import React from "react";
import { useHistory } from 'react-router-dom';

import DashboardWrapper from '../../../components/general/DashboardWrapper';
import TransfersCard from '../../../components/dashboard/TransfersCard';

import { useTransfersHandler } from '../../../logic/dashboard/transfers/transfers';

import './Transfers.css';

const Transfers = () => {

    const history = useHistory()
    const { transfers } = useTransfersHandler();

    return (

        <DashboardWrapper screen="transfers">

            <div className="home-container">
                <h2 className="main-header">Transfers</h2>

                <div className="transfers-page-row" style={{ marginBottom: "40px", marginTop: "50px" }}>
                    <div className="deposit-col">
                        <div className="deposit-withdrawal-card deposit-card"
                            onClick={() => history.push('/dashboard/deposits')}>
                            <p className="regular-font make-deposit-text">Make a deposit</p>
                            <p className="medium-font make-deposit-text" style={{ fontSize: "28px" }}>+</p>
                        </div>
                    </div>

                    <div className="withdrawal-col">
                        <div className="deposit-withdrawal-card withdrawal-card"
                            onClick={() => history.push('/dashboard/withdrawals')}>
                            <p className="regular-font make-withdrawal-text">Make a withdrawal</p>
                            <p className="medium-font make-withdrawal-text" style={{ fontSize: "28px" }}>-</p>
                        </div>
                    </div>

                    <div className="transfers-page-dummy-col">
                    </div>
                </div>

                <div className="transfers-page-row">
                    <div className="transfers-page-col">
                        <TransfersCard
                            transfers={transfers.slice(0, 6)}
                            hideViewAll={true}
                        />
                    </div>

                    <div className="transfers-page-dummy-col">
                    </div>
                </div>

            </div>
        </DashboardWrapper>

    )

}

export default Transfers;
