
import BalanceDisplay from '../general/BalanceDisplay';
import bankLogo from '../../assets/icons/bank.png';
import brexLogo from '../../assets/logos/Brex_logo.png';

import './TransferRow.css';

const TransferRow = (props) => {

    let bankIcon;
    if (props.transfer.bankLogo == 'brex') {
        bankIcon = brexLogo
    } else if (props.transfer.bankLogo == 'mercury') {
        bankIcon = bankLogo;
    } else {
        bankIcon = bankLogo;
    }

    return (
        <div className="transfer-row">

            <div className="transfer-bank-logo-col">
                <img className="transfer-bank-logo" src={bankIcon} />
            </div>

            <div className="transfer-info-col">
                <div className="transfer-info-to-from-account-container">
                    <p className="light-font transfer-to-from"
                        style={{marginRight: (props.transfer.toFrom == null ? "0px" : "6px")}}>
                        {props.transfer.toFrom}
                    </p>
                    <p className="bold-font transfer-to-from-account">
                        {props.transfer.description}
                    </p>
                </div>
                <div className="transfer-info-to-from-account-container">
                    <p className="light-font transfer-date">
                        {props.transfer.date} {props.transfer.month}
                    </p>
                </div>
            </div>

            <div className="transfer-amount-col">
                <p className="semi-bold-font transfer-plus-minus">
                    {props.transfer.creditOrDebit == "credit" ? "+" : "-"}
                </p>
                <BalanceDisplay
                    dollarBalance={props.transfer.dollarBalance}
                    centsBalance={props.transfer.centsBalance}
                    customLargeTextStyle={{fontSize: "20px"}}
                    customSmallTextStyle={{fontSize: "12px"}}
                />

            </div>

        </div>
    )

}

export default TransferRow;
