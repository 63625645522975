
import './PortfolioAllocationRow.css';

const PortfolioAllocationRow = (props) => {

    return (
        <div className="portfolio-allocation-row">
            <p className="regular-font portfolio-allocation-title">
                {props.allocationTitle}
            </p>
            <p className="semi-bold-font portfolio-allocation-percentage">
                {props.allocationPercentage}%
            </p>
        </div>
    )

}

export default PortfolioAllocationRow;
