
import React from 'react';

import './Modal.css';

const Modal = (props) => {
    return (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-dummy-col"></div>
                <div className="modal-main-col">
                    {props.children}
                </div>
                <div className="modal-dummy-col">
                    <div className="modal-close-button" onClick={props.closeModal}>
                        <p className="modal-close-x">&#215;</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;