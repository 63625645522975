const { REACT_APP_SERVICE_BASE_URL } = process.env;

export async function getLinkToken(userID, sessionToken) {

  const response = await fetch (
    `${REACT_APP_SERVICE_BASE_URL}plaid-link-token?userid=${userID}`,
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "LevoAuthToken": sessionToken
      },
      body: JSON.stringify({levo_user_id: userID}),
    }
  )

  const resData = await response.json();
  return resData;

}


export async function linkAccount(sessionToken, userID, publicToken, accountId, fingerprint) {

  const response = await fetch (
    `${REACT_APP_SERVICE_BASE_URL}plaid-link-account?userid=${userID}`,
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "LevoAuthToken": sessionToken
      },
      body: JSON.stringify({
        user_id: userID,
        public_token: publicToken,
        account_id: accountId,
        fingerprint: fingerprint
      }),
    }
  )

  const resData = await response.json();
  return [resData, response.status];

}
