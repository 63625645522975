
import DashboardWrapper from '../../../components/general/DashboardWrapper';
import LinkedAccountCard from '../../../components/transfers/LinkedAccountCard';
import PlaidLink from "../../../components/transfers/PlaidLink";

import { useTransferPageHandler } from '../../../logic/dashboard/transfers/transfers';

import './LinkedAccounts.css';

const LinkedAccounts = () => {

    const { sources } = useTransferPageHandler();

    return (

        <DashboardWrapper screen="transfers">

            <div className="home-container">
                <h2 className="main-header">Linked accounts</h2>

                {sources.map((source, index) =>
                    <LinkedAccountCard key={index} source={source} />
                )}
                <PlaidLink customStyle={{alignItems: "flex-start"}} />

            </div>
        </DashboardWrapper>

    )

}

export default LinkedAccounts;
