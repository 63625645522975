
import { stringReplace } from '../utils/generalFunctions';
const { REACT_APP_SERVICE_BASE_URL } = process.env;


export const createTransferPayload = (amount, frequency, startDate, fromAccountID) => {

  if (typeof(amount) == 'string'){
    // The amount has to be a number, without any commas of course
    amount = stringReplace(amount, ',', '');
    amount = parseFloat(amount);
  }

  let body = {};
  body = {
    'frequency': frequency,
    'amount': amount,
    'start_date': startDate,
    'from_node': fromAccountID
  }

  return body;

}


export const createTransferEditPayload = (amount, frequency, startDate, sortKey) => {

  if (typeof(amount) == 'string'){
    // The amount has to be a number, without any commas of course
    amount = stringReplace(amount, ',', '');
    amount = parseFloat(amount);
  }

  let body = {};
  body = {
    'new_frequency': frequency,
    'new_amount': amount,
    'new_start_date': startDate,
    'deposit_sort_key': sortKey
  }

  return body;

}


export const createWithdrawalPayload = (
    amount,
    toAccountID,
    toAccountType
  ) => {

  if (typeof(amount) == 'string'){
    // The amount has to be a number, without any commas of course
    amount = stringReplace(amount, ',', '');
    amount = parseFloat(amount);
  }

  const payload = {
    amount: amount,
    is_100_pct: false,
    destination_node_id: toAccountID,
    destination_node_type: toAccountType
  }

  return payload;

}


export async function makeWithdrawalRequest(
    toAccountID,
    toAccountType,
    amount,
    userID,
    sessionToken,
  ) {

    const payload = createWithdrawalPayload(
      amount,
      toAccountID,
      toAccountType
    )

    console.log(payload)

    const response = await fetch(
      `${REACT_APP_SERVICE_BASE_URL}withdrawal?userid=${userID}`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "LevoAuthToken": sessionToken
        },
        body: JSON.stringify(payload),
      }
    )

    if (!response.ok) {
      const errorResData = await response.json();
      return [errorResData, false];
    } else {
      const resData = await response.json();
      const validTransfer = (response.status == 200 ? true : false);
      return [resData, validTransfer];
    }

}


export async function makeDepositRequest(
    fromAccountID,
    amount,
    userID,
    sessionToken,
    startDate
  ) {

    const frequency = 'ONE_TIME';
    const payload = createTransferPayload(amount, frequency, startDate, fromAccountID);

    const response = await fetch(
      `${REACT_APP_SERVICE_BASE_URL}deposits?userid=${userID}`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "LevoAuthToken": sessionToken
        },
        body: JSON.stringify(payload),
      }
    )

    if (!response.ok) {
      const errorResData = await response.json();
      return [errorResData, false];
    } else {
      const resData = await response.json();
      const validTransfer = (response.status == 200 ? true : false);
      return [resData, validTransfer];
    }

}


export async function sendRecurringTransferEditRequest(
    amount,
    userID,
    sessionToken,
    method,
    sortKey,
    frequency,
    startDate,
  ) {

    // This endpoint can also delete a one time deposit request

    let payload;
    if (method == 'PATCH') {
      payload = createTransferEditPayload(amount, frequency, startDate, sortKey);
    } else {
      payload = {'deposit_sort_key': sortKey}
    }

    console.log(payload);

    const response = await fetch(
      `${REACT_APP_SERVICE_BASE_URL}deposits?userid=${userID}`,
      {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "LevoAuthToken": sessionToken
        },
        body: JSON.stringify(payload),
      }
    )

    if (!response.ok) {
      const errorResData = await response.json();
      console.log(errorResData)
      return [errorResData, false];
    } else {
      const resData = await response.json();
      console.log(resData);
      const validTransfer = (response.status == 200 ? true : false);
      return [resData, validTransfer];
    }

}


export async function cancelWithdrawalRequest(userID, sessionToken, date) {

  // Define the body as the biometric signature plus the withdrawal request date
  const body = {
    withdrawal_request_date: date
  }

  const response = await fetch(
    `${REACT_APP_SERVICE_BASE_URL}withdrawal?userid=${userID}`,
    {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        "LevoAuthToken": sessionToken
      },
      body: JSON.stringify(body),
    }
  )

  if (!response.ok) {
    const errorResData = await response.json();
    console.log(errorResData);
    return [errorResData, false];
  } else {
    const resData = await response.json();
    const validResponse = (response.status == 200 ? true : false);
    return [resData, validResponse];
  }

}
