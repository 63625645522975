const { REACT_APP_SERVICE_BASE_URL } = process.env;

export async function makeSignUpRequest(email, password, levoUserID, embedAccountID) {

    const response = await fetch(
        `${REACT_APP_SERVICE_BASE_URL}signup?userid=${levoUserID}`,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
                new_embed_user_id: embedAccountID,
            }),
        }
    )

    const resData = await response.json();
    const status = response.status;
    return [resData, status];

}