import React from 'react';

import MainSection from '../../components/marketing/MainSection';
import MobileRequestAccess from '../../components/marketing/MobileRequestAccess';
import ManageCash from '../../components/marketing/ManageCash';
import Runway from '../../components/marketing/Runway';
import WorkAlongside from '../../components/marketing/WorkAlongside';
import BackedBy from '../../components/marketing/BackedBy';
//import FooterRequestAccess from '../../components/marketing/FooterRequestAccess';
import Disclaimers from '../../components/marketing/Disclaimers';
import './Home.css';

//<FooterRequestAccess />
const Home = () => {

    return (
        <div style={{overflowX: "hidden"}}>
            <MainSection />
            <MobileRequestAccess />
            <ManageCash />
            <Runway />
            <WorkAlongside />
            <BackedBy />
            
            <Disclaimers />
        </div>
    )

}

export default Home;