// General dashboard wrapper component. This will handle the sidenav positioning mainly

import Sidenav from './Sidenav';
//import { Sidenav, Nav } from 'rsuite';

import './DashboardWrapper.css';

const DashboardWrapper = (props) => {

    return (
        <div className="main-wrapper-container">
            <Sidenav screen={props.screen} />
            <div className="main-wrapper-content">
                {props.children}
            </div>
        </div>
    )

}

export default DashboardWrapper;
