import './ManageCash.css';


const ManageCash = (props) => {

    return (
        <div className="manage-cash-section">
            <div className="manage-cash-container">
                <div className="manage-cash-title-col">
                    <h2 className="manage-cash-title">
                        Manage your cash like large corporations do
                    </h2>
                    <p className="manage-cash-sub-text">
                        Large corporations don’t just leave their money sitting in a checking account. They put 
                        their money to work by investing in short duration Treasury Bills and other super low-risk 
                        investments. We make it easy for you to do it too.
                    </p>
                </div>
            </div>
        </div>
    )

}

export default ManageCash;