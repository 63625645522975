
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { formatBalance, formatEmbedPrice, formatNameCapitalFirst } from '../../../utils/generalFunctions';
import { getMonthFromTimestamp, getDayFromTimestamp, formatEmbedTransferDate } from '../../../utils/dateFormatting';
import { makeDBRequest } from '../../../services/data';
import * as transferActions from '../../../store/actions/transfers';


const formatAmount = (amount, creditOrDebit) => {
    // Format the balance for display. We will display a '+' for any transaction that is a credit
    var [dollarBalance, centsBalance] = formatBalance(amount);
    return [dollarBalance, centsBalance];
}


const getFormattedTimestamp = (timestamp) => {

    let formattedTimestamp = new Date(timestamp);
    // The date format is '2021-03-09T11:45:21.350Z'
    const month = getMonthFromTimestamp(formattedTimestamp);
    const date = getDayFromTimestamp(formattedTimestamp);

    return [timestamp, month, date];

}

const formatWithdrawal = (withdrawal) => {

    let timestamp = withdrawal["WITHDRAWAL_REQUEST_created_on"];
    let amount = withdrawal["WITHDRAWAL_REQUEST_amount"];
    let fullWithdrawal = withdrawal["WITHDRAWAL_REQUEST_is_100_pct"];
    let toNode = withdrawal["WITHDRAWAL_REQUEST_destination_node_id"];
    if (timestamp == undefined) {
        return {}
    }

    var [dollarBalance, centsBalance] = formatAmount(amount, 'debit');
    let [formattedTimestamp, month, date] = getFormattedTimestamp(timestamp);

    const formattedWithdrawal = {
        "description": "Withdrawal request",
        "amount": amount,
        "fullWithdrawal": fullWithdrawal,
        "timestamp": formattedTimestamp,
        "transactionType": 'WithdrawalRequest',
        "dollarBalance": dollarBalance,
        "centsBalance": centsBalance,
        "month": month,
        "date": date,
        "status": "Requested",
        "toNode": toNode,
        "accountedDate": withdrawal["SK"].split('#')[1],
        "creditOrDebit": 'debit'
    }

    return formattedWithdrawal;

}

export const formatDeposit = (deposit) => {

    let timestamp = deposit["DEPOSIT_created_on"];
    let amount = deposit["DEPOSIT_amount"];
    let fromNode = deposit["DEPOSIT_from_node_id"];
    if (timestamp == undefined) {
        return {}
    }

    var [dollarBalance, centsBalance] = formatAmount(amount, 'debit');
    let [formattedTimestamp, month, date] = getFormattedTimestamp(timestamp);

    const formattedDeposit = {
        "description": "Deposit request",
        "amount": amount,
        "timestamp": formattedTimestamp,
        "transactionType": 'DepositRequest',
        "dollarBalance": dollarBalance,
        "centsBalance": centsBalance,
        "month": month,
        "date": date,
        "status": "Requested",
        "fromNode": fromNode,
        "creditOrDebit": 'credit',
        'sortKey': deposit['SK']
    }

    return formattedDeposit;

}


export const checkDepositRequest = (depositRequest) => {
    // Get the deposit requests that don't have a corresponding transaction
    // Immediately skip if it's a recurring deposit
    if (depositRequest['DEPOSIT_frequency'] != 'ONE_TIME') {
        return [false, {}];
    }
    // Skip if there is a DEPOSIT_processed_date key
    if (Object.keys(depositRequest).includes('DEPOSIT_processed_date')) {
        return [false, {}];
    }
    // Skip if the deposit is not active
    if (depositRequest['DEPOSIT_is_active'] == false || depositRequest['DEPOSIT_is_active'] == 'false') {
        return [false, {}];
    }
    const sk = depositRequest["SK"];
    // If we have created the deposit, don't show the request anymore
    /*if (depositTransactions.includes(sk)) {
        return [false, {}];
    }*/
    let formattedDeposit = formatDeposit(depositRequest);
    if (formattedDeposit == {}) {
        return [false, {}];
    }

    return [true, formattedDeposit];
}


export const checkWithdrawalRequest = (withdrawalRequest) => {
    // Get the withdrawal requests that don't have a corresponding transaction
    let formattedWithdrawal = formatWithdrawal(withdrawalRequest);
    const sk = withdrawalRequest["SK"];
    // If we have created the withdrawal, don't show the request anymore
    /*if (withdrawalTransactions.includes(sk)) {
        return [false, {}];
    }*/
    if (formattedWithdrawal == {}) {
        return [false, {}];
    }
    // Get the date in epoch format to compare to the first transaction date
    // We don't want to display the withdrawal request if  it's from before the earliest transaction we have
    const accountedDate = formattedWithdrawal["accountedDate"];
    if (accountedDate == undefined || accountedDate == null) {
        return [false, {}];
    }
    const month = (parseInt(accountedDate.slice(4,6)) - 1).toString();
    const date = new Date(accountedDate.slice(0,4), month, accountedDate.slice(6,8))
    return [true, formattedWithdrawal];
}


export function sortTransfers(transactions) {

    // This probably doesn't need to be a separate function, but at least it can be tested this way
    let sortedTransactions = transactions.sort((a, b) => (a.timestamp > b.timestamp) ? -1 : 1);
    return sortedTransactions;

}


export function formatTransfers(transfers, depositRequests, withdrawalRequests) {

    var allTransfers = [];
    if (withdrawalRequests.length > 0) {
        for (var i=0; i<withdrawalRequests.length; i++) {
            const [isUnfilledWithdrawalRequest, formattedWithdrawal] = checkWithdrawalRequest(withdrawalRequests[i])
            if (isUnfilledWithdrawalRequest) {
                allTransfers.push(formattedWithdrawal)
            }
        }
    }

    if (depositRequests.length > 0) {
        for (var i=0; i<depositRequests.length; i++) {
            const [isUnfilledDepositRequest, formattedDeposit] = checkDepositRequest(depositRequests[i])
            if (isUnfilledDepositRequest) {
                allTransfers.push(formattedDeposit)
            }
        }
    }


    if (transfers.length > 0) {
        for (i=0; i<transfers.length; i++) {

            if (transfers[i].TRANSFER_object == undefined) {
                continue;
            }
            var status = transfers[i].TRANSFER_object.status;
            status = (status == 'TRANSFER_STATUS_COMPLETED' ? 'Settled' : 'Processing')
            const amount = formatEmbedPrice(transfers[i].TRANSFER_object.details.amount);
            const sourceID = transfers[i].TRANSFER_object.sourceId;
            var [dollarBalance, centsBalance] = formatAmount(amount, 'debit');
            let [formattedTimestamp, month, date] = formatEmbedTransferDate(transfers[i].TRANSFER_object.created);
            const accountName = transfers[i].TRANSFER_display.source_display_name;
            const accountNumber = transfers[i].TRANSFER_display.source_account_num;

            let bankLogo = 'default';
            if (accountName.toLowerCase().includes('brex')) {
                bankLogo = 'brex';
            } else if (accountName.toLowerCase().includes('mercury')) {
                bankLogo = 'brex';
            }

            allTransfers.push({
                amount: amount,
                dollarBalance: dollarBalance,
                centsBalance: centsBalance,
                creditOrDebit: amount > 0 ? 'credit' : 'debit',
                toFrom: amount > 0 ? 'From' : 'To',
                description: `${accountName} - ${accountNumber}`,
                transactionType: 'ACH',
                timestamp: formattedTimestamp,
                month: month,
                date: date,
                status: status,
                sourceID: sourceID,
                account: accountName,
                accountNumber: accountNumber,
                bankLogo: bankLogo
            })

        }
    }

    const sortedTransfers = sortTransfers(allTransfers);
    return sortedTransfers;

}


export function useTransfersHandler() {

    const userID = sessionStorage.getItem("userID")//useSelector(state => state.auth.userID);
    const sessionToken = sessionStorage.getItem("sessionToken")//useSelector(state => state.auth.sessionToken);
    const savedTransfers = sessionStorage.getItem("transfers");
    const [transfers, setTransfers] = useState(savedTransfers == null ? [] : JSON.parse(savedTransfers));
    const embedSources = sessionStorage.getItem("embedSources");

    const dispatch = useDispatch()

    async function getTransfers() {

        const transferPayload = {
            'sk': 'TRANSFER',
            'limit': 100
        }
        const transfers = await makeDBRequest(userID, sessionToken, transferPayload);

        // Get the pending withdrawals
        const withdrawalPayload = {
            'sk': 'WITHDRAWAL_REQUEST',
            'table_to_query': 'accounting',
            'limit': 10
        }
        const withdrawalRequests = await makeDBRequest(userID, sessionToken, withdrawalPayload);
        console.log(withdrawalRequests)

        // Get the deposit requests
        const depositPayload = {
            'sk': 'DEPOSIT',
            'limit': 10
        }
        const depositRequests = await makeDBRequest(userID, sessionToken, depositPayload);

        const allTransfers = formatTransfers(transfers, depositRequests, withdrawalRequests)

        setTransfers(allTransfers)
        sessionStorage.setItem("transfers", JSON.stringify(allTransfers))
        dispatch(transferActions.setTransfers(allTransfers))

    }

    useEffect(() => {
        if (userID != null && userID.length > 0 && sessionToken != null && embedSources != null) {
            if (transfers.length > 0) {
                // If we have saved transfers already, don't re-request them
                const formattedTransfers = typeof(transfers) == 'string' ? JSON.parse(transfers) : transfers;
                dispatch(transferActions.setTransfers(transfers))
            } else {
                getTransfers()
            }
        }
    }, [userID, sessionToken, embedSources])

    return { transfers }

}


export function formatEmbedSources(sources) {

    if (typeof(sources) == 'string') {
        // The saved sources from session storage are in string format
        // It's not guaranteed that they have been converted yet
        sources = JSON.parse(sources)
    }

    var formattedSources = [];
    for (var i=0; i<sources.length; i++) {
        const displayName = sources[i].details.achAccount.bankName;
        var accountType = sources[i].details.achAccount.accountType;
        accountType = accountType.split('_').slice(2,);

        let formattedAccountType = '';
        for (var j=0; j<accountType.length; j++) {
            formattedAccountType += formatNameCapitalFirst(accountType[j])
            if (j < accountType.length -1) {
                formattedAccountType += ' '
            }
        }

        formattedSources.push({
            bankName: displayName,
            id: sources[i].id,
            isActive: sources[i].active,
            accountNumber: sources[i].details.achAccount.accountNumber.slice(-4,),
            accountType: formattedAccountType,
        })
    }

    return formattedSources;

}


export function useTransferPageHandler() {
    // Used on the deposit and withdrawal page. This will return the linked accounts list

    const savedSources = useSelector(state => state.transfers.embedSources)
    const [sources, setSources] = useState([])

    useEffect(() => {
        if (savedSources != undefined) {
            const formattedSources = formatEmbedSources(savedSources)
            setSources(formattedSources)
        }
    }, [savedSources])

    return { sources }

}
