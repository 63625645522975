const { REACT_APP_SERVICE_BASE_URL } = process.env;

export async function sendRequestAccessInfo(firstName, companyName, email) {

    const response = await fetch(
        `${REACT_APP_SERVICE_BASE_URL}request-access`,
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            first_name: firstName,
            company_name: companyName,
            email: email
          }),
        }
      )
    
      const resData = await response.json();
      return [resData, response.status];  

}

export async function sendStartedSignUpInfo(firstName, companyName, email) {

    const response = await fetch(
        `${REACT_APP_SERVICE_BASE_URL}started-signup`,
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            first_name: firstName,
            company_name: companyName,
            email: email
          }),
        }
      )
    
      const resData = await response.json();
      console.log(resData, response.status)
      return [resData, response.status];  

}