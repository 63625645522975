export const assetDetails = {

    'SHV': {
        shortName: '0-1 Year US Treasuries',
        duration: 0.32,
        pageLink: 'https://www.ishares.com/us/products/239466/ishares-short-treasury-bond-etf',
    },
    'VGSH': {
        shortName: '1-3 Year US Treasuries',
        duration: 1.9,
        pageLink: 'https://investor.vanguard.com/investment-products/etfs/profile/vgsh',
    },
    'VUSB': {
        shortName: '0-2 Year Investment Grade Corporate Bonds',
        duration: 0.9,
        pageLink: 'https://investor.vanguard.com/investment-products/etfs/profile/vusb',
    },
    'VCSH': {
        shortName: '1-5 Year Investment Grade Corporate Bonds',
        duration: 2.8,
        pageLink: 'https://investor.vanguard.com/investment-products/etfs/profile/vcsh',
    },
    'HYG': {
        shortName: 'High yield US corporate bonds',
        duration: 4.2,
        pageLink: 'https://www.ishares.com/us/products/239565/ishares-iboxx-high-yield-corporate-bond-etf',
    }

}
const LINKS = {
  'VGSH': 'https://investor.vanguard.com/investment-products/etfs/profile/vgsh#overview',
  'VCSH': 'https://investor.vanguard.com/investment-products/etfs/profile/vcsh#overview',
  'VUSB': 'https://investor.vanguard.com/investment-products/etfs/profile/vusb#overview',
  'SHV': 'https://www.ishares.com/us/products/239466/ishares-short-treasury-bond-etf',
  'HYG': 'https://www.ishares.com/us/products/239565/ishares-iboxx-high-yield-corporate-bond-etf'
}



export const investmentInfo = {

  defaultAllocations: {
    'SHV': 0.2,
    'VGSH': 0.5,
    'VCSH': 0.1,
    'VUSB': 0.2,
  },

  assetDescriptions: {

    Cash: {
      mainHeader: 'USD',
      detailedHeader: 'Cash (USD)',
      description: 'Cash, up to a certain amount, is a safe place to store assets (more needed).',
      currentYield: '2.12%',
    },

    VGSH: {
      mainHeader: 'VGSH',//'US treasuries (1-3 year)',
      detailedHeader: 'Vanguard Short-Term Treasury ETF',
      description: 'This fund invests primarily in high-quality (investment-grade) U.S. Treasury bonds. \
It maintains a dollar-weighted average maturity of 1 to 3 years, with an expense ratio of just 0.04%',
      currentYield: '2.86%',
    },

    VCSH: {
      mainHeader: 'VCSH',//'US corporate bonds (1-5 year)',
      detailedHeader: 'Vanguard Short-Term Corporate Bond ETF',
      description: 'This fund invests primarily in high-quality (investment-grade) corporate bonds. It \
maintains a dollar-weighted average maturity of 1 to 5 years, with an expense ratio of just 0.04%',
      currentYield: '3.76%',
    },

    SHV: {
      mainHeader: 'SHV',//'US treasuries (0-2 year)',
      detailedHeader: 'iShares Short Treasury Bond ETF',
      description: 'The iShares Short Treasury Bond ETF seeks to track the investment \
results of an index composed of U.S. Treasury bonds with remaining maturities one year or less',
      currentYield: '2.38%',
    },

    VUSB: {
      mainHeader: 'VUSB',//'US corporate bonds (0-2 year)',
      detailedHeader: 'Vanguard Ultra-Short Bond ETF',
      description: 'The fund invests in a diversified portfolio of high-quality and medium-quality \
fixed income securities. The fund is expected to maintain a dollar-weighted average maturity of 0 to 2 years',
      currentYield: '3.40%',
    },

    VTIP: {
      mainHeader: 'VTIP',//'Inflation protected securities',
      detailedHeader: 'Vanguard Short-Term Inflation-Protected Securities ETF',
      description: 'This fund is designed to generate returns more closely correlated with realized inflation over the \
near term, and to offer investors the potential for less volatility of returns relative to a longer-duration TIPS fund.',
      currentYield: '',
    },

  }

}
