
import './BalanceDisplay.css';

const BalanceDisplay = (props) => {

    return (

        <div className="balance-display-container">
            <p className="small-balance-text" style={props.customSmallTextStyle}>
                $
            </p>
            <p className="main-balance-text" style={props.customLargeTextStyle}>
                {props.dollarBalance}
            </p>
            <p className="small-balance-text" style={props.customSmallTextStyle}>
                {props.centsBalance}
            </p>
        </div>

    )

}

export default BalanceDisplay;
