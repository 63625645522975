
import './AccountSelection.css';

const AccountSelection = (props) => {

    return (

        <div className="account-selection-container">
            <select className="minimal" onChange={(event) => props.onChange(event)}>
                {props.sources.map((source, index) =>
                    <option key={index} value={source.id}>
                        {source.bankName} {source.accountType} - {source.accountNumber}
                    </option>
                )}
            </select>
        </div>

    )

}

export default AccountSelection;
