import React from "react";

import { IconContext } from "react-icons";
import { GoChevronRight } from "react-icons/go";

import './ClickableRow.css';

const ClickableRow = (props) => {

    return (
        <div className="clickable-row-container" onClick={() => props.onClick()}>
            <div className="clickable-row-icon-col">
                <IconContext.Provider
                    value={{
                        size: "24px",
                        color: "black",
                        className: "side-nav-icon"
                    }}>
                    <div>
                        {props.leftIcon}
                    </div>
                </IconContext.Provider>
            </div>
            <div className="clickable-row-text-col">
                <p className="regular-font clickable-row-text">{props.text}</p>
            </div>
            <div className="clickable-row-arrow-col">
                <IconContext.Provider
                    value={{
                        size: "24px",
                        color: "black",
                        className: "side-nav-icon"
                    }}>
                    <div>
                        <GoChevronRight />
                    </div>
                </IconContext.Provider>
            </div>
        </div>
    )

}

export default ClickableRow;