import React, { useState, useEffect } from 'react';

import Slider from 'rc-slider';
import { handleDollarValueInput } from '../../utils/generalFunctions';

import 'rc-slider/assets/index.css';
import './Runway.css';

const Runway = (props) => {

    const assetMapping = {
        1: 0.5,
        2: 0.75,
        3: 1,
        4: 1.5,
        5: 2,
        6: 2.5,
        7: 3,
        8: 4,
        9: 5,
        10: 6,
        11: 7,
        12: 8,
        13: 9,
        14: 10,
        15: 12,
        16: 14,
        17: 16,
        18: 18,
        19: 20,
        20: 25,
        21: 30,
        22: 35,
        23: 40,
        24: 45,
        25: 50
    }

    const width = window.innerWidth;

    const [sliderWidth, setSliderWidth] = useState("500px");
    const [assetSliderValue, setAssetSliderValue] = useState(9);
    const [assetDollarValue, setAssetDollarValue] = useState('5.0');
    const [percentReturn, setPercentReturn] = useState('5.0');
    const [marginLeftAssetSlider, setMarginLeftAssetSlider] = useState(0);
    const [marginLeftPercentSlider, setMarginLeftPercentSlider] = useState(0);

    var initialDollarValue = (assetDollarValue * 1000000) * (percentReturn / 100) * (1 / 12);
    initialDollarValue = handleDollarValueInput("", Math.round(initialDollarValue).toString())
    const [dollarReturn, setDollarReturn] = useState(initialDollarValue)

    const convertAssetSliderValueToDollarValue = (sliderValue) => {
        const dollarValue = assetMapping[sliderValue].toString();
        return dollarValue;
    }

    const handleAssetChange = (event) => {
        var newAssetDollarValue = convertAssetSliderValueToDollarValue(event)
        if (newAssetDollarValue.length === 1) {
            newAssetDollarValue += '.0'
        }
        setAssetDollarValue(newAssetDollarValue)
        setAssetSliderValue(event)
    }

    const handlePercentReturnChange = (event) => {
        var newValue = event.toString();
        if (newValue.length === 1) {
            newValue += '.0'
        }
        setPercentReturn(newValue)
    }

    useEffect(() => {
        const newDollarReturn = (parseFloat(assetDollarValue) * 1000000) * (parseFloat(percentReturn) / 100) * (1 / 12);
        const newDollarValue = handleDollarValueInput("", Math.round(newDollarReturn).toString())
        setDollarReturn(newDollarValue)

        // Calculate the left margins
        const sliderWidthValue = parseFloat(sliderWidth.slice(0, 3))
        let offset = 1.9;
        if (sliderWidthValue === 275) {
            offset = 2.8;
        } else if (sliderWidthValue === 350) {
            offset = 2.6;
        } else if (sliderWidthValue === 400) {
            offset = 2.4;
        }

        const assetSliderMargin = (assetSliderValue - offset) * (sliderWidthValue / 23.95)
        setMarginLeftAssetSlider(assetSliderMargin)

        offset = 4.05;
        if (sliderWidthValue === 275) {
            offset = 4.09;
        } else if (sliderWidthValue === 350) {
            offset = 4.075;
        } else if (sliderWidthValue === 400) {
            offset = 4.06;
        } 
        const percentSliderMargin = (percentReturn - offset) * (sliderWidthValue / 2)
        setMarginLeftPercentSlider(percentSliderMargin)

    }, [assetDollarValue, percentReturn, sliderWidth])

    const handleResize = () => {
        const width = window.innerWidth;
        if (width <= 550) {
            setSliderWidth("275px")
        } else if (width <= 820) {
            setSliderWidth("400px")
        } else if (width <= 900) {
            setSliderWidth("350px")
        } else if (width <= 1024) {
            setSliderWidth("400px")
        } else {
            setSliderWidth("500px")
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
    }, [width])

    return (
        <div className="runway-section">
            <div className="runway-container">

                <div className="runway-title-col">
                    <h2 className="runway-title">
                        Extend your runway by earning interest income on your idle cash
                    </h2>
                    <p className="runway-title-sub-text">
                        We offer a customizable portfolio of short duration investment
                        grade bond ETFs, letting you earn 4-6% in a low risk manner. See how much
                        your idle cash could be earning you with the calculator below.
                    </p>
                </div>

                <div className="runway-slider-section">

                    <div className="slider-container" style={{ width: sliderWidth }}>
                        <p className="slider-dollar-value" style={{ marginLeft: marginLeftAssetSlider }}>
                            ${assetDollarValue}M
                        </p>
                        <Slider
                            onChange={handleAssetChange}
                            min={1}
                            max={25}
                            step={1}
                            defaultValue={9}
                            trackStyle={{ backgroundColor: 'white', height: 4 }}
                            handleStyle={{
                                borderColor: '#2A0084',
                                height: 24,
                                width: 24,
                                marginLeft: 0,
                                marginTop: -9,
                                backgroundColor: "#2A0084",
                            }}
                            railStyle={{ backgroundColor: 'white', height: 4 }}
                        />
                    </div>

                    <div className="slider-container" style={{ width: sliderWidth }}>
                        <p className="slider-dollar-value" style={{ marginLeft: marginLeftPercentSlider }}>
                            {percentReturn}%
                        </p>
                        <Slider
                            onChange={handlePercentReturnChange}
                            min={4}
                            max={6}
                            step={0.1}
                            defaultValue={5}
                            trackStyle={{ backgroundColor: 'white', height: 4 }}
                            handleStyle={{
                                borderColor: '#2A0084',
                                height: 24,
                                width: 24,
                                marginLeft: 0,
                                marginTop: -9,
                                backgroundColor: "#2A0084",
                            }}
                            railStyle={{ backgroundColor: 'white', height: 4 }}
                        />
                    </div>

                    <div className="dollar-return-container">
                        <p className="dollar-return-value">
                            {dollarReturn}
                        </p>
                        <p className="month-text">
                            /month
                        </p>
                        <p className="interest-income-text">
                            interest income
                        </p>
                    </div>

                </div>

            </div>
        </div>
    )

}

export default Runway;