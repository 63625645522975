import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import RequestDemo from './RequestDemo';

import './Header.css';
import levoLogo from '../../assets/logos/levo-logo-white-2-1@2x.svg';

const Header = ({ hideLogin }) => {

    const history = useHistory();

    const [showRequestDemoModal, setShowRequestDemoModal] = useState(false);

    return (
        <div className="header-row">

            {showRequestDemoModal &&
                <RequestDemo
                    closeModal={() => setShowRequestDemoModal(false)}
                />
            }

            <div className="header-col header-col-left" onClick={() => history.push('/')}>
                <img className="main-header-logo" src={levoLogo} />
            </div>
            <div className="header-col header-col-right" style={{ display: hideLogin ? 'none' : 'flex' }}>
                <div className="header-button-container" id="header-login">
                    <p onClick={() => history.push('/login')} className="header-text">Log in</p>
                </div>
                <div className="header-button-container" style={{display: "none"}}>
                    <p onClick={() => setShowRequestDemoModal(true)} className="header-text">
                        Request demo
                    </p>
                </div>
                <div className="header-button-container sign-up-container" id="header-sign-up" style={{display: "none"}}>
                    <p onClick={() => history.push('/sign-up/get-started')} className="header-text sign-up-text">
                        Sign up
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Header;
