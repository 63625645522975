import React, { useState, useEffect } from "react";

import CardLayout from "../general/CardLayout";

import { assetDetails } from "../../constants/portfolioInfo";

import infoIcon from "../../assets/icons/info.svg";

import './EtfInfoCard.css';

const EtfInfoCard = ({ formattedAssetAllocation, portfolioYield }) => {

    const [allEtfYields, setAllEtfYields] = useState([]);
    const [averageDuration, setAverageDuration] = useState(0);

    function getEtfYields(yields) {

        let portfolioDuration = 0;
        let etfYields = [];
        for (let i = 0; i < formattedAssetAllocation.length; i++) {
            let symbol = formattedAssetAllocation[i].symbol;
            const etfYield = parseFloat(yields[symbol].yield);
            const expenseRatio = parseFloat(yields[symbol].expense_ratio);
            const duration = assetDetails[symbol].duration.toFixed(2);
            const link = assetDetails[symbol].pageLink;
            etfYields.push({
                symbol: symbol,
                yield: (etfYield - expenseRatio).toFixed(2).toString(),
                description: formattedAssetAllocation[i].displayName,
                percentage: formattedAssetAllocation[i].displayPercentage,
                duration: duration,
                link: link
            });
            portfolioDuration += duration * formattedAssetAllocation[i].percentage;
        }
        
        setAverageDuration(portfolioDuration.toFixed(2));
        etfYields.sort((a, b) => (a.displayPercentage > b.displayPercentage) ? -1 : 1);
        setAllEtfYields(etfYields);

    }

    useEffect(() => {
        const yields = JSON.parse(sessionStorage.getItem('currentYields'));
        getEtfYields(yields)
    }, [formattedAssetAllocation])

    return (
        <CardLayout customStyle={{ borderRadius: "5px" }}>
            <div className="etf-info-card-container">

                <div className="etf-yield-row" style={{ borderBottom: 'none', paddingBottom: "0px" }}>
                    <div className="etf-yield-col symbol-col">

                    </div>
                    <div className="etf-yield-col yield-col">
                        <p className="medium-font">Yield</p>
                    </div>
                    <div className="etf-yield-col yield-col">
                        <p className="medium-font">Duration</p>
                    </div>
                    <div className="etf-yield-col percentage-col">
                        <p className="medium-font">Portfolio weight</p>
                    </div>
                </div>

                {allEtfYields.map((etfYield, index) =>
                    <div className="etf-yield-row" key={index} 
                        style={{ borderBottom: index == allEtfYields.length - 1 ? '2px solid #a8a8a8' : '' }}>
                        <div className="etf-yield-col symbol-col">
                        <p className="bold-font etf-yield-row-symbol">
                            {etfYield.symbol} <a href={etfYield.link} target='_blank'><img src={infoIcon}></img></a>
                        </p>
                            <p className="regular-font etf-yield-row-description">{etfYield.description}</p>
                        </div>
                        <div className="etf-yield-col yield-col">
                            <p className="regular-font etf-yield-row-yield">{etfYield.yield}%</p>
                        </div>
                        <div className="etf-yield-col yield-col">
                            <p className="regular-font etf-yield-row-yield">{etfYield.duration} years</p>
                        </div>
                        <div className="etf-yield-col percentage-col">
                            <p className="bold-font etf-yield-row-percentage">{etfYield.percentage}%</p>
                        </div>
                    </div>
                )}

                <div className="etf-yield-row" style={{ borderBottom: 'none', paddingBottom: "0px" }}>
                    <div className="etf-yield-col symbol-col">

                    </div>
                    <div className="etf-yield-col yield-col">
                        <p className="regular-font etf-yield-row-yield">{portfolioYield}%</p>
                    </div>
                    <div className="etf-yield-col yield-col">
                        <p className="regular-font etf-yield-row-yield">{averageDuration} years</p>
                    </div>
                    <div className="etf-yield-col percentage-col">
                    <p className="bold-font etf-yield-row-percentage">100%</p>
                    </div>
                </div>

            </div>
        </CardLayout>
    )

}

export default EtfInfoCard;
