// Custom checkbox component

import './CustomCheckbox.css';
import '../../styles/generalStyles.css';

const CustomCheckbox = (props) => {

  return (
    <div className="checkbox-container">
      <div className="checkbox-wrapper">
        <label>
          <input
            type="checkbox"
            checked={props.isChecked}
            onChange={props.onChangeHandler}
            className={props.isChecked ? "checked" : ""}
          />
        </label>
      </div>
      <div>
        <div className="checkbox-text-section">
          <p className="semi-bold-font checkbox-header" style={props.customHeaderStyle}>{props.headerText}</p>
        </div>
        <div className="checkbox-text-section" style={props.customTextStyle}>
          {props.textComponent}
        </div>
      </div>

    </div>
  );
};

export default CustomCheckbox;