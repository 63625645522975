import { v4 as uuidv4 } from 'uuid';
import params from '../config/params';


var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


export function createUserID() {
    let uuid = uuidv4();
    return uuid;
}

export function formatPhoneNumber(phoneNumber) {
    // Formats a phone number from "111-222-333" to "+1111222333"
    phoneNumber = stringReplace(phoneNumber, '-', '');
    phoneNumber = `+1${phoneNumber}`;
    return phoneNumber;
}


export function createFingerprint() {
    let uuid = uuidv4();
    const splitFingerprint = uuid.split('-');
    let fingerprint = '';
    for (var i = 0; i < splitFingerprint.length; i++) {
        fingerprint += splitFingerprint[i];
    }
    return fingerprint;
}


export const getIP = async () => {

    //const res = await axios.get('https://geolocation-db.com/json/')
    //console.log(res.data);
    return '127.0.0.1';

}

export const saveDataToStorage = (key, json) => {
    console.log(key, json)
}


export function formatEmbedPrice(priceData) {
    // Annoying input format of {"value": "235","scale": 1}, where scale represents the
    // number of decimal places

    if (priceData == undefined) {
        return 0;
    }

    let value = priceData.value;
    const scale = parseFloat(priceData.scale);
    value = parseFloat(value)
    value = value / (Math.pow(10, scale))
    return value;

}


export function getNextSignUpPage(currentPage) {
    // Returns the next page in the sign up process
    const pages = params.signUpPages;
    const currentIndex = pages.indexOf(currentPage);
    const nextPage = pages[currentIndex + 1];
    return nextPage;
}

export function getPreviousSignUpPage(currentPage) {
    // Returns the previous page in the sign up process
    const pages = params.signUpPages;
    const currentIndex = pages.indexOf(currentPage);
    const nextPage = pages[currentIndex - 1];
    return nextPage;
}


export function handlePhoneNumberInput(phoneNumber, newText) {
    // Function to take the phone number state variable, plus the new string,
    // and then return the new phone number string (format 111-222-3333)

    const validCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if ((phoneNumber !== undefined && phoneNumber.length) > newText.length) {
        if (phoneNumber.slice(-1) === '-') {
            return phoneNumber.slice(0, -2);
        } else {
            return phoneNumber.slice(0, -1);
        }

    } else if (validCharacters.indexOf(newText.slice(-1)) === -1 || (phoneNumber !== undefined && phoneNumber.length) >= 12) {
        return phoneNumber;
    } else {
        if (newText.length === 3 || newText.length === 7) {
            return newText + '-'
        } else {
            return newText;
        }
    }

}


export function stringReplace(string, stringToReplace, replaceWith = '') {

    const splitString = string.split(stringToReplace);
    let formattedString = '';
    for (var i = 0; i < splitString.length; i++) {
        formattedString += splitString[i];
        if (i < splitString.length - 1) {
            // Don't add the replaceWith string to the final element
            formattedString += replaceWith;
        }
    }
    return formattedString;

}


export function formatBalance(balance) {

    if (typeof (balance) != 'string') {
        balance = balance.toString();
    }
    var dollarBalance = balance.split('.')[0];
    var centsBalance = balance.split('.')[1];

    // Use a regex to add in the comma every 3 digits
    dollarBalance = dollarBalance.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // If there were no cents in the balance, set it to '00'
    if (centsBalance == undefined) {
        centsBalance = '00';
    } else if (centsBalance.length == 1) {
        // Case when the cents balance is a multiple of 0.10
        centsBalance = centsBalance + '0'
    } else if (centsBalance.length > 2) {
        centsBalance = centsBalance.substring(0, 2)
    }

    return [dollarBalance, centsBalance];

}

export function formatNameCapitalFirst(textInput) {
    // Capitalize the first letter of each word

    var formattedTextInput = "";
    var string = textInput.split(' ');
    for (var i = 0; i < string.length; i++) {
        if (string[i].toLowerCase() == 'of') {
            formattedTextInput += 'of';
        } else {
            formattedTextInput += string[i].slice(0, 1).toUpperCase() + string[i].slice(1).toLowerCase();
        }
        if (i < string.length - 1) {
            formattedTextInput += " ";
        }
    }

    return formattedTextInput

}


export function handleDOB(dob, text) {

    const validCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    if (dob.length > text.length) {
        if (dob.slice(-1) == '/') {
            return dob.slice(0, -2);
        } else {
            return dob.slice(0, -1);
        }

    } else if (validCharacters.indexOf(text.slice(-1)) == -1 || dob.length >= 10) {
        // Do nothing here
        return dob;
    } else {
        if ((text).length == 2 || (text).length == 5) {
            return text + '/';
        } else {
            return text;
        }
    }

}


export function handleSSN(ssn, text) {

    const validCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    if (ssn.length > text.length) {
        if (ssn.slice(-1) == '-') {
            return ssn.slice(0, -2);
        } else {
            return ssn.slice(0, -1);
        }

    } else if (validCharacters.indexOf(text.slice(-1)) == -1 || ssn.length >= 11) {
        // Do nothing here
        return ssn;
    } else {
        if (text.length == 3 || text.length == 6) {
            return text + '-';
        } else {
            return text;
        }
    }

}


export function formatDollarValue(amount) {
    const formattedAmount = formatter.format(amount)
    return formattedAmount
}


export function handleDollarValueInput(dollarValue, newText) {
    // Format an input of 24000 to $24,000
    // Useful if we want to format the input for runway, or total assets, for example

    const validCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    var value = stringReplace(newText, ',', '');
    value = stringReplace(value, '$', '');
    if (validCharacters.indexOf(newText.slice(-1)) == -1 && value.length > 0) {
        // Do nothing if the character entered wasn't a number
        return dollarValue;
    } else if (value == '') {
        return '';
    } else {
        //var newValue = formatter.format(value)
        var newValue = formatDollarValue(value)
        return newValue;
    }

}


// Address helper functions
export function handleStateInput(state, newText) {

    const letterOnlyRegex = /^[a-zA-Z]+$/;
    var isValidCharacter = ((letterOnlyRegex.test(newText)) ? true : false);
    if (isValidCharacter && state.length < 2 && newText.length >= state.length) {
        return newText.toUpperCase();
    } else if (state.length > newText.length) {
        return state.slice(0, -1);
    } else {
        return state;
    }

}


export function handleZipCode(zip, newText) {

    const validCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    var isValidCharacter = ((validCharacters.indexOf(newText.slice(-1)) == -1) ? false : true);
    if (isValidCharacter && zip.length < 5 && newText.length >= zip.length) {
        return newText;
    } else if (zip.length > newText.length) {
        return zip.slice(0, -1);
    } else {
        return zip;
    }

}
