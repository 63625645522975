import {
  SET_LEVO_USER_ID,
  SET_COMPANY_NAME,
  SET_FIRST_NAME,
  SET_AUTHORIZED_PERSON,
  SET_AUTHORIZED_PERSON_ADDRESS,
  SET_PHONE,
  SET_VERIFIED_PHONE,
  SET_CREDENTIALS,
  SET_EMAIL_ADDRESS,
  SET_COMPANY_INFO,
  SET_COMPANY_INDUSTRY,
  SET_COMPANY_ADDRESS,
  SET_EIN,
  SET_BENEFICIAL_OWNERS,
  SET_FINANCIAL_GOALS,
  SET_TARGET_PORTFOLIO,
  SET_DOCUMENTS,
  SET_YIELDS,
  USE_DEFAULT_DEV_PARAMS,
} from '../actions/createAccount';


const initialState = {

  // Get started
  levoUserID: '', // auto-created

  // Financial questionnaire
  runway: '',
  cashBalance: '',
  riskLevel: '',

  // Portfolio recommendation
  yields: [],
  portfolio: {},

  // Authorized person
  authorizedPersonFirstName: '',
  authorizedPersonLastName: '',
  authorizedPersonDOB: '',
  authorizedPersonSSN: '',
  authorizedPersonTitle: '',
  phoneNumber: '',
  verifiedPhone: '',
  isDirector: false,
  isOfficer: false,

  // Authorized person address
  authorizedPersonStreet: '',
  authorizedPersonUnit: '',
  authorizedPersonCity: '',
  authorizedPersonState: '',
  authorizedPersonZipCode: '',
  authorizedPersonCountry: '', // For non-US addresses

  // Company info
  companyName: '',
  formationState: '',
  ein: '',
  entityType: '',

  // Company address
  street: '',
  unit: '',
  city: '',
  state: '',
  zip: '',
  country: '',

  // Beneficial owners
  beneficialOwners: {},

  // Document upload
  einDoc: '',
  aoiDoc: '',

  // Credentials
  email: '',

};


const devInitialState = {

  // Get started
  companyName: "Levo Financial, Inc.",

  // Financial questionnaire
  runway: '2To4',
  cashBalance: '$2,500,000',
  riskLevel: 'moderateRisk',

  // Portfolio recommendation
  yields: [],
  portfolio: {},
  
  // Authorized person
  authorizedPersonFirstName: 'Nick',
  authorizedPersonLastName: 'Mcc',
  authorizedPersonDOB: '01/01/1990',
  authorizedPersonSSN: '342-34-2342',
  authorizedPersonTitle: 'CTO',
  phoneNumber: '123-456-7890',
  verifiedPhone: '123-456-7890',
  isDirector: false,
  isOfficer: true,

  // Authorized person address
  authorizedPersonStreet: '100 S Main St',
  authorizedPersonUnit: '',
  authorizedPersonCity: 'San Francisco',
  authorizedPersonState: 'CA',
  authorizedPersonZipCode: '94102',
  authorizedPersonCountry: '', // For non-US addresses

  // Company info
  companyName: 'Levo Financial, Inc.',
  formationState: 'DE',
  ein: '12-5433122',
  entityType: 'corporation',

  // Company address
  street: '2191 S McClelland St',
  unit: "Apt 810",
  city: "Salt Lake City",
  state: 'UT',
  zip: '84106',
  country: 'USA',

  // Beneficial owners
  beneficialOwners: {
    "0": {
      firstName: 'Nick',
      lastName: 'Mcc',
      ssn: '123-12-1234',
      email: 'zach@test.com',
      dob: '12/12/1990',
      phone: '123-456-7890',
      ownership: '27',
      jobTitle: 'CEO',
      street: '200 S Main',
      unit: 'Apt 100',
      city: 'Salt Lake City',
      state: 'UT',
      zip: '84106',
      country: 'US',
      base64Front: "test",
      base64Back: "test",
      isComplete: true
    }
  },

  // Document upload
  einDoc: '',
  aoiDoc: '',

  // Credentials
  email: "nick@test.com",

}

export default (state = initialState, action) => {

  switch (action.type) {
    case SET_LEVO_USER_ID:
      return {
        ...state,
        levoUserID: action.levoUserID,
      }
    case SET_COMPANY_NAME:
      return {
        ...state,
        companyName: action.companyName,
      }
    case SET_FIRST_NAME:
      return {
        ...state,
        authorizedPersonFirstName: action.firstName,
      }
    case SET_EMAIL_ADDRESS:
      return {
        ...state,
        email: action.emailAddress,
      }
    case SET_FINANCIAL_GOALS:
      return {
        ...state,
        riskLevel: action.riskLevel,
        cashBalance: action.cashBalance,
        runway: action.runway,
      }
    case SET_YIELDS:
      return {
        ...state,
        yields: action.yields,
      }
    case SET_TARGET_PORTFOLIO:
      return {
        ...state,
        portfolio: action.portfolio
      }
    case SET_AUTHORIZED_PERSON:
      return {
        ...state,
        authorizedPersonFirstName: action.firstName,
        authorizedPersonLastName: action.lastName,
        authorizedPersonDOB: action.dob,
        authorizedPersonSSN: action.ssn,
        authorizedPersonTitle: action.title,
      }
    case SET_AUTHORIZED_PERSON_ADDRESS:
      return {
        ...state,
        authorizedPersonStreet: action.street,
        authorizedPersonUnit: action.unit,
        authorizedPersonCity: action.city,
        authorizedPersonState: action.state,
        authorizedPersonZipCode: action.zipCode,
        authorizedPersonCountry: action.country,
      }
    case SET_CREDENTIALS:
      return {
        ...state,
        email: action.email,
        password: action.password,
      }
    case SET_PHONE:
      return {
        ...state,
        phoneNumber: action.phoneNumber,
      }
    case SET_VERIFIED_PHONE:
      return {
        ...state,
        verifiedPhone: action.verifiedPhone,
      }
    case SET_COMPANY_INDUSTRY:
      return {
        ...state,
        companyIndustry: action.companyIndustry,
      }
    case SET_COMPANY_INFO:
      return {
        ...state,
        ein: action.ein,
        entityType: action.entityType,
        companyName: action.companyName,
      }
    case SET_COMPANY_ADDRESS:
      return {
        ...state,
        street: action.street,
        unit: action.unit,
        city: action.city,
        state: action.state,
        zip: action.zip,
        country: action.country,
      }
    case SET_EIN:
      return {
        ...state,
        ein: action.ein,
      }
    case SET_DOCUMENTS:
      return {
        ...state,
        einDoc: action.einDoc,
        aoiDoc: action.aoiDoc,
      }
    case USE_DEFAULT_DEV_PARAMS:
      return {
        ...devInitialState,
        levoUserID: action.levoUserID,
      }
    case SET_BENEFICIAL_OWNERS:
      return {
        ...state,
        beneficialOwners: action.beneficialOwners,
      }
    default:
      return state;
  }

};
