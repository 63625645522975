import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CreateAccountWrapper from '../../components/createAccount/CreateAccountWrapper';
import LoadingSpinner from '../../components/general/LoadingSpinner';
import CustomCheckbox from '../../components/general/CustomCheckbox';

import {
    getRecommendedPortfolio, formatRecommendedPortfolio, getPortfolioYield,
    createNaturalLanguageDescription, getEffectiveDuration, getDollarYield
} from '../../logic/createAccount/portfolioRecommendation';
import { getYields } from '../../services/yields';
import * as createAccountActions from '../../store/actions/createAccount';
import { getNextSignUpPage } from '../../utils/generalFunctions';

import InvestmentAdvisoryAgreement from '../../assets/pdfs/InvestmentAdvisoryAgreement.pdf';
import EmbedPDF from '../../assets/pdfs/EmbedClearing LLC_CustomerAccountAgreement.pdf';

import '../../styles/accountCreationStyles.css';
import './PortfolioRecommendation.css';
import '../../styles/generalStyles.css';

const PortfolioRecommendation = () => {

    const riskLevel = useSelector(state => state.createAccount.riskLevel);
    const runway = useSelector(state => state.createAccount.runway);
    const savedYields = useSelector(state => state.createAccount.yields);
    const cashBalance = useSelector(state => state.createAccount.cashBalance);

    const [yields, setYields] = useState(savedYields);
    const [didRequestYields, setDidRequestYields] = useState(false);
    const [recommendedPortfolio, setRecommendedPortfolio] = useState([]); // Display portfolio
    const [userPortfolio, setUserPortfolio] = useState({}); // Portfolio used during sign up call
    const [portfolioYield, setPortfolioYield] = useState('');
    const [dollarYield, setDollarYield] = useState('');
    const [naturalLanguageDescription, setNaturalLanguageDescription] = useState('');
    const [loadingIndicatorDisplay, setLoadingIndicatorDisplay] = useState(true);

    const [isChecked, setIsChecked] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    async function requestYields() {
        const [resData, status] = await getYields()
        setYields(resData.yields)
    }

    const sendToNext = () => {
        dispatch(createAccountActions.setPortfolio(userPortfolio))
        const nextPage = getNextSignUpPage(window.location.pathname);
        history.push(nextPage)
    }

    const textComponent = (
        <div style={{ flexDirection: 'row' }}>
            <p className="light-font checkbox-text">{"By checking this box, I agree to Levo’s "}
                <a href={InvestmentAdvisoryAgreement} target="_blank"
                    className="light-font link-text">
                    Investment Advisory Agreement
                </a> {" and Embed Clearing LLC's "}
                <a href={EmbedPDF} target="_blank"
                    className="light-font link-text">
                    Customer Account Agreement
                </a>
                {"."}
            </p>
        </div>
    )

    useEffect(() => {
        if (yields.length == 0 && !didRequestYields) {
            requestYields()
            setDidRequestYields(true)
        }
        if (riskLevel != '' && runway != '' && yields.length != 0) {

            const portfolio = getRecommendedPortfolio(riskLevel, runway)
            setUserPortfolio(portfolio)
            const formattedPortfolio = formatRecommendedPortfolio(portfolio)
            setRecommendedPortfolio(formattedPortfolio)

            const totalPortfolioYield = getPortfolioYield(portfolio, yields)
            const totalDollarYield = getDollarYield(totalPortfolioYield, cashBalance)
            setPortfolioYield(totalPortfolioYield)
            setDollarYield(totalDollarYield)

            const duration = getEffectiveDuration(portfolio)
            const description = createNaturalLanguageDescription(riskLevel, runway, duration)
            setNaturalLanguageDescription(description)
            setLoadingIndicatorDisplay(false)

        }
    }, [riskLevel, runway, yields])

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (isChecked) {
                    sendToNext()
                }
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [isChecked]);

    return (
        <CreateAccountWrapper
            buttonDisabled={!isChecked}
            onButtonClick={() => sendToNext()}
            onSecondaryTextClick={() => history.goBack()}
            buttonText={'Continue'}>
            <div className="section-container">
                <header className="bold-font account-creation-header">
                    Your recommended portfolio
                </header>
                <p className="regular-font sub-header">
                    {naturalLanguageDescription}
                    <b>
                        The current yield on this portfolio is {portfolioYield}%.
                        This comes out to {dollarYield} per year (assuming you move 80% of your cash to Levo Treasury).
                    </b>
                </p>

                {loadingIndicatorDisplay &&
                    <div className="loading-indicator-container">
                        <LoadingSpinner />
                    </div>
                }

                <div className="recommended-portfolio-allocation-section">
                    {recommendedPortfolio.map((asset, index) =>
                        <div key={index} className="recommended-portfolio-allocation-row">
                            <div className="symbol-container">
                                <h2 className="semi-bold-font">{asset.symbol}</h2>
                            </div>
                            <div className="description-container">
                                <h2 className="semi-bold-font">{asset.shortName}</h2>
                            </div>
                            <div className="percentage-container">
                                <h2 className="light-font">{asset.percentage}%</h2>
                            </div>
                        </div>
                    )}

                </div>

                <p className="regular-font further-info">
                    If you’d like to discuss this portfolio further, or would like to make any changes,
                    then feel free to reach out. You can edit your allocation at any time, so you can go
                    ahead and submit your application now even if you would like to make changes to your portfolio.
                </p>

                <div style={{ marginTop: 50 }}>
                    <CustomCheckbox
                        textComponent={textComponent}
                        isChecked={isChecked}
                        onChangeHandler={() => setIsChecked(!isChecked)}
                    />
                </div>

            </div>
        </CreateAccountWrapper>
    )

}

export default PortfolioRecommendation;
