const { REACT_APP_ENV_TYPE } = process.env;

export const SET_LEVO_USER_ID = 'SET_LEVO_USER_ID';
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME';
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_AUTHORIZED_PERSON = 'SET_AUTHORIZED_PERSON';
export const SET_AUTHORIZED_PERSON_ADDRESS = 'SET_AUTHORIZED_PERSON_ADDRESS';
export const SET_PHONE = 'SET_PHONE';
export const SET_VERIFIED_PHONE = 'SET_VERIFIED_PHONE';
export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const SET_EMAIL_ADDRESS = 'SET_EMAIL_ADDRESS';
export const SET_COMPANY_INFO = 'SET_COMPANY_INFO';
export const SET_COMPANY_INDUSTRY = 'SET_COMPANY_INDUSTRY';
export const SET_COMPANY_ADDRESS = 'SET_COMPANY_ADDRESS';
export const SET_EIN = 'SET_EIN';
export const SET_BENEFICIAL_OWNERS = 'SET_BENEFICIAL_OWNERS';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const SET_FINANCIAL_GOALS = 'SET_FINANCIAL_GOALS';
export const SET_YIELDS = 'SET_YIELDS';
export const SET_TARGET_PORTFOLIO = 'SET_TARGET_PORTFOLIO';
export const USE_DEFAULT_DEV_PARAMS = 'USE_DEFAULT_DEV_PARAMS';


export const setLevoUserID = (levoUserID, initToDev) => {
  return dispatch => {

    if (initToDev && REACT_APP_ENV_TYPE == 'dev') {
      dispatch({
        type: USE_DEFAULT_DEV_PARAMS,
        levoUserID: levoUserID
      })
    } else {
      dispatch({
        type: SET_LEVO_USER_ID,
        levoUserID: levoUserID
      })
    }
  }
}

export const setCompanyName = (companyName) => {
  return dispatch => {
    dispatch({ type: SET_COMPANY_NAME, companyName: companyName });
  }
}

export const setAuthorizedPersonInfo = (firstName, lastName, dob, ssn, isDirector, isOfficer, title) => {
  return dispatch => {
    dispatch({
      type: SET_AUTHORIZED_PERSON,
      firstName: firstName,
      lastName: lastName,
      dob: dob,
      ssn: ssn,
      isDirector: isDirector,
      isOfficer: isOfficer,
      title: title
    });
  }
}

export const setAuthorizedPersonAddress = (street, unit, city, state, zipCode, country) => {
  return dispatch => {
    dispatch({
      type: SET_AUTHORIZED_PERSON_ADDRESS,
      street: street,
      unit: unit,
      city: city,
      state: state,
      zipCode: zipCode,
      country: country
    });
  }
}

export const setVerifiedPhone = (phone) => {
  return dispatch => {
    dispatch({ type: SET_VERIFIED_PHONE, verifiedPhone: phone });
  }
}

export const setPhone = (phone) => {
  return dispatch => {
    dispatch({ type: SET_PHONE, phoneNumber: phone });
  }
}

export const setCredentials = (email, password) => {
  return dispatch => {
    dispatch({ type: SET_CREDENTIALS, email: email, password: password })
  }
}
export const setCompanyIndustry = (companyIndustry) => {
  return dispatch => {
    dispatch({ type: SET_COMPANY_INDUSTRY, companyIndustry: companyIndustry });
  }
}

export const setCompanyInfo = (companyName, ein, entityType, formationState) => {
  return dispatch => {
    dispatch({
      type: SET_COMPANY_INFO,
      companyName: companyName,
      ein: ein,
      entityType: entityType,
      formationState: formationState
    });
  }
}

export const setCompanyAddress = (street, unit, city, state, zip, country, isAddressVerified) => {
  return dispatch => {
    dispatch({
      type: SET_COMPANY_ADDRESS,
      street: street,
      unit: unit,
      city: city,
      state: state,
      zip: zip,
      country: country,
      isAddressVerified: isAddressVerified
    });
  }
}

export const setEIN = (ein) => {
  return dispatch => {
    dispatch({ type: SET_EIN, ein: ein });
  }
}

export const setBeneficialOwners = (beneficialOwners) => {
  return dispatch => {
    dispatch({
      type: SET_BENEFICIAL_OWNERS,
      beneficialOwners: beneficialOwners
    })
  }
}

export const setFinancialGoals = (riskLevel, cashBalance, runway) => {
  return dispatch => {
    dispatch({
      type: SET_FINANCIAL_GOALS,
      riskLevel: riskLevel,
      cashBalance: cashBalance,
      runway: runway
    })
  }
}

export const setPortfolio = (portfolio) => {
  return dispatch => {
    dispatch({
      type: SET_TARGET_PORTFOLIO,
      portfolio: portfolio
    })
  }
}

export const setYields = (yields) => {
  return dispatch => {
    dispatch({
      type: SET_YIELDS,
      yields: yields
    })
  }
}

export const setDocuments = (einDoc, aoiDoc) => {
  return dispatch => {
    dispatch({
      type: SET_DOCUMENTS,
      einDoc: einDoc,
      aoiDoc: aoiDoc
    })
  }
}
