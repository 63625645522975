
export const SET_ACCOUNT_INFO = 'SET_ACCOUNT_INFO';
export const SET_COMPANY_DOCS = 'SET_COMPANY_DOCS';
export const SET_MONTHLY_STATEMENTS = 'SET_MONTHLY_STATEMENTS';

export const setAccountInfo = (accountInfo) => {
    return dispatch => {
        dispatch({ type: SET_ACCOUNT_INFO, accountInfo: accountInfo });
    }
}

export const setCompanyDocs = (companyDocs) => {
    return dispatch => {
        dispatch({ type: SET_COMPANY_DOCS, companyDocs: companyDocs });
    }
}

export const setMonthlyStatements = (monthlyStatements) => {
    return dispatch => {
        dispatch({ type: SET_MONTHLY_STATEMENTS, monthlyStatements: monthlyStatements });
    }
}
