import React, { useState, useEffect } from "react";

import DashboardWrapper from '../../../components/general/DashboardWrapper';
import AccountOverviewCard from '../../../components/dashboard/AccountOverviewCard';
import PortfolioAllocationCard from '../../../components/dashboard/PortfolioAllocationCard';
import TransfersCard from '../../../components/dashboard/TransfersCard';

import { useHomePageHandler } from '../../../logic/dashboard/home/home';
import { formatBalance } from '../../../utils/generalFunctions';
import {
    useAssetAllocationHandler,
    useInvestmentPerformanceHandler
} from '../../../logic/dashboard/portfolio/investmentPerformance';
import { useTransfersHandler } from '../../../logic/dashboard/transfers/transfers';

import './Home.css';
import '../../../styles/generalStyles.css';
import LoadingSpinner from "../../../components/general/LoadingSpinner";

const Home = () => {

    const [accountBalanceDollars, setAccountBalanceDollars] = useState('');
    const [accountBalanceCents, setAccountBalanceCents] = useState('');
    const [dollarYieldDollars, setDollarYieldDollars] = useState('');
    const [dollarYieldCents, setDollarYieldCents] = useState('');

    const { accountBalance, activityIndicator } = useHomePageHandler();
    const { transfers } = useTransfersHandler();

    const {
        cumulativeReturn,
        firstNavDate,
        currentNAVDate,
    } = useInvestmentPerformanceHandler();
    const {
        formattedAssetAllocation, portfolioYield, dollarYield, isTargetAllocation, asOfDate
    } = useAssetAllocationHandler();

    useEffect(() => {
        if (accountBalance != null) {
            const [dollarBalance, centsBalance] = formatBalance(accountBalance)
            setAccountBalanceDollars(dollarBalance)
            setAccountBalanceCents(centsBalance)
        }
    }, [accountBalance])

    useEffect(() => {
        if (dollarYield >= 0) {
            const [dollarBalance, centsBalance] = formatBalance(dollarYield)
            setDollarYieldDollars(dollarBalance)
            setDollarYieldCents(centsBalance)
        }
    }, [dollarYield])

    return (

        <DashboardWrapper screen="dashboard">

            {activityIndicator && <LoadingSpinner />}

            <div className="home-container">
                <h2 className="main-header">Account Overview</h2>

                <div className="home-card-row">
                    <div className="home-card-account-col">
                        <AccountOverviewCard
                            dollarBalance={accountBalanceDollars}
                            centsBalance={accountBalanceCents}
                            dollarReturn={cumulativeReturn.dollarReturn}
                            centsReturn={cumulativeReturn.centsReturn}
                            percentReturn={cumulativeReturn.mwr}
                            dollarYieldDollars={dollarYieldDollars}
                            dollarYieldCents={dollarYieldCents}
                            portfolioYield={portfolioYield.toString()}
                            ytdDateRange={`${firstNavDate} - ${currentNAVDate}`}
                            yieldUpdateDate={asOfDate}
                        />
                    </div>
                </div>

                <div className="home-card-row">

                    <div className="home-card-allocation-col" style={{ marginRight: "12px" }}>
                        <PortfolioAllocationCard
                            portfolioAllocation={formattedAssetAllocation}
                            isTargetAllocation={isTargetAllocation}
                        />
                    </div>

                    <div className="home-card-transfers-col" style={{ marginLeft: "12px" }}>
                        <TransfersCard
                            transfers={transfers.slice(0, 3)}
                        />
                    </div>

                </div>

            </div>
        </DashboardWrapper>
    )

}

export default Home;
