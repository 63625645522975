import React, { useState } from "react";

import CardLayout from '../general/CardLayout';
import CustomButton from "../general/CustomButton";

import './TransferReviewCard.css';

const TransferReviewCard = (props) => {

    const toAccountBalance = props.toAccountBalance == undefined ? "" : " - $" + props.toAccountBalance;
    const toAccountType = props.toAccountType == undefined ? "" : "" + props.toAccountType;
    const toAccountNumber = props.toAccountNumber == undefined ? "" : " - " + props.toAccountNumber;

    const fromAccountBalance = props.fromAccountBalance == undefined ? "" : " - $" + props.fromAccountBalance;
    const fromAccountType = props.fromAccountType == undefined ? "" : "" + props.fromAccountType;
    const fromAccountNumber = props.fromAccountNumber == undefined ? "" : " - " + props.fromAccountNumber;

    return (

        <CardLayout customStyle={{ width: "800px", borderRadius: "8px" }}>
            <div className="transfer-review-card-container">

                <div className="transfer-review-card-row">
                    <div className="transfer-review-card-title-col">
                        <p>To</p>
                    </div>
                    <div className="transfer-review-card-info-col">
                        <p>{props.toAccountName} {toAccountType} {toAccountNumber} {toAccountBalance}</p>
                    </div>
                </div>

                <div className="transfer-review-card-row">
                    <div className="transfer-review-card-title-col">
                        <p>From</p>
                    </div>
                    <div className="transfer-review-card-info-col">
                        <p>{props.fromAccountName} {fromAccountType} {fromAccountNumber} {fromAccountBalance}</p>
                    </div>
                </div>

                <div className="transfer-review-card-row">
                    <div className="transfer-review-card-title-col">
                        <p>Amount</p>
                    </div>
                    <div className="transfer-review-card-info-col">
                        <p>{props.amount}</p>
                    </div>
                </div>

                <div className="transfer-review-card-button-container">
                    <div className="transfer-review-card-button-col">
                        <CustomButton
                            onClick={() => props.onSubmitClick()}
                            buttonText={"Submit"}
                            disabled={props.buttonDisabled}
                        />
                    </div>
                </div>

            </div>
        </CardLayout>
    )

}

export default TransferReviewCard;