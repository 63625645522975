import React, { useState, useEffect } from 'react';

import TextInput from '../components/general/TextInput';
import CustomButton from '../components/general/CustomButton';
import LoadingSpinner from '../components/general/LoadingSpinner';
import CardLayout from '../components/general/CardLayout';
import Header from '../components/marketing/Header';

import { useLoginHandler } from '../logic/dashboard/auth/login';
import './Login.css';

const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    const { performLogin, loadingIndicatorDisplay } = useLoginHandler();

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ your logic here
                if (email.length > 0 && password.length > 0) {
                    performLogin(email, password)
                }
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [email, password]);

    return (

        <div className="main-login-section">
            <Header hideLogin={true} />
            
            <div className="main-login-container">

                <CardLayout customStyle={{ borderRadius: "12px" }}>
                    <div className="login-input-container">
                        <TextInput
                            headerMessage={"Email Address"}
                            inputType={"text"}
                            value={email}
                            onTextChange={(event) => setEmail(event.target.value)}
                            placeholder={"john@acme.inc"}
                            fontColor={'dark'}
                            customStyle={{ borderWidth: "2px", paddingTop: "14px", paddingBottom: "14px", marginBottom: "20px" }}
                        />
                        <TextInput
                            headerMessage={"Password"}
                            inputType={"password"}
                            value={password}
                            onTextChange={(event) => setPassword(event.target.value)}
                            placeholder={"Password"}
                            fontColor={'dark'}
                            secureText={true}
                            customStyle={{ borderWidth: "2px", paddingTop: "14px", paddingBottom: "14px", marginBottom: "45px" }}
                        />
                        <CustomButton
                            buttonText={"Log in"}
                            onClick={() => performLogin(email, password)}
                            disabled={false}
                        />
                        {loadingIndicatorDisplay &&
                            <div className="loading-indicator-container">
                                <LoadingSpinner />
                            </div>
                        }
                    </div>
                </CardLayout>
                
            </div>
        </div>
    )

}

export default Login;
