import { useHistory } from 'react-router-dom';

import { useLogoutHandler } from '../../logic/dashboard/auth/logout';

import levoLogo from '../../assets/logos/levo-logo-white-2-1@2x.svg';
import { IconContext } from "react-icons";
import { IoPersonCircle } from "react-icons/io5";
import { IoSwapHorizontalSharp } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { AiOutlineStock } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";

import './Sidenav.css';

const Sidenav = (props) => {

    const history = useHistory();
    const { logout } = useLogoutHandler();

    function handleNavClick(page) {
        history.push(page)
    }

    return (
        <div className="sidenav-container">

            <div className="sidenav-logo-container" onClick={() => history.push('/dashboard')}>
                <img src={levoLogo} className="side-nav-logo" alt="logo" />
            </div>
            <div className="side-nav-row" onClick={() => handleNavClick('/dashboard')}>
                <IconContext.Provider
                    value={{
                        size: "24px",
                        color: (props.screen === "dashboard" ? "white" : "#AEAEAE"),
                        className: "side-nav-icon"
                    }}>
                    <div>
                        <IoHome />
                    </div>
                </IconContext.Provider>
                <p className={"side-nav-link" + (props.screen === "dashboard" ? " side-nav-link-focused" : "")}>
                    Home
                </p>
            </div>

            <div className="side-nav-row" onClick={() => handleNavClick('/dashboard/portfolio')}>
                <IconContext.Provider
                    value={{
                        size: "24px",
                        color: (props.screen === "portfolio" ? "white" : "#AEAEAE"),
                        className: "side-nav-icon"
                    }}>
                    <div>
                        <AiOutlineStock />
                    </div>
                </IconContext.Provider>
                <p className={"side-nav-link" + (props.screen === "portfolio" ? " side-nav-link-focused" : "")}>
                    Portfolio
                </p>
            </div>

            <div className="side-nav-row" onClick={() => handleNavClick('/dashboard/transfers')}>
                <IconContext.Provider
                    value={{
                        size: "24px",
                        color: (props.screen === "transfers" ? "white" : "#AEAEAE"),
                        className: "side-nav-icon"
                    }}>
                    <div>
                        <IoSwapHorizontalSharp />
                    </div>
                </IconContext.Provider>
                <p className={"side-nav-link" + (props.screen === "transfers" ? " side-nav-link-focused" : "")}>
                    Transfers
                </p>
            </div>

            <div className="side-nav-row" onClick={() => handleNavClick('/dashboard/account')}>
                <IconContext.Provider
                    value={{
                        size: "24px",
                        color: (props.screen === "account" ? "white" : "#AEAEAE"),
                        className: "side-nav-icon"
                    }}>
                    <div>
                        <IoPersonCircle />
                    </div>
                </IconContext.Provider>
                <p className={"side-nav-link" + (props.screen === "account" ? " side-nav-link-focused" : "")}>
                    Account
                </p>
            </div>

            <div className="side-nav-row-bottom">
                <div className="side-nav-row" onClick={() => logout()}>
                    <IconContext.Provider
                        value={{
                            size: "24px",
                            color: (props.screen === "account" ? "white" : "#AEAEAE"),
                            className: "side-nav-icon"
                        }}>
                        <div>
                            <BiLogOut />
                        </div>
                    </IconContext.Provider>
                    <p className={"side-nav-link" + (props.screen === "account" ? " side-nav-link-focused" : "")}>
                        Log out
                    </p>
                </div>
            </div>

        </div>
    )

}

export default Sidenav;
