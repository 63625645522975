
import CardLayout from '../../components/general/CardLayout';
import PortfolioAllocationRow from './PortfolioAllocationRow';

import './PortfolioAllocationCard.css';


const PortfolioAllocationCard = (props) => {

    return (

        <CardLayout customStyle={{borderRadius: "5px"}}>

            <div className="portfolio-allocation-container">

                <h2 className="medium-font portfolio-allocation-header">
                    Portfolio allocation {props.isTargetAllocation ? "(target)" : ""}
                </h2>

                {props.portfolioAllocation.map((allocation, index) =>
                    <PortfolioAllocationRow
                        allocationTitle={allocation.displayName}
                        allocationPercentage={allocation.displayPercentage}
                        key={index}
                    />
                )}

            </div>

        </CardLayout>

    )

}

export default PortfolioAllocationCard;
