import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import CreateAccountWrapper from '../../components/createAccount/CreateAccountWrapper';
import Address from '../../components/createAccount/Address';

//import { useAddressSubmission } from '../../logic/createAccount/address';
import * as createAccountActions from '../../store/actions/createAccount';
import { getNextSignUpPage } from '../../utils/generalFunctions';

import '../../styles/accountCreationStyles.css';
import '../../styles/generalStyles.css';

const CompanyAddress = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    //const { sendAddress, loadingIndicator, setLoadingIndicator } = useAddressSubmission();

    // TODO: possibly save this data to session storage, so we can handle page refreshes
    const savedStreet = useSelector(state => state.createAccount.street);
    const savedUnit = useSelector(state => state.createAccount.unit);
    const savedCity = useSelector(state => state.createAccount.city);
    const savedState = useSelector(state => state.createAccount.state);
    const savedZip = useSelector(state => state.createAccount.zip);

    const [address, setAddress] = useState({
        street: savedStreet === undefined ? '' : savedStreet,
        unit: savedUnit === undefined ? '' : savedUnit,
        city: savedCity === undefined ? '' : savedCity,
        state: savedState === undefined ? '' : savedState,
        zipCode: savedZip === undefined ? '' : savedZip,
    })
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const sendToNext = () => {

        // Update the redux store with the updated beneficial owner
        dispatch(createAccountActions.setCompanyAddress(
            address['street'], address['unit'], address['city'], address['state'], address['zip']
        ));

        const nextPage = getNextSignUpPage(window.location.pathname);
        history.push(nextPage)
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (!buttonDisabled) {
                    sendToNext()
                }
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [buttonDisabled]);

    useEffect(() => {
        if (address['isValidAddress']) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [address])

    return (
        <CreateAccountWrapper
            buttonDisabled={buttonDisabled}
            onButtonClick={() => sendToNext()}
            onSecondaryTextClick={() => history.goBack()}>
            <div className="section-container">
                <header className="bold-font account-creation-header">
                    Company address
                </header>
                <p className="light-font sub-header">
                    Please enter the principal place of business. This should be the address used for all official filings.
                    This must be a US address.
                </p>

                <Address initialAddress={address} setAddress={setAddress} />

            </div>
        </CreateAccountWrapper>
    )

}

export default CompanyAddress;