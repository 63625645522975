import { assetDetails } from '../../constants/portfolioInfo';
import { formatDollarValue } from '../../utils/generalFunctions';

export function getTargetDuration(runway) {
    // Get the split for ultra short vs short duration treasuries or bonds
    // This returns [ultraShort, short]
    if (runway === 'lessThan1') {
        return [1, 0]
    } else if (runway === '1To2') {
        return [2 / 3, 1 / 3]
    } else if (runway === '2To4') {
        return [1 / 3, 2 / 3]
    } else {
        return [0, 1];
    }
}


export function getTreasuryBondSplit(riskLevel) {
    // Returns [treasurySplit, bondSplit]
    if (riskLevel === 'veryLowRisk' || riskLevel === 'lowRisk') {
        return [1, 0];
    } else if (riskLevel === 'moderateRisk') {
        return [0.6, 0.4];
    } else {
        return [0.3, 0.7];
    }
}


export function getRecommendedPortfolio(riskLevel, runway) {

    const [ultraShort, short] = getTargetDuration(runway);
    const includeHYG = riskLevel === 'aggressiveRisk' && (runway === '2To4' || runway === '4Plus' || runway === 'cashFlowPositive');
    if (includeHYG) {
        return {
            'SHV': Math.round((ultraShort * 0.3) * 100) / 100,
            'VGSH': Math.round((short * 0.3) * 100) / 100,
            'VUSB': Math.round((ultraShort * (runway === '2To4' ? 0.6 : 0.5)) * 100) / 100,
            'VCSH': Math.round((short * (runway === '2To4' ? 0.6 : 0.5)) * 100) / 100,
            'HYG': Math.round((runway === '2To4' ? 0.1 : 0.2) * 100) / 100
        }
    } else {
        const [treasurySplit, bondSplit] = getTreasuryBondSplit(riskLevel);
        return {
            'SHV': Math.round((ultraShort * treasurySplit) * 100) / 100,
            'VGSH': Math.round((short * treasurySplit) * 100) / 100,
            'VUSB': Math.round((ultraShort * bondSplit) * 100) / 100,
            'VCSH': Math.round((short * bondSplit) * 100) / 100
        }
    }

}

export function formatPercentage(percentage) {
    var percentValue = (percentage * 100).toString();
    return percentValue;
}


export function formatRecommendedPortfolio(recommendedPortfolio) {
    // Convert the symbol to a short description, and format the percentages
    // Return value: {'SHV': {'name': 'Ultra short duration US treasuries', 'percentage': '20%'}, ...}

    const symbols = Object.keys(recommendedPortfolio);
    let formattedPortfolio = []
    for (var i = 0; i < symbols.length; i++) {
        const percentage = recommendedPortfolio[symbols[i]];
        // Skip the assets that have 0 allocation
        if (percentage < 0.01) {
            continue;
        }
        const shortName = assetDetails[symbols[i]].shortName;
        const formattedPercentage = formatPercentage(percentage)
        formattedPortfolio.push({
            symbol: symbols[i],
            shortName: shortName,
            percentage: formattedPercentage,
            originalPercentage: percentage
        })
    }

    let sortedPortfolio = formattedPortfolio.sort((a, b) => (a.originalPercentage > b.originalPercentage) ? -1 : 1);
    return sortedPortfolio;

}

export function getDollarYield(portfolioYield, cashBalance) {

    // portfolioYield is a string between 1 and 100
    // cashBalance is a string in the format of $1,000,000

    // Convert the cash balance from $100,000 to 100000
    let cashBalanceNumber = cashBalance.replace(/,/g, '');
    cashBalanceNumber = parseFloat(cashBalanceNumber.replace('$', ''));
    let dollarYield = Math.round(cashBalanceNumber * 0.8 * (parseFloat(portfolioYield) / 100));
    // Convert the number back to a string in the format $1,000,000
    dollarYield = formatDollarValue(dollarYield);

    return dollarYield;
}

export function getPortfolioYield(recommendedPortfolio, yields) {

    const symbols = Object.keys(recommendedPortfolio);
    let portfolioYield = 0;
    for (var i = 0; i < symbols.length; i++) {
        const percentage = recommendedPortfolio[symbols[i]];
        const assetYield = parseFloat(yields[symbols[i]].yield) - parseFloat(yields[symbols[i]].expense_ratio)
        portfolioYield += assetYield * percentage;
    }
    var formattedYield = (Math.round(portfolioYield * 100) / 100).toString();
    if (formattedYield.length === 3) {
        formattedYield += '0'
    }
    console.log(formattedYield)
    return formattedYield;

}


export function getEffectiveDuration(portfolio) {

    const symbols = Object.keys(portfolio);
    let effectiveDuration = 0;
    for (var i=0; i<symbols.length; i++) {
        const weight = portfolio[symbols[i]]
        const duration = assetDetails[symbols[i]].duration;
        effectiveDuration += duration * weight;
    }
    console.log(effectiveDuration)
    return Math.round(effectiveDuration*10)/10

}

 
export function createNaturalLanguageDescription(riskLevel, runway, duration) {

    const riskWording = {
        veryLowRisk: 'very low risk',
        lowRisk: 'low risk',
        moderateRisk: 'moderate risk',
        aggressiveRisk: 'aggressive risk'
    };
    const runwayLongerThan4 = (runway === '4Plus' || runway === 'cashFlowPositive');
    const treasuryBondSplit = {
        veryLowRisk: '100% US Treasuries, as they are the safest option',
        lowRisk: '100% US Treasuries, as they are the safest option',
        moderateRisk: '60% US Treasuries and 40% US corporate bonds',
        aggressiveRisk: `30% US Treasuries, ${runwayLongerThan4 ? '50' : '60'}% US corporate bonds, and ${runwayLongerThan4 ? '20' : '10'}% high yield corportate bonds`
    };

    const runwayWording = {
        'lessThan1': 'less than 1 year',
        '1To2': '1 to 2 years',
        '2To4': '2 to 4 years',
        '4Plus': '4 or more years',
        'cashFlowPositive': 'cash flow positive'
    }
    const shortVsUltraShortDurationWording = {
        'lessThan1': '100% ultra short duration assets, as you expect to need these funds soon.',
        '1To2': `a mix of two-thirds ultra short duration bonds and one-third short duration bonds in order to give your portfolio a duration of ${duration} years`,
        '2To4': `a mix of one-third ultra short duration bonds and two-thirds short duration bonds in order to give your portfolio a duration of ${duration} years`,
        '4Plus': '100% short duration assets. These give you the highest return',
        'cashFlowPositive': '100% short duration assets. These give you the highest return'
    }

    var description = "Based on your preference for ";
    description += riskWording[riskLevel] + ", we've chosen a portfolio of " + treasuryBondSplit[riskLevel];

    description += '. Given ' + (runway === 'cashFlowPositive' ? 'that you are ' : 'your runway of ')
    description += runwayWording[runway];
    description += ' we have chosen ' + shortVsUltraShortDurationWording[runway] + '. ';

    return description;

}