
import DashboardWrapper from '../../../components/general/DashboardWrapper';
import EtfInfoCard from '../../../components/portfolio/EtfInfoCard';

import { useAssetAllocationHandler } from '../../../logic/dashboard/portfolio/investmentPerformance';

const Portfolio = () => {

    const {
        formattedAssetAllocation, portfolioYield
    } = useAssetAllocationHandler();

    return (
        <DashboardWrapper screen="portfolio">
            <div className="home-container">
                <h2 className="main-header">Portfolio Details</h2>

                <div className="home-card-row">

                    <div className="home-card-allocation-col" style={{ flex: 6 }}>

                        <div style={{ marginTop: "20px" }}>
                            <EtfInfoCard
                                formattedAssetAllocation={formattedAssetAllocation}
                                portfolioYield={portfolioYield}
                            />
                        </div>

                    </div>

                    <div className="home-card-allocation-col" style={{ flex: 1 }}>

                    </div>

                </div>

            </div>
        </ DashboardWrapper>
    )

}

export default Portfolio;

/*

<PortfolioYieldCard
                            portfolioYield={portfolioYield}
                            dollarYield={dollarYield}
                        />
                        
<div className="home-card-allocation-col" style={{ marginRight: "20px" }}>
                        <PortfolioAllocationCard
                            portfolioAllocation={formattedAssetAllocation}
                            isTargetAllocation={isTargetAllocation}
                        />
                    </div>
                    */