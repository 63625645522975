import { Switch, Route } from 'react-router-dom';

import GetStarted from '../screens/createAccount/GetStarted';
import FinancialQuestionnaire from '../screens/createAccount/FinancialQuestionnaire';
import PortfolioRecommendation from '../screens/createAccount/PortfolioRecommendation';
import AuthorizedPerson from '../screens/createAccount/AuthorizedPerson';
import AuthorizedPersonAddress from '../screens/createAccount/AuthorizedPersonAddress';
import PhoneVerification from '../screens/createAccount/PhoneVerification';
import CompanyInfo from '../screens/createAccount/CompanyInfo';
import CompanyAddress from '../screens/createAccount/CompanyAddress';
import BeneficialOwners from '../screens/createAccount/BeneficialOwners';
import BeneficialOwnerPerson from '../screens/createAccount/BeneficialOwnerPerson';
import BeneficialOwnerAddress from '../screens/createAccount/BeneficialOwnerAddress';
import PhotoIdUpload from '../screens/createAccount/PhotoIdUpload';
import DocumentUpload from '../screens/createAccount/DocumentUpload';
import CreateAccount from '../screens/createAccount/CreateAccount';
import AccountCreationConfirmation from '../screens/createAccount/AccountCreationConfirmation';


function CreateAccountNavigator() {

    return (
        <Switch>
            <Route path="/sign-up/get-started">
                <GetStarted />
            </Route>
            <Route path='/sign-up/financial-questionnaire'>
                <FinancialQuestionnaire />
            </Route>
            <Route path='/sign-up/portfolio-recommendation'>
                <PortfolioRecommendation />
            </Route>
            <Route path='/sign-up/authorized-person'>
                <AuthorizedPerson />
            </Route>
            <Route path='/sign-up/authorized-person-address'>
                <AuthorizedPersonAddress />
            </Route>
            <Route path='/sign-up/phone-verification'>
                <PhoneVerification />
            </Route>
            <Route path='/sign-up/company-info'>
                <CompanyInfo />
            </Route>
            <Route path='/sign-up/company-address'>
                <CompanyAddress />
            </Route>
            <Route path='/sign-up/beneficial-owners/'>
                <BeneficialOwners />
            </Route>
            <Route path='/sign-up/beneficial-owner-person/:ownerNumber'>
                <BeneficialOwnerPerson />
            </Route>
            <Route path='/sign-up/beneficial-owner-address/:ownerNumber'>
                <BeneficialOwnerAddress />
            </Route>
            <Route path='/sign-up/photo-id-upload/:ownerNumber'>
                <PhotoIdUpload />
            </Route>
            <Route path='/sign-up/document-upload'>
                <DocumentUpload />
            </Route>
            <Route path='/sign-up/create-account'>
                <CreateAccount />
            </Route>
            <Route path="/sign-up/account-creation-confirmation">
                <AccountCreationConfirmation />
            </Route>
            
        </Switch>
    )

}

export default CreateAccountNavigator;
