
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import DashboardWrapper from '../../../components/general/DashboardWrapper';
import TransferReviewCard from "../../../components/transfers/TransferReviewCard";
import LoadingSpinner from "../../../components/general/LoadingSpinner";
import WithdrawalCard from '../../../components/transfers/WithdrawalCard';
import Modal from '../../../components/general/Modal';

import { handleDollarValueInput } from '../../../utils/generalFunctions';
import { useTransferPageHandler } from '../../../logic/dashboard/transfers/transfers';
import { useWithdrawalHandler } from '../../../logic/dashboard/transfers/withdrawals';

import './Withdrawals.css';

const Withdrawals = () => {

    const history = useHistory();

    const [amount, setAmount] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [withdrawalOrReview, setWithdrawalOrReview] = useState('withdrawal');
    const [selectedAccount, setSelectedAccount] = useState(null);

    const { 
        accountBalance, sendWithdrawalRequest, activityIndicator, successModal, errorModal, setSuccessModal, setErrorModal
     } = useWithdrawalHandler();
    const { sources } = useTransferPageHandler();

    const handleAmount = (event) => {
        const newValue = handleDollarValueInput(amount, event.target.value);
        setAmount(newValue);
    }

    useEffect(() => {
        if (amount.length > 0 && selectedAccount !== null) {
            setButtonDisabled(false);
        }
    }, [amount, selectedAccount])

    useEffect(() => {
        if (sources.length > 0) {
            setSelectedAccount(sources[0].id)
        }
    }, [sources])

    return (

        <DashboardWrapper screen="transfers">
            <div className="deposit-page-container">


                <div className="deposit-header">
                    <div className="deposit-header-col">
                        {withdrawalOrReview == 'review' &&
                            <p
                                onClick={() => setWithdrawalOrReview('withdrawal')}
                                className="semi-bold-font deposit-withdrawal-edit-button">
                                Edit
                            </p>}
                        {withdrawalOrReview == 'withdrawal' &&
                            <p
                                onClick={() => history.goBack()}
                                className="semi-bold-font deposit-withdrawal-edit-button">
                                Back
                            </p>}
                    </div>
                    <div className="deposit-header-main-col">
                        <h2 className="main-header">
                            {withdrawalOrReview == 'withdrawal' ? "Make a withdrawal" : "Review withdrawal"}
                        </h2>
                    </div>
                    <div className="deposit-header-col">

                    </div>
                </div>

                {activityIndicator && <LoadingSpinner />}

                {withdrawalOrReview == 'withdrawal' && <WithdrawalCard
                    accountBalance={accountBalance}
                    sources={sources}
                    setWithdrawalOrReview={setWithdrawalOrReview}
                    amount={amount}
                    handleAmount={handleAmount}
                    buttonDisabled={buttonDisabled}
                    onAccountSelection={(id) => setSelectedAccount(id)}
                />}

                {successModal && <Modal closeModal={() => setSuccessModal(false)}>
                    <div className="linking-account-modal-content">
                        <h2 className="semi-bold-font account-linking-header">Success</h2>
                        <p className="regular-font account-linking-text">
                            Your withdrawal has been successfully processed.
                        </p>
                    </div>
                </Modal>}

                {errorModal && <Modal closeModal={() => setErrorModal(false)}>
                    <div className="linking-account-modal-content">
                        <h2 className="semi-bold-font account-linking-header">Error</h2>
                        <p className="regular-font account-linking-text">
                            Sorry, we couldn't process your withdrawal at this time. 
                            Please try again later or contact support.
                        </p>
                    </div>
                </Modal>}

                {withdrawalOrReview == 'review' && <TransferReviewCard
                    toAccountName={sources.find(source => source.id == selectedAccount).bankName}
                    toAccountType={sources.find(source => source.id == selectedAccount).accountType}
                    toAccountNumber={sources.find(source => source.id == selectedAccount).accountNumber}
                    toAccountBalance={sources.find(source => source.id == selectedAccount).accountBalance}
                    fromAccountName={"Levo"}
                    fromAccountType={"Treasury"}
                    fromAccountNumber={undefined}
                    fromAccountBalance={accountBalance}
                    amount={amount}
                    onSubmitClick={() => sendWithdrawalRequest(amount, selectedAccount)}
                />}


            </div>
        </DashboardWrapper>

    )

}

export default Withdrawals;
