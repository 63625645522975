
import './BeneficialOwnerRow.css';
import '../../styles/generalStyles.css';

const BeneficialOwnerRow = (props) => {

    return (
        <div className="beneficial-owner-row">
            <div>
                <h2 className="regular-font beneficial-owner-name">
                    {props.beneficialOwner.firstName} {props.beneficialOwner.lastName}
                </h2>
            </div>
            <div>
                <h2 className="regular-font beneficial-owner-ownership">
                    {props.beneficialOwner.ownership}%
                </h2>
            </div>
            <div>
                <button
                    onClick={props.onEditClick}
                    className="semi-bold-font beneficial-owner-edit-button"
                    >edit
                </button>
            </div>
        </div>
    )

}

export default BeneficialOwnerRow;