import React, { useState } from 'react';

import TextInput from '../general/TextInput';
import LoadingSpinner from '../../components/general/LoadingSpinner';

import { sendRequestAccessInfo } from '../../logic/marketing/requestAccess';

import dashboardImage from '../../assets/dashboard-card-screenshot-new.png';
import './MobileRequestAccess.css';

const MobileRequestAccess = () => {

    const [firstName, setFirstName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [activityIndicator, setActivityIndicator] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const sendInfo = async () => {
        if (firstName.length < 1 || companyName.length < 2 || emailAddress.length < 2) {
            alert("Your inputs are invalid. Please check that you have filled out all fields")
        } else {
            setActivityIndicator(true)
            const [response, status] = await sendRequestAccessInfo(firstName, companyName, emailAddress)
            setActivityIndicator(false)
            setShowSuccessMessage(true)
        }
    }

    return (
        <div className="mobile-request-access-section">
            <div className="mobile-request-access-container">
                <div className="mobile-phone-image-col">
                    <img className="mobile-dashboard-card-image" src={dashboardImage} />
                </div>

                {(activityIndicator) &&
                    <div className="loading-indicator-container">
                        <LoadingSpinner />
                    </div>
                }

                <div className="mobile-request-access-inputs">
                    <TextInput
                        type={"text"}
                        value={firstName}
                        onTextChange={(event) => setFirstName(event.target.value)}
                        placeholder={"First name"}
                        fontColor={'dark'}
                        customStyle={{ borderWidth: "2px", paddingTop: "14px", paddingBottom: "14px", marginBottom: "8px" }}
                    />
                    <TextInput
                        type={"text"}
                        value={companyName}
                        onTextChange={(event) => setCompanyName(event.target.value)}
                        placeholder={"Company name"}
                        fontColor={'dark'}
                        customStyle={{ borderWidth: "2px", paddingTop: "14px", paddingBottom: "14px", marginBottom: "8px" }}
                    />
                    <TextInput
                        type={"text"}
                        value={emailAddress}
                        onTextChange={(event) => setEmailAddress(event.target.value)}
                        placeholder={"Email address"}
                        fontColor={'dark'}
                        customStyle={{ borderWidth: "2px", paddingTop: "14px", paddingBottom: "14px" }}
                    />
                    <div>
                        <button onClick={() => sendInfo()}
                            className="mobile-request-access-button">
                            Request demo
                        </button>
                        <div className="request-access-success" style={{ display: (showSuccessMessage ? 'block' : 'none') }}>
                            <p className="request-access-success-message" style={{color: "black"}}>
                                Thank you! Your submission has been received.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default MobileRequestAccess;
