import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import CustomCheckbox from '../../components/general/CustomCheckbox';
import TextInput from '../../components/general/TextInput';
import CreateAccountWrapper from '../../components/createAccount/CreateAccountWrapper';
import Address from '../../components/createAccount/Address';

import * as createAccountActions from '../../store/actions/createAccount';

import '../../styles/accountCreationStyles.css';
import '../../styles/generalStyles.css';

const AuthorizedPersonAddress = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    // TODO: possibly save this data to session storage, so we can handle page refreshes
    const savedStreet = useSelector(state => state.createAccount.authorizedPersonStreet);
    const savedUnit = useSelector(state => state.createAccount.authorizedPersonUnit);
    const savedCity = useSelector(state => state.createAccount.authorizedPersonCity);
    const savedState = useSelector(state => state.createAccount.authorizedPersonState);
    const savedZipCode = useSelector(state => state.createAccount.authorizedPersonZipCode);
    const savedCountry = useSelector(state => state.createAccount.authorizedPersonCountry);

    const [address, setAddress] = useState({
        street: savedStreet === undefined ? '' : savedStreet,
        unit: savedUnit === undefined ? '' : savedUnit,
        city: savedCity === undefined ? '' : savedCity,
        state: savedState === undefined ? '' : savedState,
        zipCode: savedZipCode === undefined ? '' : savedZipCode,
    })
    const [citizenshipDifferent, setCitizenshipDifferent] = useState(false);
    const [citizenship, setCitizenship] = useState('');
    const [birthCountryDifferent, setBirthCountryDifferent] = useState(false);
    const [birthCountry, setBirthCountry] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const [country, setCountry] = useState(savedCountry);
    let savedUSAddress;
    if (savedCountry.length > 0) {
        savedUSAddress = false;
    } else if (savedStreet.length > 0) {
        savedUSAddress = true;
    } else {
        savedUSAddress = ""
    }
    const [usAddress, setUsAddress] = useState(savedUSAddress);

    const sendToNext = () => {

        // Update the redux store with the updated beneficial owner
        dispatch(createAccountActions.setAuthorizedPersonAddress(
            address['street'], address['unit'], address['city'], address['state'], address['zip'], country
        ));

        history.push(`/sign-up/company-info`)
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (!buttonDisabled) {
                    sendToNext()
                }
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [buttonDisabled]);

    useEffect(() => {
        if (address['isValidAddress']) {
            // If the user checked the box that their citizenship is different than their residential address
            // make sure they have input a valid country
            if (citizenshipDifferent && !birthCountryDifferent) {
                setButtonDisabled(citizenship.length > 1 ? false : true);
            } else if (birthCountryDifferent && !citizenshipDifferent) {
                setButtonDisabled(birthCountry.length > 1 ? false : true);
            } else if (birthCountryDifferent && citizenshipDifferent) {
                setButtonDisabled((birthCountry.length > 1 && citizenship.length > 1) ? false : true);
            } else {
                setButtonDisabled(false);
            }
        } else if (usAddress === false && country.length > 1) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [address, country, usAddress, citizenshipDifferent, birthCountryDifferent, citizenship, birthCountry]);

    return (
        <CreateAccountWrapper
            buttonDisabled={buttonDisabled}
            onButtonClick={() => sendToNext()}
            onSecondaryTextClick={() => history.goBack()}>
            <div className="section-container">

                <header className="bold-font account-creation-header">
                    Authorized person address
                </header>

                <div>
                    <div style={{ marginTop: 10 }}>
                        <CustomCheckbox
                            headerText={"I have a US address"}
                            customHeaderStyle={{ fontSize: 16, fontWeight: 300 }}
                            isChecked={usAddress}
                            onChangeHandler={() => {setUsAddress(true); setCountry("")}}
                        />
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <CustomCheckbox
                            headerText={"I have a non-US address"}
                            customHeaderStyle={{ fontSize: 16, fontWeight: 300 }}
                            isChecked={usAddress === false}
                            onChangeHandler={() => setUsAddress(false)}
                        />
                    </div>
                </div>

                <p className="light-font sub-header" style={{ display: usAddress ? "flex" : "none" }}>
                    Please enter your permanent address.
                </p>

                <p className="light-font sub-header" style={{ display: usAddress === false ? "flex" : "none" }}>
                    Please enter your country of residence. We will contact you regarding
                    any extra information we may need depending on the country.
                </p>
                <div className="account-creation-input-row" style={{ display: usAddress === false ? "flex" : "none" }}>
                    <TextInput
                        headerMessage={"Country"}
                        value={country}
                        onTextChange={(event) => setCountry(event.target.value)}
                        placeholder="UK, or United Kingdom"
                        inputType={"text"}
                    />
                </div>

                {usAddress && <Address initialAddress={address} setAddress={setAddress} />}

                <div style={{ marginTop: 50, display: usAddress ? "flex" : "none" }}>
                    <CustomCheckbox
                        headerText={"My citizenship is different from my residential address."}
                        customHeaderStyle={{ fontSize: 16, fontWeight: 300 }}
                        isChecked={citizenshipDifferent}
                        onChangeHandler={() => setCitizenshipDifferent(!citizenshipDifferent)}
                    />
                </div>
                <div className="account-creation-input-row" style={{ display: citizenshipDifferent ? 'flex' : 'none' }}>
                    <TextInput
                        headerMessage={"Citizenship country"}
                        value={citizenship}
                        onTextChange={(event) => setCitizenship(event.target.value)}
                        placeholder="US"
                        inputType={"text"}
                    />
                </div>

                <div style={{ marginTop: 10, display: usAddress ? "flex" : "none" }}>
                    <CustomCheckbox
                        headerText={"My country of birth is different from my country of residential address."}
                        customHeaderStyle={{ fontSize: 16, fontWeight: 300 }}
                        isChecked={birthCountryDifferent}
                        onChangeHandler={() => setBirthCountryDifferent(!birthCountryDifferent)}
                    />
                </div>
                <div className="account-creation-input-row" style={{ display: birthCountryDifferent ? 'flex' : 'none' }}>
                    <TextInput
                        headerMessage={"Country of birth"}
                        value={birthCountry}
                        onTextChange={(event) => setBirthCountry(event.target.value)}
                        placeholder="US"
                        inputType={"text"}
                    />
                </div>

            </div>
        </CreateAccountWrapper>
    )

}

export default AuthorizedPersonAddress;