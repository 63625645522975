// Custom button for the account creation pages

import './CustomButton.css';

const CustomButton = (props) => {

    return (
        <div className="main-button-container">
            <button onClick={props.onClick} 
                className={props.disabled === true ? "button button-inactive" : "button"}
                disabled={props.disabled === undefined ? false : props.disabled}>
                {props.buttonText}
            </button>
        </div>
    )

}

export default CustomButton;