// General text input component for the account creation process (this might go into
// the general components at some point though)

import '../../styles/generalStyles.css';
import './TextInput.css';

const TextInput = (props) => {

    return (
        <form className="form-container">
            {(props.headerMessage != undefined && props.headerMessage.length > 0) &&
                <label className="medium-font label">{props.headerMessage}</label>}
            <div className="text-input-container">
                <input
                    type={props.inputType}
                    value={props.value}
                    onChange={props.onTextChange}
                    className={
                        `regular-font text-input ${props.fontColor === undefined ? '' : 'text-input-' + props.fontColor}
                        ${props.hideOutline ? 'text-input-hidden' : ''}`
                    }
                    style={props.customStyle}
                    placeholder={props.placeholder}
                    autoCapitalize={props.autoCapitalize === undefined ? "none" : props.autoCapitalize}
                />
            </div>
        </form>

    )

}

export default TextInput;
