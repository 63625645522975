import React, { useState, useEffect } from "react";

import TextInput from '../../components/general/TextInput';

import { handleStateInput, handleZipCode } from '../../utils/generalFunctions';

const Address = (props) => {

    const [street, setStreet] = useState(props.initialAddress.street === undefined ? "" : props.initialAddress.street);
    const [unit, setUnit] = useState(props.initialAddress.unit === undefined ? "" : props.initialAddress.unit);
    const [city, setCity] = useState(props.initialAddress.city === undefined ? "" : props.initialAddress.city);
    const [state, setState] = useState(props.initialAddress.state === undefined ? "" : props.initialAddress.state);
    const [zip, setZip] = useState(props.initialAddress.zipCode === undefined ? "" : props.initialAddress.zipCode);

    const handleState = (event) => {
        const newState = handleStateInput(state, event.target.value);
        setState(newState);
    }

    const handleZip = (event) => {
        const newZip = handleZipCode(zip, event.target.value);
        setZip(newZip)
    }

    useEffect(() => {
        let validAddress;
        // Check each value and update the isValidAddress state variable
        if (street.length > 1 && city.length > 1 && state.length === 2 && zip.length === 5) {
            validAddress = true;
        } else {
            validAddress = false;
        }
        // Call the callback function that was passed in as a prop
        props.setAddress({
            street: street,
            unit: unit,
            city: city,
            state: state,
            zip: zip,
            isValidAddress: validAddress
        })
    }, [street, unit, city, state, zip])

    return <div className="section-container">

        <div className="account-creation-input-row">
            <TextInput
                value={street}
                headerMessage={"Street address"}
                onTextChange={(event) => setStreet(event.target.value)}
                placeholder="100 S Main St"
                inputType={"text"}
            />
        </div>

        <div className="account-creation-input-row">
            <TextInput
                value={unit}
                headerMessage={"Unit"}
                onTextChange={(event) => setUnit(event.target.value)}
                placeholder={"Apt #100"}
                inputType={"text"}
            />
        </div>

        <div className="account-creation-input-row">
            <TextInput
                value={city}
                headerMessage={"City"}
                onTextChange={(event) => setCity(event.target.value)}
                placeholder={"San Francisco"}
                inputType={"text"}
            />
        </div>

        <div className="account-creation-input-row">
            <div className="first-name-container">
                <TextInput
                    value={state}
                    headerMessage={"State"}
                    onTextChange={handleState}
                    placeholder={"CA"}
                    inputType={"text"}
                />
            </div>
            <div className="last-name-container">
                <TextInput
                    value={zip}
                    headerMessage={"Zip code"}
                    onTextChange={handleZip}
                    placeholder={"94102"}
                    inputType={"text"}
                />
            </div>
        </div>

    </div>

}

export default Address;