import './Disclaimers.css';
import termsOfServicePDF from '../../assets/pdfs/termsOfService.pdf';
import privacyPDF from '../../assets/pdfs/privacyPolicy.pdf';

const Disclaimers = (props) => {
    return (
        <div className="disclaimers-section">
            <div className="disclaimers-container">

                <div className="disclaimer-pdf-row">
                    <div className="disclaimer-pdf-col">
                        <a href={termsOfServicePDF} target="_blank" className="disclaimer-pdf-link">Terms of Service</a>
                    </div>
                    <div className="disclaimer-pdf-col">
                        <a href={privacyPDF} target="_blank" className="disclaimer-pdf-link">Privacy Policy</a>
                    </div>
                </div>

                <div className="disclaimer-row">
                    <p className="disclaimer-text">
                        Levo Financial is not a bank. Investments are not FDIC-insured, nor are they
                        deposits of or guaranteed by a bank or any other entity, so they may lose value.
                    </p>
                </div>

                <div className="disclaimer-row">
                    <p className="disclaimer-text">
                        By using this website, you understand the information being presented is provided for
                        informational purposes only and agree to our Terms of Use and Privacy Policy. Levo
                        Financial relies on information from various sources believed to be reliable but
                        cannot guarantee the accuracy and completeness of that information. Nothing in this
                        communication should be construed as an offer, recommendation, or solicitation to buy
                        or sell any security. Additionally, Levo Financial and its affiliates do not provide
                        tax advice and investors are encouraged to consult with their personal tax advisors.
                    </p>
                </div>

                <div className="disclaimer-row">
                    <p className="disclaimer-text">
                        Investment advisory services are provided by Levo Adviser, LLC, an SEC-registered investment
                        adviser, and brokerage products and services are provided by Embed Clearing LLC, member FINRA/SIPC.
                    </p>
                </div>

                <div className="disclaimer-row">
                    <p className="disclaimer-text">
                        All accounts are subject to approval and you may cancel email subscriptions at any time.
                    </p>
                </div>

                <div className="disclaimer-row">
                    <p className="disclaimer-text">
                        This content, developed by Levo Financial, should not be used as a primary basis for
                        investment decisions and is not intended to serve as impartial investment or fiduciary advice.
                    </p>
                </div>

                <div className="disclaimer-row">
                    <p className="disclaimer-text">
                        For support, please contact support@levo.financial
                    </p>
                </div>

            </div>
        </div>
    )
}

export default Disclaimers;
