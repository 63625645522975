import { useHistory } from 'react-router-dom';

import CardLayout from '../../components/general/CardLayout';
import TransferRow from './TransferRow';

import './TransfersCard.css';


const TransfersCard = (props) => {

    const history = useHistory();

    return (

        <CardLayout customStyle={{borderRadius: "5px"}}>

            <div className="transfers-container">

                <h2 className="medium-font transfers-header">Recent transfers</h2>

                {props.transfers.map((transfer, index) =>
                    <TransferRow
                        transfer={transfer}
                        key={index}
                    />
                )}

                <p onClick={() => history.push('/dashboard/transfers')}
                    className="semi-bold-font transfers-card-view-all-text"
                    style={{display: props.hideViewAll ? 'none' : 'all'}}>
                    View all
                </p>

            </div>

        </CardLayout>

    )

}

export default TransfersCard;
