import React, { useState } from "react";

import DashboardWrapper from '../../../components/general/DashboardWrapper';
import DocumentUploadRow from '../../../components/dashboard/DocumentUploadRow';
import CustomButton from '../../../components/general/CustomButton';

import { useDocumentHandler } from '../../../logic/dashboard/account/account';
import LoadingSpinner from "../../../components/general/LoadingSpinner";

import './DocumentUpload.css';

const DocumentUpload = () => {

    const { companyDocNames, updateDocuments, activityIndicator } = useDocumentHandler();
    const [newDocs, setNewDocs] = useState({});

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const imageUploadHandler = async (event, docName) => {

        const file = event.target.files[0];
        const type = file.type;
        if (type != "image/jpeg" && type != "image/png" && type != "application/pdf") {
            alert("Only jpeg, png and pdf files are accepted")
            return;
        }

        const base64 = await convertBase64(file);
        setNewDocs({ ...newDocs, [docName]: {base64: base64, name: event.target.files[0].name} })

    }

    return (
        <DashboardWrapper screen="account">
            <div className="home-container">
                <h2 className="main-header">Document upload</h2>

                {activityIndicator && <LoadingSpinner />}

                <div className="document-container">
                    <DocumentUploadRow
                        documentName={"Articles of incorporation"}
                        isUploaded={companyDocNames.includes('aoi')}
                        onChange={(event) => imageUploadHandler(event, "aoi")}
                        filename={(Object.keys(newDocs).includes('aoi') ? newDocs['aoi'].name : "")}
                        docName={"aoi"}
                    />
                    <DocumentUploadRow
                        documentName={"EIN letter"}
                        isUploaded={companyDocNames.includes('ein')}
                        onChange={(event) => imageUploadHandler(event, "ein")}
                        filename={(Object.keys(newDocs).includes('ein') ? newDocs['ein'].name : "")}
                        docName={"ein"}
                    />
                    <DocumentUploadRow
                        documentName={"Photo ID"}
                        isUploaded={companyDocNames.includes('photo_id')}
                        onChange={(event) => imageUploadHandler(event, "photo_id")}
                        filename={(Object.keys(newDocs).includes('photo_id') ? newDocs['photo_id'].name : "")}
                        docName={"photo_id"}
                    />
                </div>

                <div className="document-upload-button-container">
                    <CustomButton
                        buttonText={"Upload"}
                        disabled={Object.keys(newDocs).length == 0}
                        onClick={() => {updateDocuments(newDocs); setNewDocs({})}}
                        />
                </div>

            </div>
        </DashboardWrapper>
    )

}

export default DocumentUpload;