import { useHistory } from 'react-router-dom';

import CreateAccountWrapper from '../../components/createAccount/CreateAccountWrapper';

import './AccountCreationConfirmation.css';
import '../../styles/accountCreationStyles.css';

const AccountCreationConfirmation = (props) => {

    const history = useHistory();

    return (
        <CreateAccountWrapper
            buttonDisabled={false}
            onButtonClick={() => history.push('/')}
            buttonText={'Go to login'}
            hideBackButton={true}>
            <div className="section-container">
                <header className="bold-font header">
                    Congratulations!
                </header>
                
                <p className="light-font sub-header">
                    Your application has been successfully submitted. 
                </p>
                
            </div>
        </CreateAccountWrapper>
    )

}

export default AccountCreationConfirmation;