
export const SET_TRANSFERS = 'SET_TRANSFERS';
export const SET_EMBED_SOURCES = 'SET_EMBED_SOURCES';


export const setTransfers = (transfers) => {
    return dispatch => {
        dispatch({ type: SET_TRANSFERS, transfers: transfers });
    }
}

export const setEmbedSources = (embedSources) => {
    return dispatch => {
        dispatch({ type: SET_EMBED_SOURCES, embedSources: embedSources });
    }
}
