import {
    SET_ACCOUNT_BALANCE
} from '../actions/home';
import { RESET_HOME } from '../actions/auth';


const initialState = {
    accountBalance: '',
}

export default (state = initialState, action) => {

    switch (action.type) {
        case SET_ACCOUNT_BALANCE:
            return {
                ...state,
                accountBalance: action.accountBalance,
            };
        case RESET_HOME:
            return {
                initialState
            };
        default:
          return state;
    }

}
