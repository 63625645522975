import {
    SET_PORTFOLIO,
    SET_USER_NAVS,
    SET_ALLOCATION,
    SET_TARGET_ALLOCATION,
    SET_CURRENT_YIELDS,
} from '../actions/portfolio';
import { RESET_PORTFOLIO } from '../actions/auth';


const initialState = {
    portfolio: {},
    userNAVs: [],
    allocation: [],
    targetAllocation: {},
    currentYields: {}
}

export default (state = initialState, action) => {

    switch (action.type) {
        case SET_PORTFOLIO:
            return {
                ...state,
                portfolio: action.portfolio,
            };
        case SET_USER_NAVS:
            return {
                ...state,
                userNAVs: action.userNAVs,
        };
        case SET_ALLOCATION:
            return {
                ...state,
                allocation: action.allocation,
            };
        case SET_TARGET_ALLOCATION:
            return {
                ...state,
                targetAllocation: action.targetAllocation,
        };
        case SET_CURRENT_YIELDS:
            return {
                ...state,
                currentYields: action.currentYields,
                yieldUpdateDate: action.yieldUpdateDate,
            }
        case RESET_PORTFOLIO:
            return {
                initialState
            }
        default:
          return state;
    }

}
