import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import CreateAccountWrapper from '../../components/createAccount/CreateAccountWrapper';
import CustomCheckbox from '../../components/general/CustomCheckbox';
import TextInput from '../../components/general/TextInput';

import { createUserID } from '../../utils/generalFunctions';
import params from '../../config/params';
import * as createAccountActions from '../../store/actions/createAccount';
import { handleDollarValueInput, getNextSignUpPage } from '../../utils/generalFunctions';

import './FinancialQuestionnaire.css';

const FinancialQuestionnaire = () => {

    const savedRiskLevel = useSelector(state => state.createAccount.riskLevel);
    const savedRunway = useSelector(state => state.createAccount.runway);
    const savedCashBalance = useSelector(state => state.createAccount.cashBalance);

    const [riskLevel, setRiskLevel] = useState(savedRiskLevel);
    const [runway, setRunway] = useState(savedRunway);
    const [cashBalance, setCashBalance] = useState(savedCashBalance);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const history = useHistory();
    const dispatch = useDispatch();

    const sendToNext = () => {
        const userID = createUserID()
        dispatch(createAccountActions.setLevoUserID(userID, params.useDefaultDevParams))
        dispatch(createAccountActions.setFinancialGoals(riskLevel, cashBalance, runway))
        const nextPage = getNextSignUpPage(window.location.pathname);
        history.push(nextPage)
    }

    const handleCashBalance = (event) => {
        const newValue = handleDollarValueInput(cashBalance, event.target.value);
        setCashBalance(newValue);
    }

    useEffect(() => {
        if (riskLevel === '' || cashBalance === '' || runway === '') {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }
    }, [riskLevel, cashBalance, runway])

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (!buttonDisabled) {
                    sendToNext()
                }
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [buttonDisabled, riskLevel, cashBalance, runway]);

    return (

        <CreateAccountWrapper
            buttonDisabled={buttonDisabled}
            onButtonClick={() => sendToNext()}
            onSecondaryTextClick={() => history.goBack()}>

            <header className="bold-font account-creation-header">
                Financial questionnaire
            </header>
            <p className="light-font sub-header">
                We’ll use the answers to the following questions to suggest a portfolio allocation
                for your account, so please answer them as accurately as possible.
            </p>

            <div className="financial-question-section">

                <h2 className="bold-font question-title">What do you hope to achieve with your Levo account?</h2>

                <CustomCheckbox
                    headerText={'Very low risk and return'}
                    textComponent={(
                        <p className="light-font">
                            I want to earn a slighlty higher return than I can get from
                            a bank account, and I want the lowest risk possible
                        </p>
                    )}
                    isChecked={riskLevel === 'veryLowRisk'}
                    onChangeHandler={() => setRiskLevel('veryLowRisk')}
                />
                <CustomCheckbox
                    headerText={'Low risk and return'}
                    textComponent={(
                        <p className="light-font">
                            I want to earn a higher return than I can get from a
                            bank account, but I don’t want to take on too much risk
                        </p>
                    )}
                    isChecked={riskLevel === 'lowRisk'}
                    onChangeHandler={() => setRiskLevel('lowRisk')}
                />
                <CustomCheckbox
                    headerText={'Moderate risk and return'}
                    textComponent={(
                        <p className="light-font">
                            I want to earn a moderate return, and am comfortable
                            taking a moderate amount of risk to achieve that
                        </p>
                    )}
                    isChecked={riskLevel === 'moderateRisk'}
                    onChangeHandler={() => setRiskLevel('moderateRisk')}
                />
                <CustomCheckbox
                    headerText={'Aggressive risk and return'}
                    textComponent={(
                        <p className="light-font">
                            I want to earn a high return, and am comfortable
                            taking a high amount of risk to achieve that
                        </p>
                    )}
                    isChecked={riskLevel === 'aggressiveRisk'}
                    onChangeHandler={() => setRiskLevel('aggressiveRisk')}
                />

            </div>

            <div className="financial-question-section">

                <h2 className="bold-font question-title">What is your company’s approximate cash balance?</h2>
                <p className="regular-font">
                    Include cash, money market funds, and any other liquid investments you have
                </p>

                <TextInput
                    value={cashBalance}
                    headerMessage={""}
                    onTextChange={handleCashBalance}
                    placeholder={"2,000,000"}
                    inputType={"text"}
                />

            </div>

            <div className="financial-question-section">

                <h2 className="bold-font question-title">What is your current runway?</h2>

                <CustomCheckbox
                    headerText={'< 1 year'}
                    textComponent={''}
                    isChecked={runway === 'lessThan1'}
                    onChangeHandler={() => setRunway('lessThan1')}
                />
                <CustomCheckbox
                    headerText={'1-2 years'}
                    textComponent={''}
                    isChecked={runway === '1To2'}
                    onChangeHandler={() => setRunway('1To2')}
                />
                <CustomCheckbox
                    headerText={'2-4 years'}
                    textComponent={''}
                    isChecked={runway === '2To4'}
                    onChangeHandler={() => setRunway('2To4')}
                />
                <CustomCheckbox
                    headerText={'4+ years'}
                    textComponent={''}
                    isChecked={runway === '4Plus'}
                    onChangeHandler={() => setRunway('4Plus')}
                />
                <CustomCheckbox
                    headerText={"We're cash flow positive"}
                    textComponent={''}
                    isChecked={runway === 'cashFlowPositive'}
                    onChangeHandler={() => setRunway('cashFlowPositive')}
                />


            </div>

        </CreateAccountWrapper>

    )

}

export default FinancialQuestionnaire;
