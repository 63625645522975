import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeLoginRequest } from '../../../services/auth';
import * as authActions from '../../../store/actions/auth';

const { REACT_APP_SESSION_LOGOUT_TIMER } = process.env;


export function useLoginHandler() {

    const dispatch = useDispatch();
    const history = useHistory();
    const [loadingIndicatorDisplay, setLoadingIndicatorDisplay] = useState(false)

    async function performLogin(email, password) {
        setLoadingIndicatorDisplay(true)
        const [resData, status] = await makeLoginRequest(email, password);
        setLoadingIndicatorDisplay(false)

        if (status !== 200) {
            alert("Invalid credentials")
            return
        } else {
            const userID = resData.user_id;
            const sessionToken = resData.session_token;
            console.log(userID)
            sessionStorage.setItem("userID", userID);
            sessionStorage.setItem("sessionToken", sessionToken);
            dispatch(authActions.authenticate(userID, sessionToken, REACT_APP_SESSION_LOGOUT_TIMER*60*1000, history))
            history.push('/dashboard')
        }
    }

    return { performLogin, loadingIndicatorDisplay }

}
