
import './CardLayout.css';

const CardLayout = (props) => {

    return (
        <div className="card-container" style={props.customStyle}>
            {props.children}
        </div>
    )

}

export default CardLayout;
