import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Header from './Header';
import TextInput from '../general/TextInput';
import LoadingSpinner from '../../components/general/LoadingSpinner';
import RequestDemo from './RequestDemo';

import { sendStartedSignUpInfo } from '../../logic/marketing/requestAccess';

import dashboardImage from '../../assets/dashboard-card-screenshot-new.png';
import './MainSection.css';


const MainSection = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [activityIndicator, setActivityIndicator] = useState(false);
    const [showRequestDemoModal, setShowRequestDemoModal] = useState(false);

    const sendInfo = async () => {
        if (firstName.length < 1 || companyName.length < 2 || emailAddress.length < 2) {
            alert("Your inputs are invalid. Please check that you have filled out all fields")
        } else {
            setActivityIndicator(true)
            const [response, status] = await sendStartedSignUpInfo(firstName, companyName, emailAddress)
            setActivityIndicator(false)
            // Save the name, company name, and email to session storage and redux
            dispatch({ type: 'SET_FIRST_NAME', firstName: firstName })
            dispatch({ type: 'SET_COMPANY_NAME', companyName: companyName })
            dispatch({ type: 'SET_EMAIL_ADDRESS', emailAddress: emailAddress })
            sessionStorage.setItem('firstName', firstName)
            sessionStorage.setItem('companyName', companyName)
            sessionStorage.setItem('emailAddress', emailAddress)

            // Send the user to the next screen
            history.push('/sign-up/get-started')
        }
    }

    return (
        <div className="main-section">
            <Header />
            <div className="home-main-container">
                <div className="title-col">
                    <h2 className="main-title">Treasury management for startups</h2>
                    <p className="main-title-sub-text">
                        Levo Treasury is a treasury management account that helps you
                        earn a higher yield on your company’s idle cash.
                    </p>

                    {(activityIndicator) &&
                        <div className="loading-indicator-container">
                            <LoadingSpinner />
                        </div>
                    }

                    {showRequestDemoModal &&
                        <RequestDemo
                            firstName={firstName}
                            companyName={companyName}
                            emailAddress={emailAddress}
                            closeModal={() => setShowRequestDemoModal(false)}
                        />
                    }

                    <div className="request-access-input-section" style={{display: "none"}}>
                        <div className="home-first-name-request-access-container">
                            <TextInput
                                type={"text"}
                                value={firstName}
                                onTextChange={(event) => setFirstName(event.target.value)}
                                placeholder={"First name"}
                                fontColor={'light'}
                                customStyle={{ borderWidth: "2px", paddingTop: "14px", paddingBottom: "14px" }}
                            />
                        </div>
                        <div className="home-company-name-request-access-container">
                            <TextInput
                                type={"text"}
                                value={companyName}
                                onTextChange={(event) => setCompanyName(event.target.value)}
                                placeholder={"Company legal name"}
                                fontColor={'light'}
                                customStyle={{ borderWidth: "2px", paddingTop: "14px", paddingBottom: "14px" }}
                            />
                        </div>
                    </div>

                    <div className="home-request-access-section" style={{display: "none"}}>
                        <TextInput
                            type={"text"}
                            value={emailAddress}
                            onTextChange={(event) => setEmailAddress(event.target.value)}
                            placeholder={"Email address"}
                            fontColor={'light'}
                            customStyle={{ borderWidth: "2px", paddingTop: "14px", paddingBottom: "14px" }}
                        />
                        <div>
                            <button onClick={() => sendInfo()}
                                className="request-access-button">
                                Get started
                            </button>
                        </div>
                        <div className="footer-request-demo-container">
                            <p className="semi-bold-font footer-request-demo-text" onClick={() => setShowRequestDemoModal(true)}>
                                Request demo
                            </p>
                        </div>
                    </div>

                </div>
                <div className="main-home-image-col">
                    <img className="dashboard-card-image" src={dashboardImage} />
                </div>
            </div>
        </div>
    )

}

export default MainSection;
