import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import CreateAccountWrapper from '../../components/createAccount/CreateAccountWrapper';
import TextInput from '../../components/general/TextInput';
import CustomCheckbox from '../../components/general/CustomCheckbox';
import LoadingSpinner from '../../components/general/LoadingSpinner';

import * as createAccountActions from '../../store/actions/createAccount';
import { handlePhoneNumberInput, formatPhoneNumber, handleDOB, handleSSN } from '../../utils/generalFunctions';
import { getPhoneVerificationCodes } from '../../services/auth';

import TermsOfServicePDF from '../../assets/pdfs/termsOfService.pdf';
import PrivacyPDF from '../../assets/pdfs/privacyPolicy.pdf';

import '../../styles/accountCreationStyles.css';
import '../../styles/generalStyles.css';
import './AuthorizedPerson.css';

const AuthorizedPerson = () => {

    const savedFirstName = useSelector(state => state.createAccount.authorizedPersonFirstName);
    const savedLastName = useSelector(state => state.createAccount.authorizedPersonLastName);
    const savedDOB = useSelector(state => state.createAccount.authorizedPersonDOB);
    const savedSSN = useSelector(state => state.createAccount.authorizedPersonSSN);
    const savedPhone = useSelector(state => state.createAccount.phoneNumber);
    const savedVerifiedPhone = useSelector(state => state.createAccount.verifiedPhone);
    const userID = useSelector(state => state.createAccount.levoUserID);
    const savedDirector = useSelector(state => state.createAccount.isDirector);
    const savedTitle = useSelector(state => state.createAccount.authorizedPersonTitle);

    const [firstName, setFirstName] = useState(savedFirstName);
    const [lastName, setLastName] = useState(savedLastName);
    const [phone, setPhone] = useState(savedPhone);
    const [dob, setDob] = useState(savedDOB);
    const [ssn, setSsn] = useState(savedSSN);
    const [isDirector, setIsDirector] = useState(savedDirector);
    const [isOfficer, setIsOfficer] = useState(savedTitle === '' ? false : true);
    const [title, setTitle] = useState(savedTitle);

    const [isChecked, setIsChecked] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [activityIndicator, setActivityIndicator] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const phoneNumberHandler = (event) => {
        const newText = handlePhoneNumberInput(phone, event.target.value);
        setPhone(newText);
    }

    const dobHandler = (event) => {
        const newText = handleDOB(dob, event.target.value);
        setDob(newText);
    }

    const ssnHandler = (event) => {
        const newText = handleSSN(ssn, event.target.value);
        setSsn(newText);
    }

    const sendToNext = async () => {
        dispatch(createAccountActions.setAuthorizedPersonInfo(firstName, lastName, dob, ssn, isDirector, isOfficer, title));
        dispatch(createAccountActions.setPhone(phone))

        if (phone === savedVerifiedPhone) {
            history.push('/sign-up/company-info');
        } else {
            setButtonDisabled(true);
            setActivityIndicator(true)
            const phoneNumber = formatPhoneNumber(phone)
            const [response, status] = await getPhoneVerificationCodes(userID, phoneNumber)
            setActivityIndicator(false)
            setButtonDisabled(false);
            if (status === 200) {
                history.push('/sign-up/phone-verification');
            } else {
                alert("Sorry, we coulnd't process your request at this time. Please try again later, or contact support")
            }

        }
    }

    const textComponent = (
        <div style={{ flexDirection: 'row' }}>
            <p className="light-font checkbox-text">{"By checking this box, I agree to Levo’s "}
                <a href={PrivacyPDF} target="_blank"
                    className="light-font link-text">
                    Privacy Policy
                </a> {" and "}
                <a href={TermsOfServicePDF} target="_blank"
                    className="light-font link-text">
                    Terms and Conditions
                </a>
                {", and I agree that I am authorized to open financial accounts on behalf of the company named above."}
            </p>
        </div>
    )

    useEffect(() => {
        if (isChecked && firstName.length > 1 && lastName.length > 1 && phone.length === 12
            && dob.length === 10 && ssn.length === 11) {
            if (isOfficer && title.length === 0) {
                // If the user is an officer, they must enter a title
                setButtonDisabled(true);
            } else {
                setButtonDisabled(false);
            }
        } else {
            setButtonDisabled(true);
        }
    }, [firstName, lastName, phone, dob, ssn, isChecked, isOfficer, title])

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (!buttonDisabled) {
                    sendToNext()
                }
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [buttonDisabled, firstName, lastName, phone, dob, ssn, isChecked, isOfficer, title]);

    return (
        <CreateAccountWrapper
            buttonDisabled={buttonDisabled}
            onButtonClick={() => sendToNext()}
            onSecondaryTextClick={() => history.goBack()}>

            <div className="section-container">
                <header className="bold-font account-creation-header">
                    Authorized person
                </header>
                <p className="light-font sub-header">
                    We need some basic personal information about you, since you’ll have full
                    access to this account. You’ll be able to add additional authorized persons later.
                </p>

                <div className="account-creation-input-row">
                    <div className="first-name-container">
                        <TextInput
                            headerMessage={"Legal first name"}
                            onTextChange={(event) => setFirstName(event.target.value)}
                            placeholder="John"
                            inputType={"text"}
                            value={firstName}
                        />
                    </div>
                    <div className="last-name-container">
                        <TextInput
                            headerMessage={"Legal last name"}
                            onTextChange={(event) => setLastName(event.target.value)}
                            placeholder="Doe"
                            inputType={"text"}
                            value={lastName}
                        />
                    </div>
                </div>

                <div className="account-creation-input-row">
                    <TextInput
                        value={dob}
                        headerMessage={"Date of birth"}
                        onTextChange={dobHandler}
                        placeholder={"01/20/1990"}
                        inputType={"text"}
                    />
                </div>

                <div className="account-creation-input-row">
                    <TextInput
                        headerMessage={"Phone number"}
                        value={phone}
                        onTextChange={phoneNumberHandler}
                        placeholder="111-222-3333"
                        inputType={"text"}
                    />
                </div>

                <div className="account-creation-input-row">
                    <TextInput
                        value={ssn}
                        headerMessage={"SSN"}
                        onTextChange={ssnHandler}
                        placeholder="123-12-1234"
                        inputType={"text"}
                    />
                </div>

                <div style={{ marginTop: "40px" }}>
                    <p className="regular-font">Do any of the following apply to you?</p>
                    <div style={{ marginTop: "0px" }}>
                        <CustomCheckbox
                            headerText={"Director"}
                            customHeaderStyle={{ fontSize: 16, fontWeight: 400 }}
                            isChecked={isDirector}
                            onChangeHandler={() => setIsDirector(!isDirector)}
                        />
                    </div>
                    <div style={{ marginTop: "0px" }}>
                        <CustomCheckbox
                            headerText={"Officer"}
                            customHeaderStyle={{ fontSize: 16, fontWeight: 400 }}
                            isChecked={isOfficer}
                            onChangeHandler={() => setIsOfficer(!isOfficer)}
                        />
                    </div>

                    <div className="account-creation-input-row" style={{ display: isOfficer ? "flex" : "none" }}>
                        <TextInput
                            value={title}
                            headerMessage={"Title"}
                            onTextChange={(event) => setTitle(event.target.value)}
                            placeholder="CEO"
                            inputType={"text"}
                        />
                    </div>
                </div>

                {(activityIndicator) &&
                    <div className="loading-indicator-container">
                        <LoadingSpinner />
                    </div>
                }

                <div style={{ marginTop: 50 }}>
                    <CustomCheckbox
                        textComponent={textComponent}
                        isChecked={isChecked}
                        onChangeHandler={() => setIsChecked(!isChecked)}
                    />
                </div>

            </div>
        </CreateAccountWrapper>
    )

}

export default AuthorizedPerson;
