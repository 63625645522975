import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as createAccountActions from '../../store/actions/createAccount';


export function formatBeneficialOwners(beneficialOwners) {
    const keys = Object.keys(beneficialOwners)
    var formattedBeneficialOwners = []
    for (var i = 0; i < keys.length; i++) {
        if (beneficialOwners[i].isComplete) {
            formattedBeneficialOwners.push(beneficialOwners[i])
        }
    }
    return formattedBeneficialOwners;
}


export function useBeneficialOwnersHandler() {

    const history = useHistory();
    const dispatch = useDispatch();

    const beneficialOwners = useSelector(state => state.createAccount.beneficialOwners);

    const authorizedPersonFirstName = useSelector(state => state.createAccount.authorizedPersonFirstName);
    const authorizedPersonLastName = useSelector(state => state.createAccount.authorizedPersonLastName);
    const authorizedPersonDOB = useSelector(state => state.createAccount.authorizedPersonDOB);
    const authorizedPersonSSN = useSelector(state => state.createAccount.authorizedPersonSSN);
    const phoneNumber = useSelector(state => state.createAccount.phoneNumber);
    const emailAddress = useSelector(state => state.createAccount.email);

    const authorizedPersonStreet = useSelector(state => state.createAccount.authorizedPersonStreet);
    const authorizedPersonUnit = useSelector(state => state.createAccount.authorizedPersonUnit);
    const authorizedPersonCity = useSelector(state => state.createAccount.authorizedPersonCity);
    const authorizedPersonState = useSelector(state => state.createAccount.authorizedPersonState);
    const authorizedPersonZipCode = useSelector(state => state.createAccount.authorizedPersonZipCode);
    const authorizedPersonTitle = useSelector(state => state.createAccount.authorizedPersonTitle);

    const [authorizedPerson, setAuthorizedPerson] = useState({})
    const [isAuthorizedPersonAdded, setIsAuthorizedPersonAdded] = useState(false)

    function checkAuthorizedPersonIsBeneficialOwner() {
        if (beneficialOwners != undefined) {
            const keys = Object.keys(beneficialOwners)
            for (var i = 0; i < keys.length; i++) {
                if (beneficialOwners[i].firstName === authorizedPersonFirstName
                    && beneficialOwners[i].lastName === authorizedPersonLastName &&  beneficialOwners[i].isComplete) {
                    setIsAuthorizedPersonAdded(true)
                    return
                }
            }
        }
        setIsAuthorizedPersonAdded(false)
    }

    function addAuthorizedPersonAsBeneficialOwner(authorizedPerson, formattedBeneficialOwners) {
        const index = formattedBeneficialOwners.length;
        var updatedBeneficialOwners = formattedBeneficialOwners;
        updatedBeneficialOwners[index] = authorizedPerson;
        dispatch(createAccountActions.setBeneficialOwners(updatedBeneficialOwners));
        history.push(`/sign-up/beneficial-owner-person/${index}`)
    }

    useEffect(() => {
        setAuthorizedPerson({
            firstName: authorizedPersonFirstName,
            lastName: authorizedPersonLastName,
            dob: authorizedPersonDOB,
            ssn: authorizedPersonSSN,
            phone: phoneNumber,
            email: emailAddress,
            jobTitle: authorizedPersonTitle,
            street: authorizedPersonStreet,
            unit: authorizedPersonUnit,
            city: authorizedPersonCity,
            state: authorizedPersonState,
            zip: authorizedPersonZipCode,
        })
        checkAuthorizedPersonIsBeneficialOwner()
    }, [beneficialOwners])

    return { authorizedPerson, isAuthorizedPersonAdded, addAuthorizedPersonAsBeneficialOwner }

}
