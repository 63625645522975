import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CreateAccountWrapper from '../../components/createAccount/CreateAccountWrapper';
import TextInput from '../../components/general/TextInput';
import CustomCheckbox from '../../components/general/CustomCheckbox';

import * as createAccountActions from '../../store/actions/createAccount';
import { handleStateInput } from '../../utils/generalFunctions';
import { getNextSignUpPage } from '../../utils/generalFunctions';

import './CompanyInfo.css';
import '../../styles/accountCreationStyles.css';
import '../../styles/generalStyles.css';


const CompanyInfo = () => {

    const savedEin = useSelector(state => state.createAccount.ein);
    const savedCompanyName = useSelector(state => state.createAccount.companyName);
    const savedEntityType = useSelector(state => state.createAccount.entityType);
    const savedFormationState = useSelector(state => state.createAccount.formationState);

    const [companyName, setCompanyName] = useState(savedCompanyName);
    const [ein, setEin] = useState(savedEin);
    const [entityType, setEntityType] = useState(savedEntityType);
    const [formationState, setFormationState] = useState(savedFormationState);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const history = useHistory();
    const dispatch = useDispatch();

    // Handle the text input for the SSN
    const handleEINInput = (text) => {

        const validCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        if (ein.length > text.length) {
            if (ein.slice(-1) == '-') {
                setEin(ein.slice(0, -2));
            } else {
                setEin(ein.slice(0, -1));
            }

        } else if (validCharacters.indexOf(text.slice(-1)) == -1 || ein.length >= 10) {
            // Do nothing
        } else {
            if (text.length == 2) {
                setEin(text + '-');
            } else {
                setEin(text);
            }
        }

    }

    const handleFormationState = (value) => {
        const newState = handleStateInput(formationState, value);
        setFormationState(newState);
    }

    const sendToNext = () => {
        dispatch(createAccountActions.setCompanyInfo(companyName, ein, entityType, formationState));
        const nextPage = getNextSignUpPage(window.location.pathname);
        history.push(nextPage)
    }

    useEffect(() => {
        if (entityType === 'other') {
            // We can't accept entity types other than LLC, S-Corp, or C-Corp
            setButtonDisabled(true);
        } else if (companyName.length > 1 && ein.length === 10 && formationState.length === 2 && entityType.length > 1) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [companyName, ein, entityType, formationState])

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (!buttonDisabled) {
                    sendToNext()
                }
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [buttonDisabled]);

    return (
        <CreateAccountWrapper
            buttonDisabled={buttonDisabled}
            onButtonClick={() => sendToNext()}
            onSecondaryTextClick={() => history.goBack()}>
            <div className="section-container">
                <header className="bold-font account-creation-header">
                    Company information
                </header>
                <p className="light-font sub-header">
                    Time to get some important information about your company.
                </p>

                <div className="account-creation-input-row">
                    <TextInput
                        headerMessage={"Company legal name"}
                        onTextChange={(event) => setCompanyName(event.target.value)}
                        placeholder="Acme Inc."
                        inputType={"text"}
                        value={companyName}
                    />
                </div>

                <div className="account-creation-input-row">
                    <TextInput
                        value={ein}
                        headerMessage={"EIN"}
                        onTextChange={(event) => handleEINInput(event.target.value)}
                        placeholder={"12-3456789"}
                        inputType={"text"}
                    />
                </div>

                <div style={{ marginTop: "50px", marginBottom: "40px" }}>
                    <p className="medium-font">What is your organization type?</p>
                    <div style={{ marginTop: "0px" }}>
                        <CustomCheckbox
                            headerText={"Corporation"}
                            customHeaderStyle={{ fontSize: 14, fontWeight: 400 }}
                            isChecked={entityType === 'corporation'}
                            onChangeHandler={() => setEntityType('corporation')}
                        />
                    </div>
                    <div style={{ marginTop: "0px" }}>
                        <CustomCheckbox
                            headerText={"LLC"}
                            customHeaderStyle={{ fontSize: 14, fontWeight: 400 }}
                            isChecked={entityType === 'llc'}
                            onChangeHandler={() => setEntityType('llc')}
                        />
                    </div>
                    <div style={{ marginTop: "0px" }}>
                        <CustomCheckbox
                            headerText={"Other"}
                            customHeaderStyle={{ fontSize: 14, fontWeight: 400 }}
                            isChecked={entityType === 'other'}
                            onChangeHandler={() => setEntityType('other')}
                        />
                    </div>
                    <p className="bold-font" style={{ color: 'red', display: entityType === 'other' ? 'block' : 'none' }}>
                        Sorry, we can only accept corporations and LLCs at this time
                    </p>
                </div>

                <div className="account-creation-input-row">
                    <TextInput
                        headerMessage={"State of incorporation/formation"}
                        onTextChange={(event) => handleFormationState(event.target.value)}
                        placeholder="DE"
                        inputType={"text"}
                        value={formationState}
                    />
                </div>

            </div>
        </CreateAccountWrapper>
    )

}

export default CompanyInfo;
