import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import CreateAccountWrapper from '../../components/createAccount/CreateAccountWrapper';

import * as createAccountActions from '../../store/actions/createAccount';
import uploadIcon from '../../assets/icons/upload.png';

import '../../styles/accountCreationStyles.css';
import '../../styles/generalStyles.css';
import './PhotoIdUpload.css';

const DocumentUpload = (props) => {

    const [einDoc, setEinDoc] = useState('');
    const [aoi, setAoi] = useState('');
    const [einDocName, setEinDocName] = useState('EIN document');
    const [aoiDocName, setAoiDocName] = useState('Articles of Incorporation');

    const history = useHistory();
    const dispatch = useDispatch();

    const sendToNext = () => {

        dispatch(createAccountActions.setDocuments(einDoc, aoi))
        history.push('/sign-up/create-account')

    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const imageUploadHandler = async (event, einOrAoi) => {

        const file = event.target.files[0];
        const type = file.type;
        if (type != "image/jpeg" && type != "image/png" && type != "application/pdf") {
            alert("Only jpeg, png and pdf files are accepted")
            return;
        }

        const base64 = await convertBase64(file);

        if (einOrAoi == 'einDoc') {
            setEinDoc(base64)
            setEinDocName(file.name)
        } else {
            setAoi(base64)
            setAoiDocName(file.name)
        }
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                sendToNext()
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [aoi, einDoc]);

    return (

        <CreateAccountWrapper
            buttonDisabled={false}
            onButtonClick={() => sendToNext()}
            onSecondaryTextClick={() => history.goBack()}>
            <div className="section-container">
                <header className="bold-font account-creation-header">
                    Document upload
                </header>
                <p className="light-font sub-header">
                    Please upload the following documents. If you don’t have these easily available right now,
                    you’re welcome to continue for now and upload them later.
                </p>

                <div className="id-upload-row">
                    <div className="id-upload-icon-box">
                        <form className="box" method="post" action="" encType="multipart/form-data">
                            <div className="box__input">
                                <input type="file" name="file" id="frontID" hidden onChange={(event) => imageUploadHandler(event, 'einDoc')} accept=".pdf, .jpg, .png" />
                            </div>
                            <label htmlFor="frontID">
                                <img className="upload-icon" src={uploadIcon} />
                            </label>
                        </form>
                    </div>
                    <div className="id-upload-text-box">
                        <p className="regular-font">{einDocName}</p>
                    </div>
                </div>

                <div className="id-upload-row">
                    <div className="id-upload-icon-box">
                        <form className="box" method="post" action="" encType="multipart/form-data">
                            <div className="box__input">
                                <input type="file" name="file" id="backID" hidden onChange={(event) => imageUploadHandler(event, 'aoi')} accept=".pdf, .jpg, .png" />
                            </div>
                            <label htmlFor="backID">
                                <img className="upload-icon" src={uploadIcon} />
                            </label>
                        </form>
                    </div>
                    <div className="id-upload-text-box">
                        <p className="regular-font">{aoiDocName}</p>
                    </div>
                </div>

            </div>
        </CreateAccountWrapper>

    )

}

export default DocumentUpload;
