import './WorkAlongside.css';


const WorkAlongside = (props) => {

    return (
        <div className="work-alongside-section">
            <div className="work-alongside-container">
                <div className="work-alongside-title-col">
                    <h2 className="work-alongside-title">
                        Designed to work alongside your existing bank account and corporate card provider like Brex or Ramp
                    </h2>
                    <p className="work-alongside-sub-text">
                        Switching primary bank accounts is a pain, so we won’t ask you to do that.
                        Just move your excess cash to Levo Treasury, and then set up automatic withdrawals
                        from Levo to your existing bank account.
                    </p>

                </div>

            </div>
        </div>
    )

}

export default WorkAlongside;