
import React from "react";

import DashboardWrapper from '../../../components/general/DashboardWrapper';

import TermsOfServicePDF from '../../../assets/pdfs/termsOfService.pdf';
import PrivacyPDF from '../../../assets/pdfs/privacyPolicy.pdf';
import InvestmentAdvisoryAgreement from '../../../assets/pdfs/LevoInvestmentAdvisoryAgreementv5.pdf';
import EmbedPDF from '../../../assets/pdfs/EmbedClearing LLC_CustomerAccountAgreement.pdf';
import FormCRS from '../../../assets/pdfs/LevoAdviserFormCRS.pdf';
import FormADV from '../../../assets/pdfs/LevoFormADV.pdf';

import './Legal.css';

const Legal = () => {

    return (
        <DashboardWrapper screen="account">
            <div className="home-container">
                <h2 className="main-header">Legal documents</h2>

                <div className="legal-doc-row">
                    <a href={TermsOfServicePDF} target="_blank" className="semi-bold-font legal-doc-link">
                        Terms of Service
                    </a>
                </div>
                <div className="legal-doc-row">
                    <a href={PrivacyPDF} target="_blank" className="semi-bold-font legal-doc-link">
                        Privacy Policy
                    </a>
                </div>
                <div className="legal-doc-row">
                    <a href={InvestmentAdvisoryAgreement} target="_blank" className="semi-bold-font legal-doc-link">
                        Investment Advisory Agreement
                    </a>
                </div>
                <div className="legal-doc-row">
                    <a href={FormADV} target="_blank" className="semi-bold-font legal-doc-link">
                        Form ADV Part 2A
                    </a>
                </div>
                <div className="legal-doc-row">
                    <a href={FormCRS} target="_blank" className="semi-bold-font legal-doc-link">
                        Form CRS
                    </a>
                </div>
                <div className="legal-doc-row">
                    <a href={EmbedPDF} target="_blank" className="semi-bold-font legal-doc-link">
                        Embed Customer Agreement
                    </a>
                </div>
            </div>
        </DashboardWrapper>
    )

}

export default Legal;