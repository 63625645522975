
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import DashboardWrapper from '../../../components/general/DashboardWrapper';
import DepositCard from "../../../components/transfers/DepositCard";
import TransferReviewCard from "../../../components/transfers/TransferReviewCard";
import LoadingSpinner from "../../../components/general/LoadingSpinner";
import Modal from '../../../components/general/Modal';

import { useDepositHandler } from '../../../logic/dashboard/transfers/deposits';
import { useTransferPageHandler } from '../../../logic/dashboard/transfers/transfers';
import { handleDollarValueInput } from "../../../utils/generalFunctions";

import './Deposits.css';

const Deposits = () => {

    const history = useHistory();

    const [amount, setAmount] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [depositOrReview, setDepositOrReview] = useState('deposit');
    const [selectedAccount, setSelectedAccount] = useState(null);

    const {
        accountBalance, sendDepositRequest, activityIndicator, successModal, setSuccessModal, errorModal, setErrorModal
    } = useDepositHandler();
    const { sources } = useTransferPageHandler();

    const handleAmount = (event) => {
        const newValue = handleDollarValueInput(amount, event.target.value);
        setAmount(newValue);
    }

    useEffect(() => {
        if (amount.length > 0 && selectedAccount !== null) {
            setButtonDisabled(false);
        }
    }, [amount, selectedAccount])

    useEffect(() => {
        if (sources.length > 0) {
            setSelectedAccount(sources[0].id)
        }
    }, [sources])

    return (

        <DashboardWrapper screen="transfers">
            <div className="deposit-page-container">

                <div className="deposit-header">
                    <div className="deposit-header-col">
                        {depositOrReview == 'review' &&
                            <p
                                onClick={() => setDepositOrReview('deposit')}
                                className="semi-bold-font deposit-withdrawal-edit-button">
                                Edit
                            </p>}
                        {depositOrReview == 'deposit' &&
                            <p
                                onClick={() => history.goBack()}
                                className="semi-bold-font deposit-withdrawal-edit-button">
                                Back
                            </p>}
                    </div>
                    <div className="deposit-header-main-col">
                        <h2 className="main-header">
                            {depositOrReview == 'deposit' ? "Make a deposit" : "Review deposit"}
                        </h2>
                    </div>
                    <div className="deposit-header-col">

                    </div>
                </div>

                {activityIndicator && <LoadingSpinner />}

                {depositOrReview == 'deposit' && <DepositCard
                    accountBalance={accountBalance}
                    sources={sources}
                    setDepositOrReview={setDepositOrReview}
                    amount={amount}
                    handleAmount={handleAmount}
                    buttonDisabled={buttonDisabled}
                    onAccountSelection={(id) => setSelectedAccount(id)}
                />}

                {successModal && <Modal closeModal={() => setSuccessModal(false)}>
                    <div className="linking-account-modal-content">
                        <h2 className="semi-bold-font account-linking-header">Success</h2>
                        <p className="regular-font account-linking-text">
                            Your deposit has been successfully processed.
                        </p>
                    </div>
                </Modal>}

                {errorModal && <Modal closeModal={() => setSuccessModal(false)}>
                    <div className="linking-account-modal-content">
                        <h2 className="semi-bold-font account-linking-header">Error</h2>
                        <p className="regular-font account-linking-text">
                            There was an error processing your deposit. Please try again later or contact support.
                        </p>
                    </div>
                </Modal>}

                {depositOrReview == 'review' && <TransferReviewCard
                    toAccountName={"Levo"}
                    toAccountType={"Treasury"}
                    toAccountNumber={undefined}
                    toAccountBalance={accountBalance}
                    fromAccountName={sources.find(source => source.id == selectedAccount).bankName}
                    fromAccountType={sources.find(source => source.id == selectedAccount).accountType}
                    fromAccountNumber={sources.find(source => source.id == selectedAccount).accountNumber}
                    fromAccountBalance={sources.find(source => source.id == selectedAccount).balance}
                    amount={amount}
                    onSubmitClick={() => sendDepositRequest(amount, selectedAccount)}
                />}

            </div>
        </DashboardWrapper>

    )

}

export default Deposits;
