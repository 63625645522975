const { REACT_APP_SERVICE_BASE_URL } = process.env;


export async function getYields() {

    const response = await fetch(
        `${REACT_APP_SERVICE_BASE_URL}yields`,
        {
            method: 'GET',
            headers: {
                "LevoAuthToken": "dummy",
            }
        }
    )

    const resData = await response.json();
    return [resData, response.status];

}
