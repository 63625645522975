// Wrapper component for the account creation process
// This will handle the logo, step number, and child component positioning

import { useHistory } from 'react-router-dom';

import CustomButton from '../general/CustomButton';
import logo from '../../assets/logos/levo-logo-white.png';

import '../../styles/generalStyles.css';
import './CreateAccountWrapper.css';


const CreateAccountWrapper = (props) => {

    const history = useHistory();

    function handleLogoClick() {
        // If the user is on the get-started page, clicking the logo should send them to the homepage
        if (props.page !== undefined && props.page === "get-started") {
            history.push('/');
            return;
        }

    }

    return (
        <div className="main-container">
            <div
                className={props.page !== undefined && props.page === "get-started" ? "logo-container logo-container-clickable" : "logo-container"}
                onClick={() => handleLogoClick()}
            >
                <img src={logo} className="logo" alt="logo" />
            </div>
            <div className="main-content">
                {props.children}
                <div className="button-container">
                    <CustomButton
                        buttonText={props.buttonText === undefined ? "Continue" : props.buttonText}
                        onClick={props.onButtonClick}
                        disabled={props.buttonDisabled}
                    />
                    <div className="back-button-container">
                        <button
                            className={props.hideBackButton ? "back-button-hidden" : "back-button"}
                            onClick={props.onSecondaryTextClick}>
                            {props.backButtonText == undefined ? "Back" : props.backButtonText}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateAccountWrapper;
