import React, { useState, useEffect } from 'react';

import TextInput from '../general/TextInput';
import Modal from '../general/Modal';
import LoadingSpinner from '../general/LoadingSpinner';

import params from '../../config/params';
import { sendRequestAccessInfo } from '../../logic/marketing/requestAccess';

import './RequestDemo.css';

const RequestDemo = (props) => {

    const [firstName, setFirstName] = useState(props.firstName === undefined ? '' : props.firstName);
    const [companyName, setCompanyName] = useState(props.companyName === undefined ? '' : props.companyName);
    const [emailAddress, setEmailAddress] = useState(props.emailAddress === undefined ? '' : props.emailAddress);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [activityIndicator, setActivityIndicator] = useState(false);
    const [buttonActive, setButtonActive] = useState(false);

    const sendRequestDemo = async () => {
        // Make sure the button is active before sending the info
        if (buttonActive) {
            // Send the data to the backend
            setActivityIndicator(true)
            setButtonActive(false)
            const [resData, status] = await sendRequestAccessInfo(firstName, companyName, emailAddress);
            setActivityIndicator(false)
            setButtonActive(true)
            if (status === 200) {
                setShowSuccessMessage(true)
            } else {
                alert("There was an error sending your request. Please try again later.")
            }
        }
    }

    useEffect(() => {
        const emailIsValid = params.emailRegex.test(emailAddress);
        if (firstName.length > 1 && companyName.length > 1 && emailIsValid) {
            setButtonActive(true);
        } else {
            setButtonActive(false);
        }
    }, [firstName, companyName, emailAddress])

    return (

        <Modal closeModal={() => props.closeModal()}>

            {showSuccessMessage &&
                <div className="request-demo-container">
                    <div className="request-demo-header">
                        <p className="semi-bold-font request-demo-header-text">Request demo</p>
                    </div>
                    <div style={{textAlign: "center"}}>
                        <p className="regular-font">
                            Thank you for your interest! We will contact you shortly to schedule a time.
                        </p>
                    </div>
                </div>
            }

            {activityIndicator && <LoadingSpinner />}

            {!showSuccessMessage &&
                <div className="request-demo-container">

                    <div className="request-demo-header">
                        <p className="semi-bold-font request-demo-header-text">Request demo</p>
                    </div>

                    <div className="footer-request-access-input-section">
                        <div className="first-name-request-access-container">
                            <TextInput
                                type={"text"}
                                value={firstName}
                                onTextChange={(event) => setFirstName(event.target.value)}
                                placeholder={"First name"}
                                fontColor={'dark'}
                                customStyle={{ borderWidth: "2px", paddingTop: "14px", paddingBottom: "14px" }}
                            />
                        </div>
                        <div className="company-name-request-access-container">
                            <TextInput
                                type={"text"}
                                value={companyName}
                                onTextChange={(event) => setCompanyName(event.target.value)}
                                placeholder={"Company name"}
                                fontColor={'dark'}
                                customStyle={{ borderWidth: "2px", paddingTop: "14px", paddingBottom: "14px" }}
                            />
                        </div>
                    </div>

                    <div className="input-section">
                        <TextInput
                            type={"text"}
                            value={emailAddress}
                            onTextChange={(event) => setEmailAddress(event.target.value)}
                            placeholder={"Email address"}
                            fontColor={'dark'}
                            customStyle={{ borderWidth: "2px", paddingTop: "14px", paddingBottom: "14px" }}
                        />
                    </div>
                    <div>
                        <button onClick={() => sendRequestDemo()}
                            className={buttonActive ? "request-demo-button" : "request-demo-button request-demo-button-inactive"}>
                            Request Demo
                        </button>
                    </div>
                </div>
            }

        </Modal >
    )

}

export default RequestDemo;