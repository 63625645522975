
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeDBRequest } from '../../../services/data';
import { makeDepositRequest } from '../../../services/transfers';
import { formatBalance } from '../../../utils/generalFunctions';
import * as transferActions from '../../../store/actions/transfers';
import { formatDeposit, sortTransfers } from './transfers';


export function formatAmount(amount) {
    // Amount comes in as $250,000
    // We want to return 250000
    let newAmount = amount;
    newAmount = newAmount.replace('$', '');
    newAmount = newAmount.replace(',', '');
    newAmount = parseFloat(newAmount);
    return newAmount;
}

export function createStartDate() {
    // We want to return a date in the format of YYYYMMDD
    let date = new Date();
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    month = (month.length == 1) ? '0' + month : month;
    let day = date.getDate().toString();
    day = (day.length == 1) ? '0' + day : day;
    let startDate = year.toString() + month + day;
    return startDate;
}


export function findNewDeposit(depositRequests, date, amount, fromAccountID) {

    console.log(amount)
    console.log(date)
    console.log(fromAccountID)

    for (var i = 0; i<depositRequests.length; i++) {
        if (!depositRequests[i].DEPOSIT_is_active) {
            continue;
        }
        const depositAmount = (depositRequests[i].DEPOSIT_amount).toString();
        const depositDate = (depositRequests[i].DEPOSIT_start_date).toString();
        const depositAccountID = depositRequests[i].DEPOSIT_from_node_id;

        if (depositAccountID == fromAccountID && depositDate == date && depositAmount == amount.toString()) {
            const formattedDeposit = formatDeposit(depositRequests[i])
            return formattedDeposit;
        }
    }

    return null;

}

export async function retrieveDepositRequest(date, amount, fromAccountID, userID, sessionToken) {

    // Get the deposit requests
    const depositPayload = {
        'sk': 'DEPOSIT',
        'limit': 10
    }
    const depositRequests = await makeDBRequest(userID, sessionToken, depositPayload);

    // Find the new deposit
    const newDeposit = findNewDeposit(depositRequests, date, amount, fromAccountID)
    console.log(newDeposit)
    return newDeposit;

}

export function useDepositHandler() {

    const savedAccountBalance = useSelector(state => state.home.accountBalance);
    const userID = useSelector(state => state.auth.userID);
    const sessionToken = useSelector(state => state.auth.sessionToken);
    let savedTransfers = useSelector(state => state.transfers.transfers);
    const [accountBalance, setAccountBalance] = useState('');
    const [activityIndicator, setActivityIndicator] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);

    const dispatch = useDispatch();

    async function sendDepositRequest(amount, fromAccountID) {
        setActivityIndicator(true);
        const formattedAmount = formatAmount(amount);
        const startDate = createStartDate();
        const [resData, validDeposit] = await makeDepositRequest(fromAccountID, formattedAmount, userID, sessionToken, startDate);
        setActivityIndicator(false);

        if (!validDeposit) {
            setErrorModal(true)
            return;
        }

        // Retrieve the deposit request now
        const newDeposit = await retrieveDepositRequest(startDate, formattedAmount, fromAccountID, userID, sessionToken)
        if (newDeposit != null) {
            savedTransfers.push(newDeposit)
            savedTransfers = sortTransfers(savedTransfers)
            sessionStorage.setItem("transfers", JSON.stringify(savedTransfers))
            dispatch(transferActions.setTransfers(savedTransfers))
        }

        // Show a modal saying the deposit has been successfully processed
        setSuccessModal(true)
    }

    useEffect(() => {
        if (savedAccountBalance != null) {
            const [dollarBalance, centsBalance] = formatBalance(savedAccountBalance)
            setAccountBalance(dollarBalance + '.' + centsBalance)
        }
    }, [savedAccountBalance])

    return {
        accountBalance, sendDepositRequest, activityIndicator, successModal, setSuccessModal, errorModal, setErrorModal
    }

}
