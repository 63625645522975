
import CardLayout from '../general/CardLayout';
import bankLogo from '../../assets/icons/bank.png';
import brexLogo from '../../assets/logos/Brex_logo.png';
import './LinkedAccountCard.css';

const LinkedAccountCard = (props) => {

    let bankIcon;
    if (props.source.bankLogo == 'brex') {
        bankIcon = brexLogo
    } else if (props.source.bankLogo == 'mercury') {
        bankIcon = bankLogo;
    } else {
        bankIcon = bankLogo;
    }

    return (
        <CardLayout customStyle={{width: "500px", borderRadius: "8px", marginBottom: "20px"}}>
            <div className="linked-account-card-container">
                <div className="linked-account-card-row">
                    <div className="linked-account-card-logo-col">
                        <img className="linked-account-card-bank-logo" src={bankIcon} />
                    </div>
                    <div className="linked-account-card-name-col">
                        <p className="bold-font linked-account-card-bank-name">
                            {props.source.bankName}
                        </p>
                        <p className="regular-font">
                            {props.source.accountType} - {props.source.accountNumber}
                        </p>
                    </div>
                </div>
            </div>
        </CardLayout>
    )

}


export default LinkedAccountCard;
