import { Switch, Route, Redirect } from 'react-router-dom';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Home from '../screens/dashboard/home/Home';
import Transfers from '../screens/dashboard/transfers/Transfers';
import LinkedAccounts from '../screens/dashboard/transfers/LinkedAccounts';
import Deposits from '../screens/dashboard/transfers/Deposits';
import Withdrawals from '../screens/dashboard/transfers/Withdrawals';
import Portfolio from '../screens/dashboard/portfolio/Portfolio';
import Account from '../screens/dashboard/account/Account';

import Statements from '../screens/dashboard/account/Statements';
import Legal from '../screens/dashboard/account/Legal';
import DocumentUpload from '../screens/dashboard/account/DocumentUpload';

function DashboardNavigator() {

    const savedSessionToken = useSelector(state => state.auth.sessionToken);
    const [sessionToken, setSessionToken] = useState(savedSessionToken == null ? '' : savedSessionToken);

    useEffect(() => {
        if (savedSessionToken == null) {
            const sessionTokenFromStorage = sessionStorage.getItem('sessionToken');
            console.log('sessionTokenFromStorage')
            console.log(sessionTokenFromStorage)
            setSessionToken(sessionTokenFromStorage);
        }
    }, [savedSessionToken])

    return (
        <Switch>
            {sessionToken == null && <Redirect to="/login" />}
            <Route path='/dashboard' exact>
                <Home />
            </Route>
            <Route path='/dashboard/portfolio'>
                <Portfolio />
            </Route>
            <Route path='/dashboard/transfers'>
                <Transfers />
            </Route>
            <Route path='/dashboard/account'>
                <Account />
            </Route>
            <Route path='/dashboard/legal'>
                <Legal />
            </Route>
            <Route path='/dashboard/document-upload'>
                <DocumentUpload />
            </Route>
            <Route path='/dashboard/statements'>
                <Statements />
            </Route>
            <Route path='/dashboard/linked-accounts'>
                <LinkedAccounts />
            </Route>
            <Route path='/dashboard/deposits'>
                <Deposits />
            </Route>
            <Route path='/dashboard/withdrawals'>
                <Withdrawals />
            </Route>
        </Switch>
    )

}

export default DashboardNavigator;
