import './BackedBy.css';

import ycLogo from '../../assets/logos/yc-logo-1@2x.png';


const BackedBy = (props) => {

    return (
        <div className="backed-by-section">
            <div className="backed-by-container">

                <div className="backed-by-row">

                    <div className="backed-by-col">
                        <p className="backed-by-header">Follow best practices</p>
                        <p className="backed-by-message">
                            Large corporations have teams of people that allocate their excess cash to
                            higher-yielding investments like US Treasuries, while managing risk and liquidity.
                            We make it easy for you to do it too.
                        </p>
                    </div>

                    <div className="backed-by-col">
                        <p className="backed-by-header">Keep your money safe</p>
                        <p className="backed-by-message">
                            FDIC insurance only goes so far. Beyond $250k, you need an alternative strategy. At
                            Levo, we’re experts in low-risk fixed income investing.
                        </p>
                    </div>

                    <div className="backed-by-col">
                        <p className="backed-by-header">Managed by an experienced team</p>
                        <p className="backed-by-message">
                            Levo is an SEC-registered investment advisor run by a team of former hedge fund managers.
                            As a fiduciary, we’re required to put your interests before ours.
                        </p>
                    </div>

                </div>

                <div className="backed-by-yc-section">
                    <div>
                        <p className="backed-by-text">Backed by</p>
                    </div>
                    <div>
                        <img className="yc-logo" src={ycLogo} />
                    </div>
                </div>

            </div>
        </div>
    )

}

export default BackedBy;
