import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import CreateAccountWrapper from '../../components/createAccount/CreateAccountWrapper';
import TextInput from '../../components/general/TextInput';

import { createUserID, getNextSignUpPage } from '../../utils/generalFunctions';
import { sendStartedSignUpInfo } from '../../logic/marketing/requestAccess';
import * as createAccountActions from '../../store/actions/createAccount';
import params from '../../config/params';

import './GetStarted.css';
import '../../styles/generalStyles.css';

const GetStarted = () => {

    const savedFirstName = useSelector(state => state.createAccount.authorizedPersonFirstName);
    const savedCompanyName = useSelector(state => state.createAccount.companyName);
    const savedEmail = useSelector(state => state.createAccount.email);

    const [firstName, setFirstName] = useState(savedFirstName === undefined ? '' : savedFirstName);
    const [companyName, setCompanyName] = useState(savedCompanyName === undefined ? '' : savedCompanyName);
    const [emailAddress, setEmailAddress] = useState(savedEmail === undefined ? '' : savedEmail);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const history = useHistory();
    const dispatch = useDispatch();

    const sendToNext = () => {
        const userID = createUserID()
        dispatch(createAccountActions.setLevoUserID(userID))
        sendStartedSignUpInfo(firstName, companyName, emailAddress)
        dispatch({ type: 'SET_FIRST_NAME', firstName: firstName })
        dispatch({ type: 'SET_COMPANY_NAME', companyName: companyName })
        dispatch({ type: 'SET_EMAIL_ADDRESS', emailAddress: emailAddress })
        const nextPage = getNextSignUpPage(window.location.pathname);
        history.push(nextPage)
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ your logic here
                if (!buttonDisabled) {
                    sendToNext()
                }
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [buttonDisabled, firstName, companyName, emailAddress]);

    useEffect(() => {
        const emailValid = params.emailRegex.test(emailAddress);
        if (emailValid && companyName.length > 1 && firstName.length > 1) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [firstName, companyName, emailAddress])

    return (

        <CreateAccountWrapper
            buttonDisabled={buttonDisabled}
            onButtonClick={() => sendToNext()}
            hideBackButton={true}
            page={'get-started'}>
            <div className="section-container">

                <header className="bold-font header">
                    Get started
                </header>

                <p className="light-font sub-header">
                    Welcome to Levo Treasury! Let's get started. This should only take about 10 minutes. 
                    We just need to get some information about your company and its owners.
                </p>

                <div className="account-creation-input-row">
                    <div className="get-started-first-name-container">
                        <TextInput
                            headerMessage={"First name"}
                            onTextChange={(event) => setFirstName(event.target.value)}
                            placeholder="John"
                            inputType={"text"}
                            value={firstName}
                        />
                    </div>
                    <div className="get-started-company-name-container">
                        <TextInput
                            headerMessage={"Company legal name"}
                            onTextChange={(event) => setCompanyName(event.target.value)}
                            placeholder={"Acme Inc."}
                            inputType={"text"}
                            value={companyName}
                        />
                    </div>
                </div>

                <div className="account-creation-input-row">
                    <TextInput
                        headerMessage={"Email address"}
                        onTextChange={(event) => setEmailAddress(event.target.value)}
                        value={emailAddress}
                        placeholder={"john@acme.inc"}
                    />
                </div>

            </div>
        </CreateAccountWrapper>

    )

}

export default GetStarted;
