
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as authActions from '../../../store/actions/auth';

export function useLogoutHandler() {

    const dispatch = useDispatch();
    const history = useHistory();

    function logout() {
        // Clear all of the session storage data
        dispatch(authActions.logout())
        history.replace('/login')
    }

    return { logout }

}
