const { REACT_APP_SERVICE_BASE_URL } = process.env;

export async function makeEmbedSignupRequest(embedDocs, levoUserID) {

    const payload = {
        embed_payload: embedDocs,
        levo_user_id: levoUserID
    }

    const response = await fetch(
        `${REACT_APP_SERVICE_BASE_URL}embed-signup?userid=${levoUserID}`,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        }
    )

    // Parse the response object
    if (response.status != 200) {
        const errorResData = await response.json();
        return [errorResData, false];
    } else {
        const resData = await response.json();
        return [resData, true];
    }

}


export async function getSources(userID, sessionToken) {

  const response = await fetch(
    `${REACT_APP_SERVICE_BASE_URL}embed-sources?userid=${userID}`,
    {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "LevoAuthToken": sessionToken
      },
    }
  )

  const resData = await response.json();
  return [resData, response.status];

}
