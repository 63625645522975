import { useState } from 'react';
import { useSelector } from 'react-redux';

import * as general from '../../utils/generalFunctions';
import { makeSignUpRequest } from '../../services/signup';
import { makeEmbedSignupRequest } from '../../services/embed';
import { makeLoginRequest } from '../../services/auth';
import { sendPortfolioSelection, sendDocuments, sendOnboardingInfo } from '../../services/data';
import { useLoginHandler } from '../dashboard/auth/login';


export function getRiskTolerance(financialGoals) {

    let riskTolerance;
    if (financialGoals == 'veryLowRisk') {
        riskTolerance = 'RISK_TOLERANCE_CONSERVATIVE';
    } else if (financialGoals == 'lowRisk') {
        riskTolerance = 'RISK_TOLERANCE_MODERATELY_CONSERVATIVE';
    } else if (financialGoals == 'moderateRisk') {
        riskTolerance = 'RISK_TOLERANCE_MODERATE';
    } else {
        riskTolerance = 'RISK_TOLERANCE_MODERATELY_AGGRESSIVE'
    }
    return riskTolerance;

}


export function createEmbedDocs(createAccountInfo, email, ipAddress, timestamp) {

    var phoneNumber = createAccountInfo.phoneNumber;
    phoneNumber = general.stringReplace(phoneNumber, '-', '')

    let formattedStreet = createAccountInfo.street;
    formattedStreet = (createAccountInfo.unit == '' ? formattedStreet : formattedStreet + ' ' + createAccountInfo.unit);

    let embedDocs = {};
    embedDocs.correspondentId = "#CORRESPONDENT_ID"
    embedDocs.details = {
        effects: [
            {
                setAccountLimits: {
                    marginType: "MARGIN_TYPE_LPM",
                    credit: {
                        tradeUnsettledCash: true
                    },
                    orders: {
                        usEquityLimit: {
                            allowClosing: true,
                            allowOpening: true,
                            fractions: true
                        }
                    },
                    funding: {
                        achLimit: {
                            pullDeposit: true,
                            pushWithdraw: true,
                            sameDay: true
                        },
                        realTimePaymentLimit: {
                            rtpDeposit: false,
                            rtpWithdraw: false
                        },
                        wireLimit: {
                            wireDeposit: true,
                            wireWithdraw: true
                        }
                    },
                    billing: {
                        billable: true
                    }
                }
            }
        ]
    }

    const riskTolerance = getRiskTolerance(createAccountInfo.riskLevel);

    const investmentProfile = {
        liquidityNeeds: "LIQUIDITY_NEEDS_VERY_IMPORTANT",
        riskTolerance: riskTolerance,
        investmentObjectives: "INVESTMENT_OBJECTIVES_UNSPECIFIED",
    }

    const authorizedPerson = {
        firstName: createAccountInfo.authorizedPersonFirstName,
        lastName: createAccountInfo.authorizedPersonLastName,
        email: email,
        phones: [
            {
                type: "PHONE_TYPE_MOBILE",
                number: phoneNumber
            }
        ]
    }

    const physicalAddress = {
        line1: createAccountInfo.street,
        line2: createAccountInfo.unit,
        city: createAccountInfo.city,
        state: createAccountInfo.state,
        zip: createAccountInfo.zip
    }

    const beneficialOwners = createAccountInfo.beneficialOwners;
    const keys = Object.keys(beneficialOwners)
    let formattedBeneficialOwners = []
    for (var i = 0; i < keys.length; i++) {
        if (!beneficialOwners[keys[i]].isComplete) {
            continue;
        }
        var dob = beneficialOwners[i].dob.split('/');
        dob = `${dob[2]}-${dob[0]}-${dob[1]}`;

        var phone = beneficialOwners[i].phone;
        phone = general.stringReplace(phone, '-', '')

        formattedBeneficialOwners.push({
            percentOwned: beneficialOwners[i].ownership,
            person: {
                firstName: beneficialOwners[i].firstName,
                lastName: beneficialOwners[i].lastName,
                email: beneficialOwners[i].email,
                phones: [
                    {
                        type: "PHONE_TYPE_MOBILE",
                        number: phone
                    }
                ],
                dateOfBirth: dob,
                taxId: {
                    countryOfCitizenship: 'US',
                    ssn: beneficialOwners[i].ssn,
                    stateOfResidence: beneficialOwners[i].state,
                },
                physicalAddress: {
                    line1: beneficialOwners[i].street,
                    line2: beneficialOwners[i].unit,
                    city: beneficialOwners[i].city,
                    state: beneficialOwners[i].state,
                    zip: beneficialOwners[i].zip
                }
            },
            title: beneficialOwners[i].jobTitle
        })
    }

    const organization = {
        authorizedPerson: authorizedPerson,
        beneficialOwners: formattedBeneficialOwners,
        email: email,
        name: createAccountInfo.companyName,
        phone: phoneNumber,
        physicalAddress: physicalAddress,
        postalAddress: physicalAddress,
        taxId: {
            ein: createAccountInfo.ein
        },
        type: "ORG_TYPE_C_CORPORATION"
    }

    const w9Withholding = {
        subjectToBackupWithholding: false,
        electronicFingerprint: {
            browserFingerprint: {
                created: timestamp,
                ipAddress: ipAddress,
            }
        }
    }

    embedDocs.versionDetails = {
        effects: [
            {
                correspondentReview: {
                    accountVersionId: "80c80369-80e9-496e-8da6-6f56a0c265fa",
                    outcome: "REVIEW_OUTCOME_APPROVE",
                    approverId: "#APPROVER_ID"
                }
            }
        ],
        registration: {
            organization: {
                investmentProfile: investmentProfile,
                organization: organization,
                w9Withholding: w9Withholding,
            }
        }
    }

    return embedDocs;

}


export function createRiskQuestionnairePayload(createAccountInfo, recommendedPortfolio, userID) {

    const symbols = Object.keys(recommendedPortfolio);
    let portfolio = {}
    for (var i = 0; i < symbols.length; i++) {
        portfolio[symbols[i]] = recommendedPortfolio[symbols[i]].toString()
    }

    const riskQuestionnaireVersion = '2';
    const riskQuestionnaireResults = {
        targetVersion: riskQuestionnaireVersion,
        riskLevel: createAccountInfo.riskLevel,
        runway: createAccountInfo.runway,
        cashBalance: createAccountInfo.cashBalance,
    }

    const payload = {
        allocation: portfolio,
        user_id: userID,
        risk_questionnaire_results: riskQuestionnaireResults
    }

    return payload;

}


export const useAccountSubmission = (history) => {

    const [activityAnimating, setActivityAnimating] = useState(false);

    // Get all of the params from the createAccount redux store
    const createAccountInfo = useSelector(state => state.createAccount);

    const { performLogin } = useLoginHandler();

    const createAccount = async (email, password) => {

        // Get the levo user id
        const levoUserID = createAccountInfo.levoUserID;
        console.log("levoUserID", levoUserID)

        // Start the loading indicator
        setActivityAnimating(true);

        const ipAddress = await general.getIP();

        const timestamp = new Date(new Date().toUTCString());
        const embedDocs = createEmbedDocs(createAccountInfo, email, ipAddress, timestamp);
        console.log("embedDocs")
        console.log(embedDocs)

        const [embedResponse, embedSuccess] = await makeEmbedSignupRequest(
            embedDocs, levoUserID
        )

        const embedAccountID = embedResponse.account.id;

        if (!embedSuccess) {
            setActivityAnimating(false);
            alert(
                "Sorry, we couldn't process your request at this time",
            );
            return;
        }

        const riskPayload = createRiskQuestionnairePayload(createAccountInfo, createAccountInfo.portfolio, levoUserID);

        // Make the sign up request for our backend once the Synapse account has been created
        const [resData, status] = await makeSignUpRequest(
            email, password, levoUserID, embedAccountID
        );

        if (status != 200) {
            setActivityAnimating(false);
            alert(
                "Sorry, we couldn't process your request at this time"
            );
            return;
        }

        const [loginResData, loginStatus] = await makeLoginRequest(email, password);
        const loginUserID = loginResData.user_id;
        const sessionToken = loginResData.session_token;

        if (loginStatus == 200) {

            const [portfolioSelectionResponse, portfolioSelectionStatus] = await sendPortfolioSelection(
                loginUserID, sessionToken, riskPayload
            )

            createAccountInfo.email = email;
            // Send the full account creation payload to the backend
            const [onboardingResponse, onboardingStatus] = await sendOnboardingInfo(
                loginUserID, sessionToken, createAccountInfo
            );

            if (createAccountInfo.aoiDoc != '') {
                const [aoiResponse, aoiStatus] = await sendDocuments(
                    loginUserID, sessionToken, createAccountInfo.aoiDoc, 'aoi'
                )
            }
            if (createAccountInfo.einDoc != '') {
                const [einResponse, einStatus] = await sendDocuments(
                    loginUserID, sessionToken, createAccountInfo.einDoc, 'ein_doc'
                )
            }

            if (createAccountInfo.beneficialOwners[0].base64 != undefined && createAccountInfo.beneficialOwners[0].base64 != '') {
                const [photoIDResponse, photoIDStatus] = await sendDocuments(
                    loginUserID, sessionToken, createAccountInfo.beneficialOwners[0].base64, 'photo_id'
                )
            }

            // Log the user in and navigate to the home page
            setTimeout(function () {
                performLogin(email, password)
            }, 1000)


        } else {
            setActivityAnimating(false);
            history.push('/sign-up/account-creation-confirmation')
        }

    }

    return { createAccount, activityAnimating };

}
