import { IconContext } from "react-icons";
import { BsArrowUpRight } from "react-icons/bs";
import { BsArrowDownRight } from "react-icons/bs";

import CardLayout from '../../components/general/CardLayout';
import BalanceDisplay from '../../components/general/BalanceDisplay';

import './AccountOverviewCard.css';

const AccountOverviewCard = (props) => {

    return (

        <CardLayout customStyle={{borderRadius: "5px"}}>

            <div className="account-overview-container">

                <div className="account-overview-detail" style={{borderRight: "1px solid #E8E8E8", flex: 4}}>
                    <div className="account-overview-detail-row">
                        <h2 className="account-overview-title">Account balance</h2>
                    </div>
                    <div className="account-overview-detail-row-lower">
                        <BalanceDisplay
                            dollarBalance={props.dollarBalance}
                            centsBalance={props.centsBalance}
                            customLargeTextStyle={{fontSize: "36px", fontWeight: 700}}
                            customSmallTextStyle={{fontSize: "19px", fontWeight: 700, marginTop: "4px"}}
                            />
                    </div>
                </div>


                <div className="account-overview-detail" style={{borderRight: "1px solid #E8E8E8", flex: 5}}>
                    <div className="account-overview-detail-row">
                        <h2 className="account-overview-title">Current portfolio yield</h2>
                        <p className="light-font" style={{marginTop: "5px", color: "#8F8F8F"}}>
                            As of {props.yieldUpdateDate}, net of fees
                        </p>
                    </div>
                    <div className="account-overview-detail-row-lower">
                        <div style={{display: "flex", flex: 4, flexDirection: "row", alignItems: "flex-end"}}>
                            <BalanceDisplay
                                dollarBalance={props.dollarYieldDollars}
                                centsBalance={props.dollarYieldCents}
                                />
                            <p className="regular-font" style={{marginBottom: "1px", marginLeft: "2px"}}> / year</p>
                        </div>
                        <div style={{flex: 3}}>
                            <p className="regular-font" style={{fontSize: "20px", marginBottom: "0px"}}>
                                {props.portfolioYield}%
                            </p>
                        </div>

                    </div>
                </div>

                <div className="account-overview-detail" style={{flex: 5}}>
                    <div className="account-overview-detail-row">
                        <h2 className="account-overview-title">
                            Lifetime return
                        </h2>
                        <p className="light-font" style={{marginTop: "5px", color: "#8F8F8F"}}>
                            {props.ytdDateRange}
                        </p>
                    </div>
                    <div className="account-overview-detail-row-lower">
                        <div className="account-overview-return-col">
                            <BalanceDisplay
                                dollarBalance={props.dollarReturn}
                                centsBalance={props.centsReturn}
                            />
                        </div>
                        <div className="account-overview-return-col">
                            <IconContext.Provider
                                value={{
                                    size: "20px",
                                    color: ("#8F8F8F"),
                                    className: "return-icon"
                                }}>
                                <div>
                                    {parseFloat(props.percentReturn) >= 0 ? <BsArrowUpRight /> : <BsArrowDownRight />}
                                </div>
                            </IconContext.Provider>
                            <p className="regular-font account-overview-percent-return">
                                {props.percentReturn}%
                            </p>
                        </div>
                    </div>
                </div>

            </div>

        </CardLayout>

    )

}

export default AccountOverviewCard;
