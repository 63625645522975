const { REACT_APP_SERVICE_BASE_URL } = process.env;

export async function makeDBRequest(userID, sessionToken, payload) {

  // Generic database request function that takes in a payload

  const response = await fetch(
    `${REACT_APP_SERVICE_BASE_URL}user-data?userid=${userID}`,
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "LevoAuthToken": sessionToken
      },
      body: JSON.stringify(payload),
    }
  )

  const resData = await response.json();
  return resData;

}

export async function getCompanyDocuments(userID, sessionToken) {

  // Get all of the company documents for the user using /documents endpoint

  const response = await fetch(
    `${REACT_APP_SERVICE_BASE_URL}documents?userid=${userID}`,
    {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "LevoAuthToken": sessionToken
      },
    }
  )

  const resData = await response.json();
  return resData;

}


export async function getMonthlyStatements(userID, sessionToken) {

  // Get all of the company documents for the user using /documents endpoint

  console.log(userID, sessionToken)

  const response = await fetch(
    `${REACT_APP_SERVICE_BASE_URL}statements?userid=${userID}`,
    {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "LevoAuthToken": sessionToken
      },
    }
  )

  const resData = await response.json();
  return resData;

}


export async function sendPortfolioSelection(userID, sessionToken, payload) {

  const response = await fetch(
    `${REACT_APP_SERVICE_BASE_URL}portfolio-selection?userid=${userID}`,
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "LevoAuthToken": sessionToken,
      },
      body: JSON.stringify(payload),
    }
  )

  const resData = await response.json();
  return [resData, response.status];

}


export async function sendOnboardingInfo(userID, sessionToken, onboardingInfo) {

  const response = await fetch(
    `${REACT_APP_SERVICE_BASE_URL}onboarding-data?userid=${userID}`,
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "LevoAuthToken": sessionToken,
      },
      body: JSON.stringify(onboardingInfo),
    }
  )

  const resData = await response.json();
  return [resData, response.status];

}


export async function sendDocuments(userID, sessionToken, base64, documentType) {

  let dataType;
  var data = base64.split(',')
  data = data[0].split(';')
  dataType = data[0].split('/')[1]

  const response = await fetch(
    `${REACT_APP_SERVICE_BASE_URL}document-upload?userid=${userID}`,
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "LevoAuthToken": sessionToken,
      },
      body: JSON.stringify({ content: base64, document_type: `${documentType}.${dataType}` }),
    }
  )

  const resData = await response.json();
  return [resData, response.status];

}


export async function getPortfolioDetails(userID, sessionToken) {

    const response = await fetch(
        `${REACT_APP_SERVICE_BASE_URL}portfolio?userid=${userID}`,
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "LevoAuthToken": sessionToken,
            }
        }
    )

    const resData = await response.json();
    console.log('resData', resData)
    return [resData, response.status];

}


export async function getCurrentYields() {

  const response = await fetch(
    `${REACT_APP_SERVICE_BASE_URL}yields`,
    {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
      }
    }
  )

  const resData = await response.json();
  return [resData, response.status];

}
