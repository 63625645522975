import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CreateAccountWrapper from '../../components/createAccount/CreateAccountWrapper';
import BeneficialOwnerRow from '../../components/createAccount/BeneficialOwnerRow';

import { formatBeneficialOwners, useBeneficialOwnersHandler } from '../../logic/createAccount/beneficialOwners';
import { getNextSignUpPage } from '../../utils/generalFunctions';

import plusIcon from '../../assets/icons/plus.png';

import '../../styles/accountCreationStyles.css';
import '../../styles/generalStyles.css';
import './BeneficialOwners.css';

const BeneficialOwners = () => {

    const beneficialOwners = useSelector(state => state.createAccount.beneficialOwners);
    const [formattedBeneficialOwners, setFormattedBeneficialOwners] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const history = useHistory();

    const { authorizedPerson, isAuthorizedPersonAdded, addAuthorizedPersonAsBeneficialOwner } = useBeneficialOwnersHandler();

    const sendToNext = () => {
        const nextPage = getNextSignUpPage(window.location.pathname);
        history.push(nextPage)
    }

    useEffect(() => {
        if (beneficialOwners != undefined) {
            const owners = formatBeneficialOwners(beneficialOwners)
            setFormattedBeneficialOwners(owners)
        }
    }, [beneficialOwners])

    useEffect(() => {
        setButtonDisabled(formattedBeneficialOwners.length === 0)
    }, [formattedBeneficialOwners])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (!buttonDisabled) {
                    sendToNext()
                }
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [buttonDisabled, formattedBeneficialOwners]);

    return (
        <CreateAccountWrapper
            buttonDisabled={buttonDisabled}
            onButtonClick={() => sendToNext()}
            onSecondaryTextClick={() => history.goBack()}>

            <div className="benficial-owners-section">
                <h2 className="bold-font account-creation-header">Beneficial owners</h2>
                <p className="regular-font">
                    Please add all beneficial owners of your company below. A beneficial owner is any individual
                    (i.e. not another company or investment fund) who owns 25% or more of the company. If there is
                    no one who qualifies for this, add yourself.
                </p>
                <p className="regular-font" style={{ marginBottom: "35px" }}>
                    Each beneficial owner must have a US address. If no one in your company has a US address,
                    please contact us.
                </p>

                {formattedBeneficialOwners.map((beneficialOwner, index) =>
                    <BeneficialOwnerRow
                        beneficialOwner={beneficialOwner}
                        onEditClick={() => history.push(`/sign-up/beneficial-owner-person/${index}`)}
                        key={index} />
                )}

                {!isAuthorizedPersonAdded && <div>
                    <div className="beneficial-owner-add-row">
                        <button
                            className="beneficial-owner-add-button"
                            onClick={() => {
                                addAuthorizedPersonAsBeneficialOwner(authorizedPerson, formattedBeneficialOwners)
                            }}>
                            <img src={plusIcon} className="plus-icon" alt="+" />
                        </button>
                        <p className="regular-font">
                            Add myself ({authorizedPerson['firstName']} {authorizedPerson['lastName']}) as a beneficial owner
                        </p>
                    </div>
                </div>}

                <div className="beneficial-owner-add-row">
                    <button
                        className="beneficial-owner-add-button"
                        onClick={() => {
                            history.push(`/sign-up/beneficial-owner-person/${formattedBeneficialOwners.length}`)
                        }}>
                        <img src={plusIcon} className="plus-icon" alt="+" />
                    </button>
                    <p className="regular-font">Add a beneficial owner</p>
                </div>
            </div>

        </CreateAccountWrapper >
    )

}

export default BeneficialOwners;
