import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import CreateAccountWrapper from '../../components/createAccount/CreateAccountWrapper';
import TextInput from '../../components/general/TextInput';

import { calculateAge } from '../../utils/dateFormatting';
import * as createAccountActions from '../../store/actions/createAccount';
import { handlePhoneNumberInput, handleDOB, handleSSN } from '../../utils/generalFunctions';
import params from '../../config/params';

import '../../styles/accountCreationStyles.css';
import '../../styles/generalStyles.css';

const BeneficialOwnerPerson = () => {

    const { ownerNumber } = useParams();
    const index = ownerNumber; // index just makes more sense as a variable name
    const beneficialOwners = useSelector(state => state.createAccount.beneficialOwners);
    const editOrAdd = beneficialOwners[index] == undefined ? 'add' : 'edit';

    var savedJobTitle = beneficialOwners[index] === undefined ? '' : beneficialOwners[index].jobTitle;
    savedJobTitle = savedJobTitle === undefined ? '' : savedJobTitle;
    var savedOwnership = beneficialOwners[index] === undefined ? '' : beneficialOwners[index].ownership;
    savedOwnership = savedOwnership === undefined ? '' : savedOwnership;

    const [firstName, setFirstName] = useState(editOrAdd == 'add' ? '' : beneficialOwners[index].firstName);
    const [lastName, setLastName] = useState(editOrAdd == 'add' ? '' : beneficialOwners[index].lastName);
    const [dob, setDob] = useState(editOrAdd == 'add' ? '' : beneficialOwners[index].dob);
    const [ssn, setSsn] = useState(editOrAdd == 'add' ? '' : beneficialOwners[index].ssn);
    const [jobTitle, setJobTitle] = useState(editOrAdd == 'add' ? '' : savedJobTitle);
    const [ownership, setOwnership] = useState(editOrAdd == 'add' ? '' : savedOwnership);
    const [phone, setPhone] = useState(editOrAdd == 'add' ? '' : beneficialOwners[index].phone);
    const [email, setEmail] = useState(editOrAdd == 'add' ? '' : beneficialOwners[index].email);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const history = useHistory();
    const dispatch = useDispatch();

    const sendToNext = () => {

        var updatedBeneficialOwners = beneficialOwners;
        if (editOrAdd == 'add') {
            updatedBeneficialOwners[index] = {};
        }
        updatedBeneficialOwners[index].firstName = firstName;
        updatedBeneficialOwners[index].lastName = lastName;
        updatedBeneficialOwners[index].dob = dob;
        updatedBeneficialOwners[index].ssn = ssn;
        updatedBeneficialOwners[index].jobTitle = jobTitle;
        updatedBeneficialOwners[index].ownership = ownership;
        updatedBeneficialOwners[index].email = email;
        updatedBeneficialOwners[index].phone = phone;

        // Update the redux store with the updated beneficial owner
        dispatch(createAccountActions.setBeneficialOwners(updatedBeneficialOwners));

        history.push(`/sign-up/beneficial-owner-address/${ownerNumber}`)
    }

    const phoneNumberHandler = (event) => {
        const newText = handlePhoneNumberInput(phone, event.target.value);
        setPhone(newText);
    }

    const dobHandler = (event) => {
        const newText = handleDOB(dob, event.target.value);
        setDob(newText);
    }

    const ssnHandler = (event) => {
        const newText = handleSSN(ssn, event.target.value);
        setSsn(newText);
    }

    const handleOwnership = (event) => {
        const text = event.target.value;
        const validCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        if (ownership.length > text.length) {
            setOwnership(text)
        } else if (ownership.toString() == '0') {
            // do nothing because we can't have leading 0s
            // Also this has to be converted to a string for this comparison
        } else if (validCharacters.indexOf(text.slice(-1)) == -1 || text > 100) {
            // do nothing because it's an invalid character
        } else {
            setOwnership(text)
        }
    }

    useEffect(() => {
        // Check the user's age now
        let age = 0;
        if (dob.length == 10) {
            age = calculateAge(dob)
        }
        const isEmailValid = params.emailRegex.test(email.toLowerCase());
        if (firstName.length > 1 && lastName.length > 1 && phone.length === 12 && isEmailValid && ssn.length == 11
            && age >= 18 && jobTitle.length > 0) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [firstName, lastName, dob, ssn, jobTitle, email, phone, ownership])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (!buttonDisabled) {
                    sendToNext()
                }
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [buttonDisabled, firstName, lastName, dob, ssn, jobTitle, email, phone, ownership]);

    return (
        <CreateAccountWrapper
            buttonDisabled={buttonDisabled}
            onButtonClick={() => sendToNext()}
            onSecondaryTextClick={() => history.goBack()}>
            <div className="section-container">
                <header className="bold-font account-creation-header">
                    Beneficial Owner
                </header>
                <p className="light-font sub-header">
                    We're required to collect this information.
                </p>

                <div className="account-creation-input-row">
                    <div className="first-name-container">
                        <TextInput
                            value={firstName}
                            headerMessage={"Legal first name"}
                            onTextChange={(event) => setFirstName(event.target.value)}
                            placeholder="John"
                            inputType={"text"}
                        />
                    </div>
                    <div className="last-name-container">
                        <TextInput
                            value={lastName}
                            headerMessage={"Legal last name"}
                            onTextChange={(event) => setLastName(event.target.value)}
                            placeholder="Doe"
                            inputType={"text"}
                        />
                    </div>
                </div>

                <div className="account-creation-input-row">
                    <TextInput
                        value={dob}
                        headerMessage={"Date of birth"}
                        onTextChange={dobHandler}
                        placeholder={"01/20/1990"}
                        inputType={"text"}
                    />
                </div>

                <div className="account-creation-input-row">
                    <TextInput
                        value={ssn}
                        headerMessage={"SSN"}
                        onTextChange={ssnHandler}
                        placeholder="123-12-1234"
                        inputType={"text"}
                    />
                </div>

                <div className="account-creation-input-row">
                    <TextInput
                        value={jobTitle}
                        headerMessage={"Job title"}
                        onTextChange={(event) => setJobTitle(event.target.value)}
                        placeholder="CEO"
                        inputType={"text"}
                    />
                </div>

                <div className="account-creation-input-row">
                    <TextInput
                        value={ownership}
                        headerMessage={"Ownership %"}
                        onTextChange={handleOwnership}
                        placeholder="27"
                        inputType={"text"}
                    />
                </div>

                <div className="account-creation-input-row">
                    <TextInput
                        value={email}
                        headerMessage={"Email address"}
                        onTextChange={(event) => setEmail(event.target.value)}
                        placeholder="john@acme.inc"
                        inputType={"text"}
                    />
                </div>

                <div className="account-creation-input-row">
                    <TextInput
                        value={phone}
                        headerMessage={"Phone number"}
                        onTextChange={phoneNumberHandler}
                        placeholder="111-222-3333"
                        inputType={"text"}
                    />
                </div>

            </div>
        </CreateAccountWrapper>
    )

}

export default BeneficialOwnerPerson;
