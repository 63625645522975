
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { makeDBRequest, getCompanyDocuments, getMonthlyStatements, sendDocuments } from '../../../services/data';
import * as accountActions from '../../../store/actions/account';
import { monthNames } from '../../../utils/dateFormatting';


export function formatCompanyDocs(companyDocs) {

    // This will take the companyDocs object and turn it into an array
    let formattedCompanyDocs = [];
    const keys = Object.keys(companyDocs);
    for (let i = 0; i < keys.length; i++) {
        formattedCompanyDocs.push({
            key: keys[i],
            value: companyDocs[keys[i]]
        });
    }
    return formattedCompanyDocs;

}

export function useAccountHandler() {

    const userID = sessionStorage.getItem("userID")
    const sessionToken = sessionStorage.getItem("sessionToken")

    // Account info
    const savedAccountInfo = sessionStorage.getItem("accountInfo")
    const [accountInfo, setAccountInfo] = useState(savedAccountInfo === null ? {} : JSON.parse(savedAccountInfo))

    // company documents
    const savedCompanyDocs = sessionStorage.getItem('companyDocs')
    const [companyDocs, setCompanyDocs] = useState(savedCompanyDocs === null ? {} : JSON.parse(savedCompanyDocs))

    // monthly statements
    const savedMonthlyStatements = sessionStorage.getItem('monthlyStatements')
    const [monthlyStatements, setStatements] = useState(savedMonthlyStatements === null ? {} : JSON.parse(savedMonthlyStatements))

    const [activityIndicator, setActivityIndicator] = useState(true)

    const dispatch = useDispatch();

    async function getUserAccountInfo() {
        // Check if the account info is already in the session storage
        if (savedAccountInfo !== null) {
            dispatch(accountActions.setAccountInfo(JSON.parse(savedAccountInfo)))
            return;
        }
        const payload = { sk: 'BROKERAGE' }
        const companyAccountInfo = await makeDBRequest(userID, sessionToken, payload);
        setAccountInfo(companyAccountInfo)
        dispatch(accountActions.setAccountInfo(companyAccountInfo[0]))
        sessionStorage.setItem("accountInfo", JSON.stringify(companyAccountInfo[0]))
    }

    async function setCompanyDocuments() {
        // Check if the company docs are already in the session storage
        if (savedCompanyDocs !== null) {
            dispatch(accountActions.setCompanyDocs(JSON.parse(savedCompanyDocs)))
            return;
        }
        const allCompanyDocs = await getCompanyDocuments(userID, sessionToken);
        dispatch(accountActions.setCompanyDocs(allCompanyDocs))
        setCompanyDocs(allCompanyDocs)
        sessionStorage.setItem("companyDocs", JSON.stringify(allCompanyDocs))
    }

    async function setMonthlyStatements() {
        // Check if the statements are already in the session storage
        if (savedMonthlyStatements !== null) {
            dispatch(accountActions.setMonthlyStatements(JSON.parse(savedMonthlyStatements)))
            return;
        }
        const statements = await getMonthlyStatements(userID, sessionToken);
        dispatch(accountActions.setMonthlyStatements(statements))
        setStatements(statements)
        sessionStorage.setItem("monthlyStatements", JSON.stringify(statements))
    }

    useEffect(() => {
        // Request all of the necessary info for the dashboard, done async
        getUserAccountInfo();
        setCompanyDocuments();
        setMonthlyStatements();
    }, [])

    useEffect(() => {
        if (accountInfo !== null && companyDocs !== null && monthlyStatements !== null) {
            setActivityIndicator(false)
        }
    }, [accountInfo, companyDocs, monthlyStatements])

    return { accountInfo, companyDocs, activityIndicator }

}


export function useStatementHandler() {

    const savedStatements = useSelector(state => state.account.monthlyStatements);
    const [statements, setStatements] = useState([]);
    const [activityIndicator, setActivityIndicator] = useState(true);
    const [statementYears, setStatementYears] = useState([]);

    function formatMonthlyStatements(monthlyStatements) {
        let formattedStatements = [];
        const keys = Object.keys(monthlyStatements);
        // Get the unique years from the keys (keys are in the format YYYYMM)
        const years = [...new Set(keys.map(key => key.slice(0, 4)))];
        setStatementYears(years)

        // Create a yearly statements object for each year
        for (let i = 0; i < years.length; i++) {
            const year = years[i];
            const yearStatements = [];
            for (let j = 0; j < keys.length; j++) {
                const key = keys[j];
                // Get the month number and convert it to the full month name
                const month = keys[j].slice(4, 6);
                const monthName = monthNames[parseFloat(month) - 1];
                if (key.slice(0, 4) === year) {
                    yearStatements.push({
                        month: monthName,
                        value: monthlyStatements[key]
                    })
                }
            }
            formattedStatements.push({
                year: year,
                statements: yearStatements
            })
        }
        return formattedStatements;
    }

    useEffect(() => {
        if (savedStatements !== null && savedStatements !== undefined) {
            const formattedStatements = formatMonthlyStatements(savedStatements)
            setStatements(formattedStatements)
            setActivityIndicator(false)
        }
    }, [savedStatements])

    return { statements, statementYears, activityIndicator }

}


export function useDocumentHandler() {

    // We can get the company docs from the redux store because the previous page will have already
    // checked if they are in the session storage and set them in the store if they are
    const savedCompanyDocs = useSelector(state => state.account.companyDocs);
    // User ID and session token need to come from the session storage though
    const userID = sessionStorage.getItem("userID")
    const sessionToken = sessionStorage.getItem("sessionToken")

    const [companyDocs, setCompanyDocs] = useState(savedCompanyDocs);
    const [companyDocNames, setCompanyDocNames] = useState([]);
    const [activityIndicator, setActivityIndicator] = useState(false);

    const dispatch = useDispatch();

    function getDocNames(docs) {
        const docNames = [];
        for (let i = 0; i < docs.length; i++) {
            const docName = docs[i].key.split('.')[0];
            docNames.push(docName)
        }
        return docNames;
    }

    async function updateDocuments(newDocs) {

        setActivityIndicator(true)

        const docNames = Object.keys(newDocs);
        for (let i = 0; i < docNames.length; i++) {
            const document = newDocs[docNames[i]].base64;
            const [response, status] = await sendDocuments(userID, sessionToken, document, docNames[i])
        }
        
        // Request the updated documents
        const allCompanyDocs = await getCompanyDocuments(userID, sessionToken);
        setCompanyDocs(allCompanyDocs)
        dispatch(accountActions.setCompanyDocs(allCompanyDocs))
        sessionStorage.setItem("companyDocs", JSON.stringify(allCompanyDocs))

        setActivityIndicator(false)
    
    }

    useEffect(() => {
        if (companyDocs !== null && companyDocs !== undefined) {
            const formattedCompanyDocs = formatCompanyDocs(companyDocs)
            const docNames = getDocNames(formattedCompanyDocs)
            setCompanyDocNames(docNames)
        }
    }, [companyDocs])

    return { companyDocNames, updateDocuments, activityIndicator }
}
