import React from "react";
import { IconContext } from "react-icons";
import { AiFillCheckCircle } from "react-icons/ai";
import { GoPlus } from "react-icons/go";

import './DocumentUploadRow.css';

const DocumentUploadRow = (props) => {

    return (
        <div className="document-row">
            <div className="document-name-container">
                <p className="medium-font document-name">{props.documentName}</p>
            </div>
            {props.isUploaded && props.filename.length === 0 && <div className="document-image-container uploaded-document">
                <IconContext.Provider
                    value={{
                        size: "28px",
                        color: "#4BAE4F",
                        className: "side-nav-icon"
                    }}>
                    <div>
                        <AiFillCheckCircle />
                    </div>
                </IconContext.Provider>
            </div>}

            {!props.isUploaded && props.filename.length === 0 && <div className="document-image-container non-uploaded-document">
                <form className="box" method="post" action="" encType="multipart/form-data">
                    <div className="box__input">
                        <input type="file" name="file" id={props.docName} hidden
                            onChange={(event) => props.onChange(event)} accept=".pdf,.png,.jpg" />
                    </div>
                    <label className="document-upload-label" htmlFor={props.docName}>
                        <IconContext.Provider
                            value={{
                                size: "26px",
                                color: "#383838",
                                className: "side-nav-icon"
                            }}>
                            <div>
                                <GoPlus />
                            </div>
                        </IconContext.Provider>
                    </label>
                </form>
            </div>}

            {!props.isUploaded && props.filename.length > 0 && <div className="document-image-container uploaded-document">
                <form className="box" method="post" action="" encType="multipart/form-data">
                    <div className="box__input">
                        <input type="file" name="file" id={props.docName} hidden
                            onChange={(event) => props.onChange(event)} accept=".pdf,.png,.jpg" />
                    </div>
                    <label className="document-upload-label" htmlFor={props.docName}>
                        <p className="regular-font" style={{textOverflow: "ellipsis"}}>
                            {props.filename}
                        </p>
                    </label>
                </form>
            </div>}


        </div>
    )

}

export default DocumentUploadRow;
