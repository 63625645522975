// Get the investment performance for this user

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formatBalance } from '../../../utils/generalFunctions';
import {
  formatDateForDetails, getMonthFromTimestamp, formatDateForDateRange, convertEpochToYearMonthDay, formatLastUpdatedTime
} from '../../../utils/dateFormatting';
import { investmentInfo, assetDetails } from '../../../constants/portfolioInfo';
import { makeDBRequest } from '../../../services/data';
import * as portfolioActions from '../../../store/actions/portfolio';


export function calculateRMS(arr) {

  if (arr.length < 1) {
    return null;
  }

  const squares = arr.map((val) => (val*val));
  const sum = squares.reduce((acum, val) => (acum + val));
  const mean = sum/arr.length;
  return Math.sqrt(mean);

}


export function getTWR(allNAVs, allInvestments, startingNAV) {
  // allNAVs and allInvestments will be in descending chronological order
  allNAVs = allNAVs.reverse();
  allInvestments = allInvestments.reverse();

  let allReturns = [];
  let initialNAV = startingNAV;
  for (var i=0; i<allNAVs.length; i++) {
    initialNAV = (i == 0 ? startingNAV : allNAVs[i-1])
    if (allNAVs[i] < 0.01 || initialNAV < 0.01) {
      allReturns.push(1);
    } else {
      allReturns.push(1 + (allNAVs[i] - allInvestments[i] - initialNAV) / initialNAV);
    }
  }

  let twr = 1;
  for (var i=0; i<allReturns.length; i++) {
    twr = twr*allReturns[i];
  }
  twr = twr - 1;

  return twr;

}


export function getMWR(userNavs, allInvestments, startingNAV, currentNAV) {

  const reducer = (previousValue, currentValue) => previousValue + currentValue;

  const averageNAV = calculateRMS(userNavs);
  // startingNAV should always be 0, but it's not guaranteed
  const dollarReturn = (currentNAV - (allInvestments.reduce(reducer) + startingNAV));
  const mwr = averageNAV < 0.001 ? 0 : (dollarReturn / averageNAV); // Average NAV of 0 results in NAN
  return mwr;

}


export function getDollarReturn(allInvestments, startingNAV, currentNAV) {

    // Convenient way to sum an array
    const reducer = (previousValue, currentValue) => previousValue + currentValue;
    const dollarReturn = (currentNAV - (allInvestments.reduce(reducer) + startingNAV));
    return Math.round(dollarReturn*100)/100;

}


export function getReturns(userNAVs) {
  // Function to get the return of a user's portfolio, both in dollar and percent values

    if (userNAVs.length == 0) {
        return {
            cumulativeReturns: [],
            yearlyReturns: [],
            monthlyReturns: [],
            currentNAV: 0,
            currentNAVDate: '',
            currentDate: '',
            allNAVs: [],
            allInvestments: [],
            allNAVDates: [],
            updatedEpoch: '0'
        }
    }

    // Need to handle both V1, V2 and V3 users
    const balanceKey = 'BALANCE_balance';
    const investmentKey = 'BALANCE_net_investment';
    const updatedEpochKey = 'BALANCE_provisional_balance_update_epoch';

    // Ensure the NAVs are sorted in descending date order. The SK is BALANCE#YYYYMMDD or USER_NAV#YYYYMMDD
    userNAVs.sort((a, b) => (a.SK.split('#')[1] > b.SK.split('#')[1]) ? -1 : 1);

    const updatedEpoch = userNAVs[0][updatedEpochKey];

    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    month = ((month+1).toString().length == 1 ? '0' + (month+1).toString() : (month+1).toString());
    let date = currentDate.getDate();
    currentDate = `${year}${month}${date}`;

    const currentNAV = userNAVs[0][balanceKey];
    const currentNAVDate = userNAVs[0].SK.split('#')[1];
    const currentYear = userNAVs[0].SK.split('#')[1].slice(0,4);
    const currentMonth = userNAVs[0].SK.split('#')[1].slice(4,6);
    let startingNAVs = {cumulative: 0, yearly: 0, monthly: 0};
    let allUserNavs = {cumulative: [], yearly: [], monthly: []};
    let allInvestments = {cumulative: [], yearly: [], monthly: []};
    let allNAVDates = [];

    // Loop through all the user navs to get the total investment and the sum of the daily NAVs
    for (var i=0; i<userNAVs.length; i++) {
        date = userNAVs[i].SK.split('#')[1];
        year = date.slice(0,4);
        month = date.slice(4,6);
        const netInvestment = userNAVs[i][investmentKey] == undefined ? 0 : userNAVs[i][investmentKey];
        allNAVDates.push(date);

        if (userNAVs[i][balanceKey] == undefined || userNAVs[i][balanceKey] == null) {
            continue;
        }

        allUserNavs.cumulative.push(userNAVs[i][balanceKey]);
        allInvestments.cumulative.push(netInvestment);
        startingNAVs.cumulative = userNAVs[i][balanceKey];

        // Check the year against the current year before adding this date's info in
        if (year == currentYear) {
            allUserNavs.yearly.push(userNAVs[i][balanceKey]);
            allInvestments.yearly.push(netInvestment);
            startingNAVs.yearly = userNAVs[i][balanceKey];
        } else if ((i > 0) && (userNAVs[i-1].SK.split('#')[1].slice(0,4)) == currentYear) {
            // If this is the starting NAV, we need to include it in the yearly NAVs
            if (userNAVs[i].SK == 'BALANCE#00000000') {
                allUserNavs.yearly.push(userNAVs[i][balanceKey]);
                allInvestments.yearly.push(netInvestment);
            }
            // If the previous year (in the reverse chronological order NAV list) was equal to the current
            // year, set the yearly starting NAV. This will set the starting NAV to the final day of the previous year
            startingNAVs.yearly = userNAVs[i][balanceKey];
        }

        // Do the same for the month
        if (month == currentMonth && year == currentYear) {
            allUserNavs.monthly.push(userNAVs[i][balanceKey]);
            allInvestments.monthly.push(netInvestment);
            startingNAVs.monthly = userNAVs[i][balanceKey];
        } else if ((i > 0) && (userNAVs[i-1].SK.split('#')[1].slice(4,6)) == currentMonth &&
            (userNAVs[i-1].SK.split('#')[1].slice(0,4)) == currentYear) {
            // If this is the starting NAV, we need to include it in the monthly NAVs
            if (userNAVs[i].SK == 'BALANCE#00000000') {
                allUserNavs.monthly.push(userNAVs[i][balanceKey]);
                allInvestments.monthly.push(netInvestment);
            }
            // If the previous month (in the reverse chronological order NAV list) was equal to the current
            // month, set the monthly starting NAV. This will set the starting NAV to the final day of the previous month
            startingNAVs.monthly = userNAVs[i][balanceKey];
        }

    }

    const cumulativeDollarReturn = getDollarReturn(allInvestments.cumulative, startingNAVs.cumulative, currentNAV);
    const yearlyDollarReturn = getDollarReturn(allInvestments.yearly, startingNAVs.yearly, currentNAV);
    const monthlyDollarReturn = getDollarReturn(allInvestments.monthly, startingNAVs.monthly, currentNAV);

    const cumulativeTWR = getTWR(allUserNavs.cumulative, allInvestments.cumulative, startingNAVs.cumulative);
    const yearlyTWR = getTWR(allUserNavs.yearly, allInvestments.yearly, startingNAVs.yearly);
    const monthlyTWR = getTWR(allUserNavs.monthly, allInvestments.monthly, startingNAVs.monthly);

    const cumulativeMWR = getMWR(allUserNavs.cumulative, allInvestments.cumulative, startingNAVs.cumulative, currentNAV);
    const yearlyMWR = getMWR(allUserNavs.yearly, allInvestments.yearly, startingNAVs.yearly, currentNAV);
    const monthlyMWR = getMWR(allUserNavs.monthly, allInvestments.monthly, startingNAVs.monthly, currentNAV);

    const cumulativeReturns = [cumulativeDollarReturn, cumulativeTWR, cumulativeMWR];
    const yearlyReturns = [yearlyDollarReturn, yearlyTWR, yearlyMWR];
    const monthlyReturns = [monthlyDollarReturn, monthlyTWR, monthlyMWR];

    // Format the return data into an object
    const returnData = {
        cumulativeReturns: cumulativeReturns,
        yearlyReturns: yearlyReturns,
        monthlyReturns: monthlyReturns,
        currentNAV: currentNAV,
        currentNAVDate: currentNAVDate,
        currentDate: currentDate,
        allNAVs: allUserNavs.cumulative,
        allInvestments: allInvestments.cumulative,
        allNAVDates: allNAVDates.reverse(),
        updatedEpoch: updatedEpoch
    };

  return returnData;

}


export function formatReturns(returns) {

    // The returns array will be [] when a user just creates an account, so we just want to return 0s
    if (returns == undefined || returns.length == 0) {
        return  {
            dollarReturn: '0',
            centsReturn: '00',
            twr: '0',
            mwr: '0',
        }
    }

    // returns comes in as a list of [dollarReturn, percentReturn];
    const dollarReturn = returns[0];
    const twr = returns[1];
    const mwr = returns[2];

    const [dollarBalance, centsBalance] = formatBalance(dollarReturn);
    var formattedTWR = (Math.round((twr*100)*100)/100).toString();
    var formattedMWR = (Math.round((mwr*100)*100)/100).toString();

    // Force this to be 2 decimal places
    if (!formattedTWR.includes('.')) {
        formattedTWR += '.00';
    } else if (formattedTWR.slice(-2).includes('.')) {
    // If the formatted return ends in '.{0-9}', add another 0 to it
        formattedTWR += '0';
    }

    if (!formattedMWR.includes('.')) {
        formattedMWR += '.00';
    } else if (formattedMWR.slice(-2).includes('.')) {
    // If the formatted return ends in '.{0-9}', add another 0 to it
        formattedMWR += '0';
    }

    const returnValues = {
        dollarReturn: dollarBalance,
        centsReturn: centsBalance.slice(0,2),
        twr: formattedTWR,
        mwr: formattedMWR,
    }

    return returnValues;

}


export function getCurrentPortfolioValue(portfolio) {

    if (portfolio == undefined) {
        return 0;
    }

    var equityValue = portfolio.portfolio.usEquity.currentUsdValue;
    equityValue = formatEmbedPrice(equityValue);
    var cashValue = portfolio.portfolio.cash.balance.canTrade;
    cashValue = formatEmbedPrice(cashValue);
    const portfolioValue = equityValue + cashValue

    return portfolioValue;

}


export function useInvestmentPerformanceHandler() {

    const userNAVs = useSelector(state => state.portfolio.userNAVs);
    const portfolio = useSelector(state => state.portfolio.portfolio);

    const [cumulativeReturn, setCumulativeReturn] = useState({});
    const [currentNAVDate, setCurrentNAVDate] = useState('');
    const [firstNavDate, setFirstNaveDate] = useState('');

    function getReturnValues() {

        const allReturnValues = getReturns(userNAVs === undefined ? [] : userNAVs);
        setCurrentNAVDate(formatNavDate(allReturnValues.currentNAVDate));
        const initialDate = allReturnValues.allNAVDates[0] == '00000000' ? allReturnValues.allNAVDates[1] : allReturnValues.allNAVDates[0];
        setFirstNaveDate(formatNavDate(initialDate))

        // Format the return values
        const formattedCumulativeReturns = formatReturns(allReturnValues.cumulativeReturns);

        setCumulativeReturn(formattedCumulativeReturns);

    }

    useEffect(() => {
        if (portfolio != null && Object.keys(portfolio).length > 0) {
            getReturnValues()
        }
    }, [portfolio])

    return {
        cumulativeReturn,
        firstNavDate,
        currentNAVDate,
    };

}


export function formatDisplayPercentage(dollarValue, sumAssets) {

    const percentage = Math.round(((parseFloat(dollarValue) / sumAssets) * 100) * 10) / 10;
    var displayPercentage = (percentage.toString());
    if (!displayPercentage.includes('.')) {
        displayPercentage += '.0';
    }
    return displayPercentage;

}


export function formatEmbedPrice(priceData) {
  // Annoying input format of {"value": "235","scale": 1}, where scale represents the
  // number of decimal places

    if (priceData == undefined) {
        return 0;
    }

    let value = priceData.value;
    const scale = parseFloat(priceData.scale);
    value = parseFloat(value)
    value = value / (Math.pow(10,scale))
    return value;

}



export function useAssetAllocationHandler() {

    const savedPortfolio = useSelector(state => state.portfolio.portfolio);
    const savedTargetAllocation = useSelector(state => state.portfolio.targetAllocation);
    const savedCurrentYields = useSelector(state => state.portfolio.currentYields);
    const savedYieldUpdateDate = useSelector(state => state.portfolio.yieldUpdateDate);

    const [formattedAssetAllocation, setFormattedAssetAllocation] = useState([]);
    const [isTargetAllocation, setIsTargetAllocation] = useState(false)
    const [portfolioYield, setPortfolioYield] = useState(0);
    const [dollarYield, setDollarYield] = useState(0);
    const [asOfDate, setAsOfDate] = useState('')

    const dispatch = useDispatch();

    function getFormattedAllocation(portfolio, targetAllocation) {

        const positions = portfolio.positions === undefined ? [] : portfolio.positions;
        if (positions.length == 0) {
            var allocation = formatDefaultAllocationEmbed(targetAllocation)
            allocation.sort((a, b) => (a.percentage > b.percentage) ? -1 : 1);
            setFormattedAssetAllocation(allocation)
            setIsTargetAllocation(true)
            return
        }

        var sumAssets = 0;
        for (var i=0; i<positions.length; i++) {
            const quantity = formatEmbedPrice(positions[i].usEquityAsset.balance.tradeBalance);
            const price = formatEmbedPrice(positions[i].usEquityAsset.closingPrice);
            sumAssets += parseFloat(quantity*price);
        }

        let assetAllocation = [];
        for (var i=0; i<positions.length; i++) {
            const quantity = formatEmbedPrice(positions[i].usEquityAsset.balance.tradeBalance);
            const price = formatEmbedPrice(positions[i].usEquityAsset.closingPrice);
            const dollarValue = quantity*price;
            const displayPercentage = formatDisplayPercentage(dollarValue, sumAssets);
            var percentage = (parseFloat(dollarValue) / sumAssets);
            const [dollarBalance, centsBalance] = formatBalance(dollarValue);
            const symbol = positions[i].usEquityAsset.symbol;

            //console.log("percentage", percentage)

            assetAllocation.push({
                index: i,
                dollarAmount: dollarBalance,
                centsAmount: centsBalance,
                symbol: positions[i].usEquityAsset.symbol,
                displayName: assetDetails[symbol].shortName,
                displayPercentage: displayPercentage,
                percentage: percentage
            })
        }

        assetAllocation.sort((a, b) => (a.percentage > b.percentage) ? -1 : 1);
        setFormattedAssetAllocation(assetAllocation)

    }

    function getPortfolioYield(portfolio, currentYields) {
        const portfolioValue = getCurrentPortfolioValue(portfolio);
        let totalYield = 0;
        for (var i=0; i<formattedAssetAllocation.length; i++) {
            const symbol = formattedAssetAllocation[i].symbol;
            const percentage = formattedAssetAllocation[i].percentage;
            const currentYield = parseFloat(currentYields[symbol].yield) - parseFloat(currentYields[symbol].expense_ratio);
            totalYield += (currentYield * percentage)
        }
        setPortfolioYield(Math.round(totalYield*100)/100)
        setDollarYield((totalYield/100) * portfolioValue)
    }

    useEffect(() => {
        // If the portfolio is empty, check the session storage
        let portfolio = savedPortfolio;
        let targetAllocation = savedTargetAllocation;
        if (portfolio != undefined && Object.keys(portfolio).length < 1) {
            portfolio = sessionStorage.getItem('portfolio');
            if (portfolio != null) {
                portfolio = JSON.parse(portfolio);
                dispatch(portfolioActions.setPortfolio(portfolio));
            }
        }
        if (portfolio != undefined && portfolio != null && Object.keys(portfolio).length > 0) {
            getFormattedAllocation(portfolio, targetAllocation)
        }
    }, [savedPortfolio])

    useEffect(() => {
        let currentYields = savedCurrentYields;
        let yieldUpdateDate = savedYieldUpdateDate;
        if (currentYields != undefined && Object.keys(currentYields).length < 1) {
            currentYields = sessionStorage.getItem('currentYields');
            yieldUpdateDate = sessionStorage.getItem('yieldUpdateDate');
            if (currentYields != null) {
                currentYields = JSON.parse(currentYields);
                dispatch(portfolioActions.setCurrentYields(currentYields, yieldUpdateDate));
            }
        }
        if (currentYields !== undefined && formattedAssetAllocation.length > 0 && Object.keys(currentYields).length > 0) {
            getPortfolioYield(savedPortfolio, currentYields)
            // Format the as of date from yyyymmdd to mm/dd/yyyy
            const year = yieldUpdateDate.slice(0,4);
            const month = yieldUpdateDate.slice(4,6);
            const day = yieldUpdateDate.slice(6,8);
            setAsOfDate(month + '/' + day + '/' + year)
        }
    }, [savedCurrentYields, formattedAssetAllocation, savedPortfolio])

    return { formattedAssetAllocation, portfolioYield, dollarYield, isTargetAllocation, asOfDate };

}



export function formatDefaultAllocationEmbed(defaultAllocation) {

    if (defaultAllocation == undefined || defaultAllocation == null) {
        // Try to get the saved allocation from session storage
        defaultAllocation = sessionStorage.getItem('targetAllocation');
        if (defaultAllocation !== null && defaultAllocation !== 'null') {
            defaultAllocation = JSON.parse(defaultAllocation);
        } else {
            defaultAllocation = {};
        }
    }

    const symbols = Object.keys(defaultAllocation);
    var formattedAllocation = [];
    let percentage;
    for (var i=0; i<symbols.length; i++) {
        percentage = defaultAllocation[symbols[i]];
        if (parseFloat(percentage) < 0.001) {
            continue;
        }
        formattedAllocation.push({
            index: i+1,
            dollarAmount: '0',
            centsAmount: '00',
            symbol: symbols[i],
            displayPercentage: formatDisplayPercentage(percentage, 1),
            percentage: percentage,
            displayName: assetDetails[symbols[i]].shortName,
        })
    }

    formattedAllocation.sort((a, b) => (a.percentage > b.percentage) ? -1 : 1);
    return formattedAllocation;

}


export function formatAssetAllocation(assetAllocation, currentNAV) {

    // Initialize variables
    let sumAssets = 0;
    let formattedAssetAllocation = [];
    let displayPercentage;
    let dollarValue;
    let symbol;

    // Get the symbols
    let symbols = [];
    const assetDescriptions = investmentInfo.defaultAllocations.allocations;
    for (var i=0; i<assetDescriptions.length; i++) {
        symbols.push(assetDescriptions[i][0])
    }

    // Turn the asset allocation into a dictionary
    let assetAllocationObject = {};
    for (var i=0; i<assetAllocation.length; i++) {
        assetAllocationObject[assetAllocation[i][0]] = assetAllocation[i][1];
    }

    // Get the sum of the asset values
    for (var i=0; i<assetAllocation.length; i++) {
        symbol = symbols[i];
        // Ignore cash for now
        if (symbol == 'USD') {
            continue
        }
        sumAssets += parseFloat(assetAllocation[i][1]);
    }

    for (i=0; i<symbols.length; i++) {
        symbol = symbols[i];
        // We don't want to display cash here
        if (symbol == 'USD') {
            continue
        }
        dollarValue = assetAllocationObject[symbol];
        displayPercentage = formatDisplayPercentage(dollarValue, sumAssets);
        var percentage = (parseFloat(dollarValue) / sumAssets);
        const [dollarBalance, centsBalance] = formatBalance(currentNAV * percentage);
        formattedAssetAllocation.push({
            index: i,
            dollarAmount: dollarBalance,
            centsAmount: centsBalance,
            symbol: symbol,
            displayPercentage: displayPercentage
        })
    }

  return formattedAssetAllocation;

}

export function formatNavDate(navDate) {

    //
    if (navDate == undefined || navDate == '00000000') {
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear();
        const currentMonth = getMonthFromTimestamp(currentDate).slice(0,3);
        const currentDay = currentDate.getDate()
        return `${currentMonth} ${currentDay}, ${currentYear}`;
    }

    const year = navDate.slice(0,4);
    const month = navDate.slice(4,6);
    const day = navDate.slice(6,8);

    const newDate = new Date(year, month-1, day);
    const formattedDate = formatDateForDetails(newDate).slice(0,12);

    return formattedDate;

}

export function getDateRanges(userNAVdates, currentDate, accountOpenDate, updatedEpoch) {

    // Handle the
    if (currentDate == '') {
        var date = new Date();
        date = date.toLocaleString('default')
        date = date.split(',')
        date = date[0].split('/')
        const year = date[2]
        const month = date[0].length == 1 ? '0'+date[0] : date[0]
        const day = date[1]
        currentDate = year + month + day
    }

    // For a new account, we need to use the account open date
    if (userNAVdates.length == 0 || (userNAVdates.length == 1 && userNAVdates[0] == ["00000000"])) {
        const formattedDate = formatDateForDateRange(accountOpenDate);
        const formattedCurrentDate = formatDateForDateRange(currentDate);
        const dateRange = `${formattedDate} - ${formattedCurrentDate}`
        return [dateRange, dateRange, dateRange, formattedCurrentDate];
    }
    const mostRecentDate = userNAVdates[userNAVdates.length-1];

    // Format the last updated date
    const formattedLastUpdated = formatLastUpdatedTime(updatedEpoch)

    const year = mostRecentDate.slice(0,4);
    const month = mostRecentDate.slice(4,6);
    const formattedEndingDate = formatDateForDateRange(mostRecentDate);

    // Get the month date range
    const monthStart = Math.max(`${year}${month}01`, accountOpenDate).toString();
    const formattedMonthStart = formatDateForDateRange(monthStart);
    const monthlyDateRange = `${formattedMonthStart} - ${formattedEndingDate}`

    // Get the yearly date range
    const yearStart = Math.max(`${year}0101`, accountOpenDate).toString();
    const formattedYearStart = formatDateForDateRange(yearStart);
    const yearlyDateRange = `${formattedYearStart} - ${formattedEndingDate}`

    // Get the cumulative date range
    const formattedCumulativeStart = formatDateForDateRange(accountOpenDate).toString();
    const cumulativeDateRange = `${formattedCumulativeStart} - ${formattedEndingDate}`

    return [monthlyDateRange, yearlyDateRange, cumulativeDateRange, formattedLastUpdated];

}
