const PARAMS = {

    useDefaultDevParams: false,

    // Ordered list of the pages in the sign up process
    // This omits the beneficial owner address, beneficial owner person, and photo id 
    // pages which are handled separately since they are extensions of the beneficial owner page
    signUpPages: [
        '/sign-up/financial-questionnaire',
        '/sign-up/portfolio-recommendation',
        '/sign-up/authorized-person',
        '/sign-up/phone-verification',
        '/sign-up/authorized-person-address',
        '/sign-up/company-info',
        '/sign-up/company-address',
        '/sign-up/beneficial-owners',
        '/sign-up/document-upload',
        '/sign-up/create-account',
    ],

    emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    passwordRegex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*]).{8,}$/,
    numberOnlyRegex: /^\d+$/,

    /*monthConversion: {
        'jan': 'January',
        'feb': 'February',
        'mar': 'March',
        'apr': 'April',
        'may': 'May',
        'jun': 'June',
        'jul': 'July',
        'aug': 'August',
        'sep': 'September',
        'oct': 'October',
        'nov': 'November',
        'dec': 'December',
    },*/

};

export default PARAMS;