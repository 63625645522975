
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeWithdrawalRequest } from '../../../services/transfers';
import { formatBalance } from '../../../utils/generalFunctions';


export function formatAmount(amount) {
    // Amount comes in as $250,000
    // We want to return 250000
    let newAmount = amount;
    newAmount = newAmount.replace('$', '');
    newAmount = newAmount.replace(',', '');
    newAmount = parseFloat(newAmount);
    return newAmount;
}

export function createStartDate() {
    // We want to return a date in the format of YYYYMMDD
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let startDate = year.toString() + month.toString() + day.toString();
    return startDate;
}


export function useWithdrawalHandler() {

    const savedAccountBalance = useSelector(state => state.home.accountBalance);
    const userID = useSelector(state => state.auth.userID);
    const sessionToken = useSelector(state => state.auth.sessionToken);
    const [accountBalance, setAccountBalance] = useState('');
    const [activityIndicator, setActivityIndicator] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);

    async function sendWithdrawalRequest(amount, toAccountID) {
        setActivityIndicator(true);
        const formattedAmount = formatAmount(amount);
        const startDate = createStartDate();
        const [resData, validWithdrawal] = await makeWithdrawalRequest(toAccountID, 'ACH', formattedAmount, userID, sessionToken);
        console.log('resData', resData);
        console.log('validWithdrawal', validWithdrawal);
        setActivityIndicator(false);

        if (validWithdrawal) {
            setSuccessModal(true);
        } else {
            setErrorModal(true);
        }
    }

    useEffect(() => {
        if (savedAccountBalance != null) {
            const [dollarBalance, centsBalance] = formatBalance(savedAccountBalance)
            setAccountBalance(dollarBalance+'.'+centsBalance)
        }
    }, [savedAccountBalance])

    return { accountBalance, sendWithdrawalRequest, activityIndicator, successModal, errorModal, setSuccessModal, setErrorModal }

}
