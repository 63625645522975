import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import CreateAccountWrapper from '../../components/createAccount/CreateAccountWrapper';
import TextInput from '../../components/general/TextInput';

import * as createAccountActions from '../../store/actions/createAccount';
import { handleStateInput, handleZipCode } from '../../utils/generalFunctions';

import '../../styles/accountCreationStyles.css';
import '../../styles/generalStyles.css';

const BeneficialOwnerAddress = (props) => {

    const { ownerNumber } = useParams();
    const index = ownerNumber; // index just makes more sense as a variable name
    const beneficialOwners = useSelector(state => state.createAccount.beneficialOwners);
    const editOrAdd = (beneficialOwners[index].street == undefined) ? 'add' : 'edit';
    const ownerName = beneficialOwners[index].firstName;

    const savedStreet = beneficialOwners[index].street === undefined ? '' : beneficialOwners[index].street;
    const savedUnit = beneficialOwners[index].unit === undefined ? '' : beneficialOwners[index].unit;
    const savedCity = beneficialOwners[index].city === undefined ? '' : beneficialOwners[index].city;
    const savedState = beneficialOwners[index].state === undefined ? '' : beneficialOwners[index].state;
    const savedZip = beneficialOwners[index].zip === undefined ? '' : beneficialOwners[index].zip;

    const [street, setStreet] = useState(editOrAdd == 'add' ? '' : savedStreet);
    const [unit, setUnit] = useState(editOrAdd == 'add' ? '' : savedUnit);
    const [city, setCity] = useState(editOrAdd == 'add' ? '' : savedCity);
    const [state, setState] = useState(editOrAdd == 'add' ? '' : savedState);
    const [zip, setZip] = useState(editOrAdd == 'add' ? '' : savedZip);

    const [buttonDisabled, setButtonDisabled] = useState(true);

    const history = useHistory();
    const dispatch = useDispatch();

    const handleState = (event) => {
        const newState = handleStateInput(state, event.target.value);
        setState(newState);
    }

    const handleZip = (event) => {
        const newZip = handleZipCode(zip, event.target.value);
        setZip(newZip)
    }

    const sendToNext = () => {

        var updatedBeneficialOwners = beneficialOwners;
        updatedBeneficialOwners[index].street = street;
        updatedBeneficialOwners[index].unit = unit;
        updatedBeneficialOwners[index].city = city;
        updatedBeneficialOwners[index].state = state;
        updatedBeneficialOwners[index].zip = zip;

        // Update the redux store with the updated beneficial owner
        dispatch(createAccountActions.setBeneficialOwners(updatedBeneficialOwners));

        history.push(`/sign-up/photo-id-upload/${ownerNumber}`)
    }

    useEffect(() => {
        if (street.length > 1 && city.length > 1 && state.length === 2 && zip.length === 5) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [street, city, state, zip])

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (!buttonDisabled) {
                    sendToNext()
                }
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [buttonDisabled, street, unit, city, state, zip]);

    return (
        <CreateAccountWrapper
            buttonDisabled={buttonDisabled}
            onButtonClick={() => sendToNext()}
            onSecondaryTextClick={() => history.goBack()}>
            <div className="section-container">
                <header className="bold-font account-creation-header">
                    Beneficial Owner
                </header>
                <p className="light-font sub-header">
                    Please enter the permanent address for {ownerName}.
                </p>

                <div className="account-creation-input-row">
                    <TextInput
                        value={street}
                        headerMessage={"Street address"}
                        onTextChange={(event) => setStreet(event.target.value)}
                        placeholder="100 S Main St"
                        inputType={"text"}
                    />
                </div>

                <div className="account-creation-input-row">
                    <TextInput
                        value={unit}
                        headerMessage={"Unit"}
                        onTextChange={(event) => setUnit(event.target.value)}
                        placeholder={"Apt #100"}
                        inputType={"text"}
                    />
                </div>

                <div className="account-creation-input-row">
                    <TextInput
                        value={city}
                        headerMessage={"City"}
                        onTextChange={(event) => setCity(event.target.value)}
                        placeholder={"San Francisco"}
                        inputType={"text"}
                    />
                </div>

                <div className="account-creation-input-row">
                    <div className="first-name-container">
                        <TextInput
                            value={state}
                            headerMessage={"State"}
                            onTextChange={handleState}
                            placeholder={"CA"}
                            inputType={"text"}
                        />
                    </div>
                    <div className="last-name-container">
                        <TextInput
                            value={zip}
                            headerMessage={"Zip code"}
                            onTextChange={handleZip}
                            placeholder={"94102"}
                            inputType={"text"}
                        />
                    </div>
                </div>

            </div>
        </CreateAccountWrapper>
    )

}

export default BeneficialOwnerAddress;
