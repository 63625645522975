
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';

import { getSources } from '../../../services/embed';
import { makeDBRequest, getPortfolioDetails, getCurrentYields } from '../../../services/data';
import { investmentInfo } from '../../../constants/portfolioInfo';
import * as portfolioActions from '../../../store/actions/portfolio';
import * as homeActions from '../../../store/actions/home';
import * as transferActions from '../../../store/actions/transfers';


async function requestUserNAVs(userID, sessionToken, savedUserNAVs) {
    if (savedUserNAVs !== null) {
        return typeof (savedUserNAVs) == 'string' ? JSON.parse(savedUserNAVs) : savedUserNAVs
    }
    const payload = {
        sk: 'BALANCE',
        table_to_query: 'accounting',
    }
    const userNAVs = await makeDBRequest(userID, sessionToken, payload);
    return userNAVs;
}

async function requestEmbedSources(userID, sessionToken, savedSources) {
    if (savedSources != null) {
        return typeof (savedSources) == 'string' ? JSON.parse(savedSources) : savedSources
    }
    const [embedSources, embedSourceStatus] = await getSources(userID, sessionToken);
    return embedSources;
}

async function requestYields(savedYields, yieldUpdateDate) {
    if (savedYields != null) {
        return typeof (savedYields) == 'string' ? [JSON.parse(savedYields), yieldUpdateDate] : [savedYields, yieldUpdateDate]
    }
    const [yields, yieldStatus] = await getCurrentYields();
    return [yields.yields, yields.asOfDate]
}

async function requestAllocation(userID, sessionToken, savedAllocation, savedTargetAllocation) {
    if (savedAllocation !== null) {
        const allocation = typeof (savedAllocation) == 'string' ? JSON.parse(savedAllocation) : savedAllocation;
        const targetAllocation = typeof (savedTargetAllocation) == 'string' ? JSON.parse(savedTargetAllocation) : savedTargetAllocation;
        return [allocation, targetAllocation];
    }
    const payload = { sk: 'ALLOCATION' }
    const allocation = await makeDBRequest(userID, sessionToken, payload);
    const targetAllocation = allocation.length == 0 ? investmentInfo.defaultAllocations : allocation[0].ALLOCATION_targets;
    return [allocation, targetAllocation]
}

async function requestPortfolioInfo(userID, sessionToken, savedPortfolio) {
    if (savedPortfolio !== null) {
        return typeof (savedPortfolio) == 'string' ? JSON.parse(savedPortfolio) : savedPortfolio;
    }
    const [portfolio, status] = await getPortfolioDetails(userID, sessionToken);
    return portfolio;
}

async function getUserMetaInfo(userID, sessionToken) {
    const payload = { sk: 'META' }
    const userMetaInfo = await makeDBRequest(userID, sessionToken, payload);
    sessionStorage.setItem("userMetaInfo", JSON.stringify(userMetaInfo))
}


export function useHomePageHandler() {

    const [userID, setUserID] = useState(sessionStorage.getItem("userID"))
    const [sessionToken, setSessionToken] = useState(sessionStorage.getItem("sessionToken"))
    const [userNAVs, setUserNAVs] = useState(sessionStorage.getItem("userNAVs"))
    const [accountBalance, setAccountBalance] = useState(sessionStorage.getItem("accountBalance"))
    const [portfolio, setPortfolio] = useState(sessionStorage.getItem("portfolio"))
    const [allocation, setAllocation] = useState(sessionStorage.getItem("allocation"))
    const [targetAllocation, setTargetAllocation] = useState(sessionStorage.getItem("targetAllocation"))
    const [embedSources, setEmbedSources] = useState(sessionStorage.getItem("embedSources"))
    const userMetaInfo = sessionStorage.getItem("userMetaInfo")
    const [yields, setYields] = useState(sessionStorage.getItem("currentYields"))
    const [yieldUpdateDate, setYieldUpdateDate] = useState(sessionStorage.getItem("yieldUpdateDate"))

    const [activityIndicator, setActivityIndicator] = useState(true)

    const dispatch = useDispatch();


    async function getUserNAVs() {
        const navs = await requestUserNAVs(userID, sessionToken, userNAVs);
        // The account balance is always the most recent item in the BALANCE array
        const balance = navs.length == 0 ? '0' : navs[0].BALANCE_balance;
        sessionStorage.setItem("userNAVs", JSON.stringify(navs));
        sessionStorage.setItem("accountBalance", accountBalance);
        setUserNAVs(navs)
        setAccountBalance(balance)
        dispatch(portfolioActions.setUserNAVs(navs))
        dispatch(homeActions.setAccountBalance(balance))
    }

    async function getPortfolioInfo() {
        const userPortfolio = await requestPortfolioInfo(userID, sessionToken, portfolio)
        setPortfolio(userPortfolio)
        dispatch(portfolioActions.setPortfolio(userPortfolio))
        sessionStorage.setItem("portfolio", JSON.stringify(userPortfolio))
    }

    async function getAllocationInfo() {
        var [assetAllocation, targetAssetAllocation] = await requestAllocation(
            userID, sessionToken, allocation, targetAllocation
        )
        setAllocation(assetAllocation)
        dispatch(portfolioActions.setAllocation(assetAllocation))
        dispatch(portfolioActions.setTargetAllocation(targetAssetAllocation))
        sessionStorage.setItem("allocation", JSON.stringify(assetAllocation))
        sessionStorage.setItem("targetAllocation", JSON.stringify(targetAssetAllocation));
    }

    async function getYields() {
        const [currentYields, asOfDate] = await requestYields(yields, yieldUpdateDate);
        setYields(currentYields)
        setYieldUpdateDate(asOfDate)
        sessionStorage.setItem("currentYields", JSON.stringify(currentYields));
        sessionStorage.setItem("yieldUpdateDate", asOfDate);
        dispatch(portfolioActions.setCurrentYields(currentYields, asOfDate))
    }

    async function getEmbedSources() {
        const sources = await requestEmbedSources(userID, sessionToken, embedSources);
        setEmbedSources(sources)
        sessionStorage.setItem("embedSources", JSON.stringify(sources));
        dispatch(transferActions.setEmbedSources(sources))
    }

    useEffect(() => {
        // Request all of the necessary info for the dashboard, done async
        getUserNAVs();
        getPortfolioInfo();
        getAllocationInfo();
        getYields()
        getEmbedSources();
        //getUserMetaInfo(userID, sessionToken, dispatch, userMetaInfo);
    }, [])

    useEffect(() => {
        if (portfolio !== null && allocation !== null && userNAVs !== null && embedSources !== null && yields !== null) {
            setActivityIndicator(false)
        }
    }, [yields, embedSources, userNAVs, portfolio, allocation])

    return { accountBalance, activityIndicator }

}
