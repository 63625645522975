import React from "react";
import { useHistory } from "react-router-dom";
import { HiLink } from "react-icons/hi";
import { HiUpload } from "react-icons/hi";
import { GrDocumentText } from "react-icons/gr";
import { HiDocumentText } from "react-icons/hi";

import DashboardWrapper from '../../../components/general/DashboardWrapper';
import LoadingSpinner from "../../../components/general/LoadingSpinner";
import ClickableRow from '../../../components/dashboard/ClickableRow';

import { useAccountHandler } from '../../../logic/dashboard/account/account';

import './Account.css';
import '../../../styles/generalStyles.css';


const Account = () => {

    const history = useHistory();

    const {
        accountInfo,
        companyDocs,
        activityIndicator
    } = useAccountHandler();

    return (
        <DashboardWrapper screen="account">
            {activityIndicator && <LoadingSpinner />}
            <div className="home-container">

                <h2 className="main-header">Account</h2>

                <ClickableRow
                    leftIcon={<HiLink />}
                    text={"Manage linked accounts"}
                    onClick={() => history.push('/dashboard/linked-accounts')}
                />

                <ClickableRow
                    leftIcon={<GrDocumentText />}
                    text={"Statements"}
                    onClick={() => history.push('/dashboard/statements')}
                />

                <ClickableRow
                    leftIcon={<HiDocumentText />}
                    text={"Legal documents"}
                    onClick={() => history.push('/dashboard/legal')}
                />

                <ClickableRow
                    leftIcon={<HiUpload />}
                    text={"Document upload"}
                    onClick={() => history.push('/dashboard/document-upload')}
                />

            </div>
        </DashboardWrapper>
    )
}

export default Account;
