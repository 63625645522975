import { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';

import LoadingSpinner from "../general/LoadingSpinner";
import Modal from '../general/Modal';

import { usePlaidLinkHandler } from '../../logic/dashboard/transfers/plaidLink';

import "./PlaidLink.css";

const PlaidLink = (props) => {

    const [linkToken, setLinkToken] = useState('');
    const [activityIndicator, setActivityIndicator] = useState(false);

    const {
        getToken, linkingAccountOverlay, successOverlay, handlePlaidSuccess,
        setSuccessOverlay, slowResponseOverlay, setSlowResponseOverlay
    } = usePlaidLinkHandler();

    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: (public_token, metadata) => {
            handlePlaidSuccess(public_token, metadata)
            // send public_token to server
        },
        onExit: (event) => {
            console.log(event)
        }
    })

    async function handleNewAccountClick() {
        if (ready && linkToken.length > 1) {
            // This case happens when someone clicks 'Link new account', then closes the Plaid modal, then clicks it again
            open();
        } else {
            setActivityIndicator(true);
            const token = await getToken()
            setLinkToken(token.link_token)
            setActivityIndicator(false);
        }
    }

    useEffect(() => {
        if (ready) {
            console.log('opening plaid link')
            open()
        }
    }, [ready])

    return (

        <div className="link-new-account-container" style={props.customStyle}>
            <p className="semi-bold-font link-new-account-text" 
                onClick={() => handleNewAccountClick()}>
                {props.text == undefined ? 'Link a new account' : props.text}
            </p>

            {activityIndicator && <LoadingSpinner />}

            {linkingAccountOverlay && <Modal>
                <div className="linking-account-modal-content">
                    <h2 className="semi-bold-font account-linking-header">Linking Account</h2>
                    <p className="regular-font account-linking-text">
                        Please wait while we finish linking your account. This could take up to a minute.
                    </p>
                    <LoadingSpinner />
                </div>
            </Modal>}

            {successOverlay && <Modal closeModal={() => setSuccessOverlay(false)}>
                <div className="linking-account-modal-content">
                    <h2 className="semi-bold-font account-linking-header">Success</h2>
                    <p className="regular-font account-linking-text">
                        Your account has been successfully linked. You can now make a transfer to or from this account.
                    </p>
                </div>
            </Modal>}

            {slowResponseOverlay && <Modal closeModal={() => setSlowResponseOverlay(false)}>
                <div className="linking-account-modal-content">
                    <h2 className="semi-bold-font account-linking-header">Slow Response</h2>
                    <p className="regular-font account-linking-text">
                        Oops, this is taking longer than expected. Not to worry your information
                        has been received and your account should be linked shortly.
                    </p>
                </div>
            </Modal>}

        </div>

    )

}

export default PlaidLink;