import React, { useState, useEffect } from 'react';
import { IconContext } from "react-icons";
import { GoChevronRight } from "react-icons/go";
import { GoChevronDown } from "react-icons/go";

import DashboardWrapper from '../../../components/general/DashboardWrapper';

import { useStatementHandler } from '../../../logic/dashboard/account/account';

import './Statements.css';

const Statements = () => {

    const { statements, statementYears, activityIndicator } = useStatementHandler();
    const [yearVisibility, setYearVisibility] = useState({});

    useEffect(() => {
        // Set up to the first 2 years to visible by default
        const newYearVisibility = {}
        for (let i = 0; i < Math.min(2, statementYears.length); i++) {
            newYearVisibility[statementYears[i]] = true
        }
        setYearVisibility(newYearVisibility)
    }, [statementYears])

    function toggleYearVisibility(year) {
        const newYearVisibility = { ...yearVisibility }
        newYearVisibility[year] = !newYearVisibility[year]
        setYearVisibility(newYearVisibility)
    }

    return (

        <DashboardWrapper screen={"account"}>

            <div className="home-container">
                <h2 className="main-header">Statements</h2>

                {activityIndicator && <p>Loading...</p>}

                {!activityIndicator && statements.length === 0 &&
                    <div>
                        <p className="regular-font">There are no statements for your account at this time.</p>
                    </div>
                }

                {!activityIndicator &&
                    <div>
                        {statements.map((statement, index) =>
                            <div key={index} className="statement-year-container">
                                <div className="statement-year-row" onClick={() => toggleYearVisibility(statement.year)}>
                                    <h3 className="medium-font statement-year">{statement.year}</h3>
                                    <IconContext.Provider
                                        value={{
                                            size: "20px",
                                            color: "black",
                                            className: "side-nav-icon"
                                        }}>
                                        <div>
                                            {yearVisibility[statement.year] ? <GoChevronDown /> : <GoChevronRight />}
                                        </div>
                                    </IconContext.Provider>
                                </div>
                                <div style={{ display: yearVisibility[statement.year] ? 'block' : 'none' }}>
                                    {statement.statements.map((month, index) =>
                                        <div key={index} className="statement-month-row">
                                            <a href={month.value.link} target="_blank" className="semi-bold-font statement-month">
                                                {month.month}
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                }

            </div>

        </DashboardWrapper>

    )

}

export default Statements;

/*
{statements.map((statement, index) =>
                    <div key={index}>
                        <h3>{statement.year}</h3>
                        {statement.statements.map((month, index) =>
                            <div key={index}>
                                <p>{month.month}</p>
                            </div>
                        )}
                    </div>
                )}
                */