
import { formatNameCapitalFirst, stringReplace } from './generalFunctions';


export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export const shortMonthNames = ["jan", "feb", "mar", "apr", "may", "jun",
  "jul", "aug", "sep", "oct", "nov", "dec"
];

const monthConversion = {
  'jan': 'January',
  'feb': 'February',
  'mar': 'March',
  'apr': 'April',
  'may': 'May',
  'jun': 'June',
  'jul': 'July',
  'aug': 'August',
  'sep': 'September',
  'oct': 'October',
  'nov': 'November',
  'dec': 'December',
};

export function calculateAge(dob) {
    // dob format is either YYYY/MM/DD or YYYY-MM-DD
    // Have to convert the displayed month to the 0 indexed month
    var birthday = +new Date(dob.slice(6, 10), dob.slice(0, 2) - 1, dob.slice(3, 5));
    // 31557600000 is 24 * 3600 * 365.25 * 1000
    return ~~((Date.now() - birthday) / (31557600000));
}


export function getTimeOfDayFromDateString(dateString) {

    const month = dateString.slice(4,7);
    const day = dateString.slice(8,10);
    const year = dateString.slice(11,15);
    var hour = dateString.slice(16,18);
    const minute = dateString.slice(19,21);
    const seconds = dateString.slice(22,24);

    // Decide whether to show 'AM' or 'PM', plus format the hour for displaying properly
    if (hour == '12') {
        var amORpm = 'PM';
    } else if (parseFloat(hour) >= 13) {
        hour = hour - 12
        var amORpm = 'PM';
    } else {
        if (hour == '00') {
            hour = '12';
        } else if (hour[0] == '0') {
            hour = hour[1];
        }
        var amORpm = 'AM';
    }

    return [hour, minute, seconds, amORpm];

}

export const formatDateForDetails = (date) => {

    // The input format of date is: 'Sun Feb 14 2021 14:40:05 GMT-0700 (MST)'
    // The return format of date is: 'Jan 1, 2021 2:05 PM'

    const dateString = date.toString();

    // Extract the year, month, and day
    const month = dateString.slice(4,7);
    const day = dateString.slice(8,10);
    const year = dateString.slice(11,15);

    // Get the time of day
    const [hour, minute, seconds, amORpm] = getTimeOfDayFromDateString(dateString);

    // Put all of the date params together
    const formattedDate = month + ' ' + day + ', ' + year + ' ' + hour + ':' + minute + ' ' + amORpm;
    return formattedDate;

}


export function checkForDST(date) {

    // The date comes in as a string with the format 2022-06-22T16:45:38.489Z
    // Date.parse converts it to an epoch, the calling new Date() turns it into a date object
    date = new Date(Date.parse(date))

    Date.prototype.stdTimezoneOffset = function () {
        // Standard timezone offset (handles timezones ahead of UTC too, which is what Math.max is used for)
        var jan = new Date(this.getFullYear(), 0, 1);
        var jul = new Date(this.getFullYear(), 6, 1);
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    }

    Date.prototype.isDstObserved = function () {
        // If the date's timezone offset is less than the standard timezone offset, then it is DST
        return this.getTimezoneOffset() < this.stdTimezoneOffset();
    }

    return date.isDstObserved()

}


export function formatTradeDate(date, longOrShort='long') {

    // The input date format is 2022-04-21T13:46:28.796725911-04:00
    // longOrShort is whether we want the time of day included or not, as well as the full month name

    const isDST = checkForDST(date)
    const easternTimeOffset = isDST ? 4 : 5;

    var month = parseFloat(date.slice(5,7));
    month = monthNames[month-1]
    const day = date.slice(8,10);
    const year = date.slice(0,4);
    var hour = date.slice(11,13) - easternTimeOffset;
    const minute = date.slice(14,16);

    if (hour == '12') {
        var amORpm = 'PM';
    } else if (parseFloat(hour) >= 13) {
        hour = hour - 12
        var amORpm = 'PM';
    } else {
        if (hour == '00') {
            hour = '12';
        } else if (hour[0] == '0') {
            hour = hour[1];
        }
        var amORpm = 'AM';
    }

    let formattedDate;
    if (longOrShort == 'long') {
        formattedDate = `${month} ${day}, ${year}, ${hour}:${minute} ${amORpm}`;
    } else {
        formattedDate = `${month.slice(0,3)} ${day}, ${year}`;
    }

    return formattedDate;

}


export const convertShortMonthToLong = (month) => {

    return monthConversion[month.toLowerCase()];

}


export const getMonthFromEpoch = (epoch) => {

    // Get the month and year from an epoch date, returned as the full month name

    // Get the current ms offset (used for the date string)
    var date = new Date();
    var msOffset = date.getTimezoneOffset() * 60 * 1000;
    date = new Date(epoch + msOffset);

    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
    ];

    const month = monthNames[date.getMonth()];

    return month;

}


export const getYearFromEpoch = (epoch) => {

    // Get the month and year from an epoch date

    // Get the current ms offset (used for the date string)
    var date = new Date();
    var msOffset = date.getTimezoneOffset() * 60 * 1000;
    date = new Date(epoch + msOffset);

    // Convert the date to a string in order to get the year
    const dateString = date.toString();
    const year = dateString.slice(11,15);
    return year;

}


export const getMonthFromTimestamp = (date) => {

    // The date has to be a date object, not string, in the format '2021-03-09T11:45:21.350Z'
    let month = date.toLocaleString('default', { month: 'long' });
    if (month.length > 13) {
        date = date.toLocaleString('default');
        let splitDate = date.split(' ');
        month = splitDate[1];
        month = convertShortMonthToLong(month);
    }
    return month;

}


export const getDayFromTimestamp = (date) => {

    // The date has to be in the format '2021-03-09T11:45:21.350Z'
    let day = date.toLocaleString('default', { day: 'numeric' });
    if (day.length > 3) {
        day = date.toLocaleString('default');
    let splitDate = day.split(' ');
        day = (splitDate.length == 6) ? splitDate[3] : splitDate[2];
    }
    day = (day.length == 1) ? "0"+day : day
    return day;

}


export const convertRecurringTransferDate = (date) => {
    // This date comes in as YYYYMMDD
    const year = date.slice(0,4);
    const monthNum = date.slice(4,6);
    const monthName = formatNameCapitalFirst(Object.keys(monthConversion)[parseFloat(monthNum)-1]);
    const day = date.slice(6,8);

    const formattedDate = `${monthName} ${day}, ${year}`;
    return formattedDate;

}


export const formatTransferStartDate = (startDate) => {
    // The date will come in as Nov 10, 2021

    const datetime = new Date(Date.parse(startDate));
    const year = datetime.getFullYear().toString();
    var monthNum = datetime.getMonth() + 1; // 0 is Jan, 1 is Feb, etc.
    monthNum = monthNum < 10 ? '0' + monthNum.toString() : monthNum.toString();

    var day = datetime.getDate();
    day = day < 10 ? '0' + day.toString() : day.toString();

    const formattedStartDate = `${year}${monthNum}${day}`
    return formattedStartDate;

}


export function formatDateForDateRange(date) {
    // Input date is YYYYMMDD, target output date is mm/dd/YYYY (no 0 padding)

    const year = date.slice(0,4);
    const month = (parseFloat(date.slice(4,6))).toString();
    const day = (parseFloat(date.slice(6,8))).toString();
    return `${month}/${day}/${year}`;

}


export function formatLastUpdatedTime(updatedEpoch) {

    // Need the date string in order to get the time of day
    const dateString = new Date(updatedEpoch).toString();
    const yearMonthDay = convertEpochToYearMonthDay(updatedEpoch);
    const formattedDate = formatDateForDateRange(yearMonthDay); // 5/24/2022 format
    const [hour, minute, seconds, amORpm] = getTimeOfDayFromDateString(dateString);

    let timeZone = dateString.split('(')[1]
    timeZone = stringReplace(timeZone, ')', '')
    const formattedDateTime = `${formattedDate} ${hour}:${minute}${amORpm.toLowerCase()} ${timeZone}`;

    return formattedDateTime;

}


export function convertEpochToYearMonthDay(epoch) {

    // Convert the epoch to a date string (format: Thu Mar 25 2021 19:11:09 GMT-0600 (MDT))
    const dateString = new Date(epoch).toString();

    // Format the date
    const month = dateString.slice(4,7);
    const monthIndex = shortMonthNames.indexOf(month.toLowerCase())+1;
    const fullMonthNumber = (monthIndex < 10 ? '0'+monthIndex : monthIndex).toString();
    const date = dateString.slice(8,10);
    const year = dateString.slice(11,15);
    const yearMonthDay = `${year}${fullMonthNumber}${date}`;

    return yearMonthDay;

}


export function formatEmbedTransferDate(date) {

    // date is formatted as "2022-08-30T20:38:35.873928Z" in GMT time

    var currentDate = new Date();
    var msOffset = currentDate.getTimezoneOffset() * 60 * 1000;

    var year = parseInt(date.slice(0,4));
    var month = parseInt(date.slice(5,7));
    var day = parseInt(date.slice(8,10));
    var hour = parseInt(date.slice(11,13));
    var minute = parseInt(date.slice(14,16));

    // Handle the timezone offset
    var epoch = new Date(year, month-1, day, hour, minute).getTime()
    epoch = epoch - (msOffset)
    const newDate = convertEpochToYearMonthDay(epoch)
    year = newDate.slice(0,4)
    month = parseInt(newDate.slice(4,6))
    day = newDate.slice(6,8)
    month = monthNames[month-1]

    return [epoch, month, day]

}
