import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Resizer from "react-image-file-resizer";

import CreateAccountWrapper from '../../components/createAccount/CreateAccountWrapper';

import * as createAccountActions from '../../store/actions/createAccount';
import uploadIcon from '../../assets/icons/upload.png';

import '../../styles/accountCreationStyles.css';
import '../../styles/generalStyles.css';
import './PhotoIdUpload.css';


const PhotoIdUpload = (props) => {

    const { ownerNumber } = useParams();
    const index = ownerNumber; // index just makes more sense as a variable name
    const beneficialOwners = useSelector(state => state.createAccount.beneficialOwners);
    const editOrAdd = (beneficialOwners[index].base64 == undefined || beneficialOwners[index].base64 == '') ? 'add' : 'edit';
    const ownerName = beneficialOwners[index].firstName;

    const [base64, setBase64] = useState(editOrAdd == 'add' ? '' : beneficialOwners[index].base64);
    const [frontIdName, setFrontIdName] = useState(editOrAdd == 'add' ? '' : beneficialOwners[index].frontIdName);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const sendToNext = () => {

        var updatedBeneficialOwners = beneficialOwners;
        updatedBeneficialOwners[index].base64 = base64;
        updatedBeneficialOwners[index].isComplete = true;
        dispatch(createAccountActions.setBeneficialOwners(updatedBeneficialOwners))

        history.go(-3)

    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const resizeFile = (file, compressionQuality) => new Promise(resolve => {
        // This returns the base64 data for the resized image
        console.log(compressionQuality)
        Resizer.imageFileResizer(file, 5000, 5000, 'JPEG', compressionQuality, 0,
            uri => {
                resolve(uri);
            }, 'base64');
    })

    const getCompressionQuality = (originalImageSize) => {

        // For the license images, we want ~1-1.5MB file sizes

        if (originalImageSize >= 20000000) {
            // returns ~ 1/20 size
            return 20
        } else if (originalImageSize >= 10000000) {
            // returns ~1/10 size
            return 40
        } else if (originalImageSize >= 5000000) {
            // returns ~1/6 size
            return 55
        } else if (originalImageSize >= 2500000) {
            // returns ~1/4 size
            return 70
        } else if (originalImageSize >= 1000000) {
            // returns ~1/2 size
            return 90
        } else {
            return 99
        }

    }

    const idUploadHandler = async (event) => {
        console.log(event.target.files[0])

        const file = event.target.files[0]
        const base64 = await convertBase64(file);

        const byteSize = str => new Blob([str]).size;
        const originalImageSize = byteSize(base64)
        console.log(originalImageSize)

        const resizedBase64 = await resizeFile(event.target.files[0], 25);
        const newImageSize = byteSize(resizedBase64)

        console.log(newImageSize)

        setFrontIdName(event.target.files[0].name)
            setBase64(resizedBase64)
    }

    useEffect(() => {
        if (base64 === undefined || base64 === '') {
            setButtonDisabled(true)
        } else {
            setButtonDisabled(false)
        }
    }, [base64])

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                sendToNext()
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [base64]);

    return (

        <CreateAccountWrapper
            buttonDisabled={false}
            onButtonClick={() => sendToNext()}
            backButtonText={"Back"}
            onSecondaryTextClick={() => history.goBack()}
            >
            <div className="section-container">
                <header className="bold-font account-creation-header">
                    Photo ID
                </header>
                <p className="light-font sub-header">
                    Please upload the photo ID for {ownerName}. This can be a driver's license, passport, or Visa.
                    If you do not have this on hand, you can continue and submit it later.
                </p>

                <div className="id-upload-row">
                    <div className="id-upload-icon-box">
                        <form className="box" method="post" action="" encType="multipart/form-data">
                            <div className="box__input">
                                <input type="file" name="file" id="frontID" hidden onChange={(event) => idUploadHandler(event)} />
                            </div>
                            <label htmlFor="frontID">
                                <img className="upload-icon" src={uploadIcon} />
                            </label>
                        </form>
                    </div>
                    <div className="id-upload-text-box">
                        <p className="regular-font">{frontIdName == '' ? "Photo ID" : frontIdName}</p>
                    </div>
                </div>

            </div>
        </CreateAccountWrapper>

    )

}

export default PhotoIdUpload;
