const { REACT_APP_SERVICE_BASE_URL, REACT_APP_PHONE_VERIFICATION_ENDPOINT } = process.env;


export async function getPhoneVerificationCodes(userID, phoneNumber) {

    const response = await fetch(
        `${REACT_APP_SERVICE_BASE_URL}${REACT_APP_PHONE_VERIFICATION_ENDPOINT}?levouserid=${userID}&userid=${userID}`,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                device: 'phone',
                action: 'send',
                data: phoneNumber
            }),
        }
    )

    const resData = await response.json();
    return [resData, response.status];

}


export async function submitPhoneVerificationCodes(userID, code) {
    console.log(userID, code)

    const response = await fetch(
        `${REACT_APP_SERVICE_BASE_URL}${REACT_APP_PHONE_VERIFICATION_ENDPOINT}?levouserid=${userID}&userid=${userID}`,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                device: 'phone',
                action: 'verify',
                data: code
            }),
        }
    )

    const resData = await response.json();
    return [resData, response.status];

}


export async function makeLoginRequest(email, password, type='USER') {

    const response = await fetch(
        `${REACT_APP_SERVICE_BASE_URL}login/`,
        {
            method: 'POST',
            body: JSON.stringify({
                email: email,
                password: password,
                session_type: type
            }),
        }
    )

    const resData = await response.json();
    return [resData, response.status];

}
