import {
    SET_ACCOUNT_INFO,
    SET_COMPANY_DOCS,
    SET_MONTHLY_STATEMENTS
} from '../actions/account';
import { RESET_ACCOUNT } from '../actions/auth';


const initialState = {
    accountInfo: {},
    companyDocs: null,
    monthlyStatements: null,
}

export default (state = initialState, action) => {

    switch (action.type) {
        case SET_ACCOUNT_INFO:
            return {
                ...state,
                accountInfo: action.accountInfo,
            };
        case SET_COMPANY_DOCS:
            return {
                ...state,
                companyDocs: action.companyDocs,
            };
        case SET_MONTHLY_STATEMENTS:
            return {
                ...state,
                monthlyStatements: action.monthlyStatements,
            };
        case RESET_ACCOUNT:
            return {
                initialState
            };
        default:
            return state;
    }

}
