import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import CreateAccountWrapper from '../../components/createAccount/CreateAccountWrapper';
import TextInput from '../../components/general/TextInput';
import LoadingSpinner from '../../components/general/LoadingSpinner';

import { submitPhoneVerificationCodes } from '../../services/auth';
import * as createAccountActions from '../../store/actions/createAccount';
import { getNextSignUpPage } from '../../utils/generalFunctions';

import './PhoneVerification.css';
import '../../styles/generalStyles.css';

const PhoneVerification = (props) => {

    const savedPhoneNumber = useSelector(state => state.createAccount.phoneNumber);
    const userID = useSelector(state => state.createAccount.levoUserID);

    const [code, setCode] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [activityIndicator, setActivityIndicator] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const textChangeHandler = (event) => {
        setCode(event.target.value);
    }

    const sendToNext = async () => {
        setActivityIndicator(true)
        const response = await submitPhoneVerificationCodes(userID, code)
        setActivityIndicator(false)
        if (response[1] === 200) {
            dispatch(createAccountActions.setVerifiedPhone(savedPhoneNumber));
            const nextPage = getNextSignUpPage(window.location.pathname);
            history.push(nextPage)
        } else {
            alert("The code you entered was incorrect. Please try again")
        }
    }

    useEffect(() => {
        if (code.length === 6) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [code])

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (!buttonDisabled) {
                    sendToNext()
                }
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [buttonDisabled, code]);

    return (
        <CreateAccountWrapper
            buttonDisabled={buttonDisabled}
            onButtonClick={() => sendToNext()}
            onSecondaryTextClick={() => history.goBack()}>
            <div className="section-container">
                <header className="bold-font account-creation-header">
                    Phone number verification
                </header>
                <p className="light-font sub-header">Please enter the code sent to {savedPhoneNumber}</p>

                {(activityIndicator) &&
                    <div className="loading-indicator-container">
                        <LoadingSpinner />
                    </div>
                }

                <div className="legal-name-container">
                    <TextInput
                        headerMessage={"Verification code"}
                        onTextChange={textChangeHandler}
                        value={code}
                    />
                </div>
            </div>
        </CreateAccountWrapper>
    )

}

export default PhoneVerification;
