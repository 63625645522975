import {
    SET_TRANSFERS,
    SET_EMBED_SOURCES
} from '../actions/transfers';
import { RESET_TRANSFERS } from '../actions/auth';


const initialState = {
    transfers: [],
    embedSources: [],
}

export default (state = initialState, action) => {

    switch (action.type) {
        case SET_TRANSFERS:
            return {
                ...state,
                transfers: action.transfers,
            };
        case SET_EMBED_SOURCES:
            return {
                ...state,
                embedSources: action.embedSources,
            };
        case RESET_TRANSFERS:
            return {
                initialState
            }
        default:
          return state;
    }

}
