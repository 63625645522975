
export const SET_USER_ID = 'SET_USER_ID';
export const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN';

export const LOGOUT = 'LOGOUT';
export const RESET_HOME = 'RESET_HOME';
export const RESET_ACCOUNT = 'RESET_ACCOUNT';
export const RESET_TRANSFERS = 'RESET_TRANSFERS';
export const RESET_PORTFOLIO = 'RESET_PORTFOLIO';


export const authenticate = (userID, sessionToken, expiryTime, history) => {
    return dispatch => {
        dispatch(setAutoLogoutTimer(expiryTime, history));
        dispatch({ type: SET_USER_ID, userID: userID });
        dispatch({ type: SET_SESSION_TOKEN, sessionToken: sessionToken });
    }
}

export const logout = () => {
    sessionStorage.clear();
    clearLogoutTimer();
    return dispatch => {
        dispatch({ type: LOGOUT });
        dispatch({ type: RESET_HOME });
        dispatch({ type: RESET_ACCOUNT });
        dispatch({ type: RESET_TRANSFERS });
        dispatch({ type: RESET_PORTFOLIO });
    }
}

let timer;

const clearLogoutTimer = () => {
    if (timer) {
        clearTimeout(timer);
    }
};

export const setAutoLogoutTimer = (expirationTime, history) => {
    clearLogoutTimer();

    return dispatch => {
        timer = setTimeout(() => {

            alert("For security purposes, your session has ended")
            logout();
            history.replace('/login')

            /*alert(
                "Session Ending",
                "For security purposes, your session has ended. Click here to re-authenticate",
                [
                { text: "Continue", onPress: () => {
                props.navigation.reset({
                index: 0,
                routes: [{
                name: 'Auth'
                }]
                });
                dispatch(logout());
                }}
                ]
            );*/

        }, expirationTime);

    };
};
