import { Switch, Route, Redirect } from 'react-router-dom';

import Home from '../screens/marketing/Home';
import CreateAccountNavigator from '../navigation/CreateAccountNavigator';
import DashboardNavigator from '../navigation/DashboardNavigator';
import Login from '../screens/Login';

function MainNavigator() {

    return (
        <Switch>
            <Route path="/" exact>
                <Home />
            </Route>
            <Route path="/sign-up">
                <CreateAccountNavigator />
            </Route>
            <Route path="/login">
                <Login />
            </Route>
            <Route path="/dashboard">
                <DashboardNavigator />
            </Route>
        </Switch>
    )

}

export default MainNavigator;
