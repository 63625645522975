
export const SET_PORTFOLIO = 'SET_PORTFOLIO';
export const SET_USER_NAVS = 'SET_USER_NAVS';
export const SET_ALLOCATION = 'SET_ALLOCATION';
export const SET_TARGET_ALLOCATION = 'SET_TARGET_ALLOCATION';
export const SET_CURRENT_YIELDS = 'SET_CURRENT_YIELDS';

export const setPortfolio = (portfolio) => {
    return dispatch => {
        dispatch({ type: SET_PORTFOLIO, portfolio: portfolio });
    }
}

export const setUserNAVs = (userNAVs) => {
    return dispatch => {
        dispatch({ type: SET_USER_NAVS, userNAVs: userNAVs });
    }
}

export const setAllocation = (allocation) => {
    return dispatch => {
        dispatch({ type: SET_ALLOCATION, allocation: allocation });
    }
}

export const setTargetAllocation = (targetAllocation) => {
    return dispatch => {
        dispatch({ type: SET_TARGET_ALLOCATION, targetAllocation: targetAllocation });
    }
}

export const setCurrentYields = (currentYields, yieldUpdateDate) => {
    return dispatch => {
        dispatch({ type: SET_CURRENT_YIELDS, currentYields: currentYields, yieldUpdateDate: yieldUpdateDate });
    }
}
