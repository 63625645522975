
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TextInput from '../../components/general/TextInput';
import CreateAccountWrapper from '../../components/createAccount/CreateAccountWrapper';
import LoadingSpinner from '../../components/general/LoadingSpinner';

import { useAccountSubmission } from '../../logic/createAccount/createAccount';
import params from '../../config/params';

import '../../styles/accountCreationStyles.css';
import '../../styles/generalStyles.css';


const CreateAccount = () => {

    const history = useHistory();
    const { createAccount, activityAnimating } = useAccountSubmission(history)

    const savedEmail = useSelector(state => state.createAccount.email)

    const [email, setEmail] = useState(savedEmail === undefined ? '' : savedEmail);
    const [password, setPassword] = useState('');
    const [secureText, setSecureText] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const [passwordLengthSatisfied, setPasswordLengthSatisfied] = useState(false);
    const [upperCaseSatisfied, setUpperCaseSatisfied] = useState(false);
    const [lowerCaseSatisfied, setLowerCaseSatisfied] = useState(false);
    const [numberSatisfied, setNumberSatisfied] = useState(false);
    const [specialCharacterSatisfied, setSpecialCharacterSatisfied] = useState(false);

    const sendToNext = () => {
        setButtonDisabled(true)
        createAccount(email, password)
        setButtonDisabled(false)
    }

    useEffect(() => {
        const lengthSatisfied = password.length >= 8;
        setPasswordLengthSatisfied(lengthSatisfied)
        const upperCase = /[A-Z]/.test(password)
        setUpperCaseSatisfied(upperCase)
        const lowerCase = /[a-z]/.test(password)
        setLowerCaseSatisfied(lowerCase)
        const specialCharacter = /[#!@$%^&*]/.test(password)
        setSpecialCharacterSatisfied(specialCharacter)
        const number = /[0-9]/.test(password)
        setNumberSatisfied(number)
    }, [password])

    useEffect(() => {
        const isEmailValid = params.emailRegex.test(email.toLowerCase());
        const isPasswordValid = params.passwordRegex.test(password);
        if (isEmailValid && isPasswordValid) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [email, password])


    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // Do nothing
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);


    return (
        <CreateAccountWrapper
            buttonDisabled={buttonDisabled}
            onButtonClick={() => sendToNext()}
            onSecondaryTextClick={() => history.goBack()}
            buttonText={'Submit application'}>

            <h2 className="bold-font account-creation-header">Create account</h2>

            {activityAnimating &&
                <div className="loading-indicator-container">
                    <LoadingSpinner />
                </div>
            }

            <div className="account-creation-input-row">
                <TextInput
                    headerMessage={"Email address"}
                    onTextChange={(event) => setEmail(event.target.value)}
                    placeholder="john@acme.inc"
                    inputType={"text"}
                    value={email}
                />
            </div>

            <p className="medium-font label" style={{ marginTop: "30px" }}>Password</p>
            <div className="account-creation-input-row" style={{ marginTop: "5px" }}>
                <div className="password-input-container">
                    <TextInput
                        headerMessage={""}
                        onTextChange={(event) => setPassword(event.target.value)}
                        inputType={(secureText ? "password" : "text")}
                        value={password}
                        customStyle={{ border: "none" }}
                        hideOutline={true}
                        placeholder={"password"}
                    />
                    <p className="show-password-button" onClick={() => setSecureText(!secureText)}>
                        {(secureText ? "show" : "hide")}
                    </p>
                </div>
            </div>

            <div className="password-requirement-container">
                <p className={"semi-bold-font password-requirement-text"}>
                    Your password must contain at least:
                </p>
                <p className={"light-font password-requirement-text" + (passwordLengthSatisfied ? "-satisfied" : "")}>
                    8 characters
                </p>
                <p className={"light-font password-requirement-text" + (upperCaseSatisfied ? "-satisfied" : "")}>
                    One uppercase letter
                </p>
                <p className={"light-font password-requirement-text" + (lowerCaseSatisfied ? '-satisfied' : "")}>
                    One lowercase letter
                </p>
                <p className={"light-font password-requirement-text" + (specialCharacterSatisfied ? "-satisfied" : "")}>
                    One special character
                </p>
                <p className={"light-font password-requirement-text" + (numberSatisfied ? "-satisfied" : "")}>
                    One number
                </p>
            </div>

        </CreateAccountWrapper>
    )

}

export default CreateAccount;