
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePlaidLink } from 'react-plaid-link';

//import { formatEmbedSources } from './transfers';
import { linkAccount, getLinkToken } from '../../../services/plaid';
import { getSources } from '../../../services/embed';
import * as accountActions from '../../../store/actions/transfers';

export function usePlaidLinkHandler(props) {

    const userID = useSelector(state => state.auth.userID);
    const sessionToken = useSelector(state => state.auth.sessionToken);

    const embedSources = useSelector(state => state.transfers.embedSources)
    const originalLinkedAccountsLength = embedSources.length;

    const [linkingAccountOverlay, setLinkingAccountOverlay] = useState(false);
    const [successOverlay, setSuccessOverlay] = useState(false);
    const [slowResponseOverlay, setSlowResponseOverlay] = useState(false);
    const [foundNewAccount, setFoundNewAccount] = useState(false);
    const [responseStatus, setResponseStatus] = useState(0);
    const [newAccountID, setNewAccountID] = useState('');
    const [cutoffTime, setCutoffTime] = useState(5000000000000000); // Way in the future to begin with

    const dispatch = useDispatch();

    async function getAccounts() {

        const [embedSources, embedSourceStatus] = await getSources(userID, sessionToken);
        if (embedSources.length > originalLinkedAccountsLength && !foundNewAccount) {
            setLinkingAccountOverlay(false);
            setSuccessOverlay(true);
            setFoundNewAccount(true);
            // Update the session storage item and dispatch the action to update the embedSources
            sessionStorage.setItem("embedSources", JSON.stringify(embedSources));
            dispatch(accountActions.setEmbedSources(embedSources));
        }

        // If it is taking too long to find the newly linked account, kill the linking account overlay
        if ((new Date()).getTime() >= cutoffTime) {
            setLinkingAccountOverlay(false);
            setSlowResponseOverlay(true)
        }

    }

    async function getToken() {
        const token = await getLinkToken(userID, sessionToken)
        return token;
    }

    async function handlePlaidSuccess(publicToken, metadata) {

        console.log('metadata')
        console.log(metadata)
        const accounts = metadata.accounts;
        const accountID = accounts[0].id;

        // Set the cutoff time to 60 seconds (converted to milliseconds)
        setCutoffTime((new Date()).getTime() + 60 * 1000)
        setLinkingAccountOverlay(true);

        const [linkAccountResponse, status] = await linkAccount(
            sessionToken, userID, publicToken, accountID
        )
        setResponseStatus(status)

        if (status == 200) {
            setNewAccountID(accountID);
        } else {
            setLinkingAccountOverlay(false);
            console.log('fail')
        }

    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (!foundNewAccount && responseStatus == 200 && newAccountID.length > 0) {
                console.log('fetching new accounts')
                getAccounts();
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [foundNewAccount, responseStatus, newAccountID])

    return {
        getToken, handlePlaidSuccess, linkingAccountOverlay, successOverlay,
        setSuccessOverlay, slowResponseOverlay, setSlowResponseOverlay
    }

}
