
import CardLayout from '../general/CardLayout';
import CustomButton from '../general/CustomButton';
import TextInput from '../general/TextInput';
import AccountSelection from './AccountSelection';
import PlaidLink from "../transfers/PlaidLink";

import './DepositWithdrawalCard.css';

const WithdrawalCard = (props) => {

    return (

        <CardLayout customStyle={{ width: "800px", borderRadius: "8px" }}>
            <div className="deposit-withdrawal-card-container">

                <div className="deposit-withdrawal-card-row">
                    <div className="deposit-withdrawal-card-title-col">
                        <p>From</p>
                    </div>
                    <div className="deposit-withdrawal-card-info-col">
                        <AccountSelection
                            onChange={(event) => props.onAccountSelection(event.target.value)}
                            sources={props.sources}
                        />
                        <PlaidLink />
                    </div>
                </div>

                <div className="deposit-withdrawal-card-row">
                    <div className="deposit-withdrawal-card-title-col">
                        <p>To</p>
                    </div>
                    <div className="deposit-withdrawal-card-info-col">
                        <p>Levo Treasury -  ${props.accountBalance}</p>
                    </div>
                </div>

                <div className="deposit-withdrawal-card-row">
                    <div className="deposit-withdrawal-card-title-col">
                        <p>Amount</p>
                    </div>
                    <div className="deposit-withdrawal-card-info-col">
                        <TextInput
                            inputType={"text"}
                            placeholder={"$250,000"}
                            customStyle={{ width: "250px" }}
                            onTextChange={props.handleAmount}
                            value={props.amount}
                        />
                    </div>
                </div>

                <div className="deposit-withdrawal-card-button-container">
                    <div className="deposit-withdrawal-card-button-col">
                        <CustomButton
                            onClick={() => props.setWithdrawalOrReview('review')}
                            buttonText={"Review"}
                            disabled={props.buttonDisabled}
                        />
                    </div>
                </div>

            </div>
        </CardLayout>

    )

}

export default WithdrawalCard;
