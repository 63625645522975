
import {
    SET_USER_ID,
    SET_SESSION_TOKEN,
    LOGOUT
} from '../actions/auth';


const initialState = {
    userID: '',
    sessionToken: null,
}

export default (state = initialState, action) => {

    switch (action.type) {
        case SET_USER_ID:
            return {
                ...state,
                userID: action.userID,
            };
        case SET_SESSION_TOKEN:
            return {
                ...state,
                sessionToken: action.sessionToken,
            };
        case LOGOUT:
            return {
                initialState
            }
        default:
          return state;
    }

}
